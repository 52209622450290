import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { getPrintableDateTime, getUserCountry } from "../utils";
import { useNavigate } from "react-router-dom";

function SubscriptionPlans({ t, setIsLoading, isAxiosReady, user, proceedSubscription, manageSubscription }) {
    const navigate = useNavigate();

    const [activeSubscriptions, setActiveSubscriptions] = useState({});
    const [userCountry, setUserCountry] = useState();

    const handleClickOnPricing = () => {
        navigate("/register", { replace: true });
    }

    useEffect(() => {
        setIsLoading(true);

        if (isAxiosReady) {
            const getCustomerAndSub = async () => {
                const result = await axios.get("/subscription/");
                return result.data;
            };

            getCustomerAndSub().then(result => {
                setActiveSubscriptions(result);
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                setIsLoading(false);
            });
        }
    }, [isAxiosReady, user]);

    useEffect(() => {
        setIsLoading(true);

        if (isAxiosReady) {
            const getCountry = async () => {
                const country = await getUserCountry();
                return country;
            };

            getCountry().then(result => {
                setUserCountry(result);
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                setIsLoading(false);
            });
        }
    }, [isAxiosReady]);

    return (
        <div className="pricingGroupsContainer">
            <div className="pricingGroupContainer" id="france">
                <h2>
                    <hr />
                    <span><img src="./images/france.png" alt="france" /> {t("France")}</span>
                </h2>
                <div className="plansContainer">
                    <SubscriptionPlan
                        t={t}
                        user={user}
                        name={t("Pré-match")}
                        price={200}
                        slogan={t("Performant et pérenne")}
                        services={[
                            `${t("Accès aux meilleurs bookmakers français")} (1)`,
                            t("Tous les value bets et sure bets"),
                            t("Liens directs vers les bookmakers"),
                            t("Calcul et suivi en temps réel des CLV"),
                            t("Récupération des résultats et bilan automatisé")
                        ]}
                        subscriptionName="BSM-FRANCE"
                        activeSubscriptions={activeSubscriptions}
                        isIncoming={false}
                        userCountry={userCountry}
                        isAvailable={true}
                        isLive={false}
                        proceedSubscription={proceedSubscription}
                        manageSubscription={manageSubscription}
                        goToRegister={user ? null : handleClickOnPricing}
                    />
                </div>
            </div>

            <div className="pricingGroupContainer" id="international">
                <h2>
                    <hr />
                    <span><img src="./images/international.png" alt="international" /> {t("International")}</span>
                </h2>
                <div className="plansContainer">
                    <SubscriptionPlan
                        t={t}
                        user={user}
                        name={t("Pré-match")}
                        price={100}
                        slogan={t("Diversifié et puissante")}
                        services={[
                            `${t("Accès aux meilleurs bookmakers internationaux")} (2)`,
                            t("Tous les value bets et sure bets"),
                            t("Liens directs vers les bookmakers"),
                            t("Calcul et suivi en temps réel des CLV"),
                            t("Récupération des résultats et bilan automatisé")
                        ]}
                        subscriptionName="BSM-WORLD"
                        activeSubscriptions={activeSubscriptions}
                        isIncoming={false}
                        userCountry={userCountry}
                        isAvailable={userCountry !== "France"}
                        isLive={false}
                        proceedSubscription={proceedSubscription}
                        manageSubscription={manageSubscription}
                        goToRegister={user ? null : handleClickOnPricing}
                    />

                    <SubscriptionPlan
                        t={t}
                        user={user}
                        price={50}
                        slogan={t("Rapide et efficace")}
                        services={[
                            `${t("Accès aux meilleurs bookmakers internationaux")} (2)`,
                            t("Tous les value bets et sure bets"),
                            t("Liens directs vers les bookmakers"),
                            t("Récupération des résultats et bilan automatisé")
                        ]}
                        subscriptionName="BSM-WORLD-LIVE"
                        activeSubscriptions={activeSubscriptions}
                        isIncoming={true}
                        userCountry={userCountry}
                        isAvailable={userCountry !== "France"}
                        isLive={true}
                        proceedSubscription={proceedSubscription}
                        manageSubscription={manageSubscription}
                        goToRegister={user ? null : handleClickOnPricing}
                    />
                </div>
            </div>
        </div>
    )
}

export default SubscriptionPlans

const SubscriptionPlan = ({ t, user, name, price, slogan, services, subscriptionName, activeSubscriptions, isIncoming, userCountry, isAvailable, isLive, proceedSubscription, manageSubscription, goToRegister }) => {
    const isAlreadySubscribed = activeSubscriptions.hasOwnProperty(subscriptionName);
    const subscription = activeSubscriptions[subscriptionName];

    const [isAccountAvailable, setIsAccountAvailable] = useState(!user || (user && user.isAccountConfirmed));
    useEffect(() => {
        setIsAccountAvailable(!user || (user && user.isAccountConfirmed));
    }, [user])

    const mainClasses = ["planContainer"];
    if (isIncoming) mainClasses.push("incoming");
    if (isAlreadySubscribed) mainClasses.push("subscribed");
    if (!isAvailable) mainClasses.push("notAvailable");
    if (!isAccountAvailable) mainClasses.push("notAvailable");

    return (
        <div className={mainClasses.join(" ")}>
            <div className="header">
                <h3>
                    {isLive ? (
                        <>
                            <span className="liveContainer">
                                <span className="live"></span>
                                {t("Live")}
                            </span>
                            {t("Match")}
                        </>
                    ) : (
                        <>{name}</>
                    )}
                </h3>
                <h4>{price} € <small>/ {t("mois")}</small></h4>
            </div>
            <div className="slogan">{slogan}</div>
            <div className="servicesContainer">
                {isAlreadySubscribed ? (
                    <ul className="subscriptionInfoContainer">
                        <li>
                            {t("Statut de l'abonnement")} :
                            <b> {subscription.status === "active" ? t("ACTIF") : subscription.status === "trialing" ? t("PÉRIODE D'ESSAI") : t("INACTIF")}</b>
                        </li>
                        <li>
                            {t("Prix de l'abonnement")} :
                            <b> {subscription.discount && subscription.discount.coupon ? (subscription.plan.amount - subscription.discount.coupon.amount_off) / 100 : subscription.plan.amount / 100} {t("€")} / {t("mois")}</b>
                        </li>
                        <li>
                            {t("Dernier paiement le")} :
                            <b> {getPrintableDateTime(subscription.current_period_start * 1000)}</b>
                        </li>
                        <li>
                            {t("Prochain paiement le")} :
                            <b> {getPrintableDateTime(subscription.current_period_end * 1000)}</b>
                        </li>
                        <li>
                            {t("Type de paiement")} :
                            <b> {subscription.collection_method === "charge_automatically" ? t("Automatique") : t("Sur facture")}</b>
                        </li>
                        <li>
                            {t("Abonné depuis le")} :
                            <b> {getPrintableDateTime(subscription.start_date * 1000)}</b>
                        </li>
                    </ul>
                ) : (
                    <ul className="servicesList">{services.map((service, index) => <li key={index}>{service}</li>)}</ul>
                )}

            </div>
            <SubscriptionButton t={t} isAccountVerified={isAccountAvailable} isAlreadySubscribed={isAlreadySubscribed} manageSubscription={manageSubscription} proceedSubscription={proceedSubscription} goToRegister={goToRegister} isAvailable={isAvailable} userCountry={userCountry} subscriptionName={subscriptionName} />
            <div className="incomingContainer">
                <div className="content">{t("à venir")}</div>
            </div>
        </div>
    )
}

const SubscriptionButton = ({ t, isAccountVerified, isAlreadySubscribed, manageSubscription, proceedSubscription, goToRegister, userCountry, isAvailable, subscriptionName }) => {
    if (!isAccountVerified) {
        return <button className="btn thematized">{t("Adresse email non vérifiée")}</button>
    } else if (isAlreadySubscribed) {
        return <button onClick={() => manageSubscription(subscriptionName)} className="btn thematized">{t("Gérer mon abonnement")}</button>
    } else if (!isAvailable) {
        return <button className="btn thematized">{t("Non accessible dans votre pays")}<br />({userCountry})</button>
    } else if (goToRegister) {
        return <button onClick={goToRegister} className="btn thematized">{t("Choisir ce plan")} <br /><small>({t("3 jours offerts")})</small></button>
    } else {
        return <button onClick={() => proceedSubscription(subscriptionName)} className="btn thematized">{t("S'abonner à ce plan")} <br /><small>({t("3 jours offerts")})</small></button>
    }
}
