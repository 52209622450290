import React from 'react';
import NotationStars from '../components/NotationStars';

function WorldBooksNotation({ t }) {
    const worldBooks = {
        "UniBet.com": {
            prematch: 5,
            live: -1
        },
        "Stake": {
            prematch: 5,
            live: -1
        },
        "MyStake": {
            prematch: 5,
            live: -1,
            clones: ["FreshBet", "31Bet", "GoldenBet", "JackBit", "VeloBet", "Rolletto", "DonBet", "CosmoBet", "20Bets"]
        },
        "1xBet": {
            prematch: 5,
            live: -1,
            clones: ["Bet2Fun", "22Bet", "CoinPlay", "VivatBet", "MelBet", "BetWinner", "888Starz", "BetPari", "HelaBet", "MegaPari", "PariPesa", "SapphireBet"]
        },
        "BetRebels": {
            prematch: 4,
            live: -1,
            clones: ["Sportaza", "Rabona", "FezBet", "Amunra", "MaxxWin", "24Bettle", "4Kasino", "BBets", "BankOnBet", "Betinia", "BetRiot", "Betti", "CampeOnBet", "Big5Casino", "Cashed", "GozaBet", "Casinoly", "DivasLuckCasino", "ExciteWin", "GreatWin", "LightCasino", "LuckyBull", "Gambeta10", "CasinoSieger", "MrPacho", "Nomini", "PalmsBet", "PlayZilla", "OhMySpins", "PowBet", "SlotsHammer", "SvenPlay", "VipArabClub", "Wazamba", "Winpot", "Zet", "RembrandtCasino", "Pedalada10", "SpinCashWin", "EvoBet"]
        },
        "Bets.io": {
            prematch: 4,
            live: -1,
            clones: ["CobraCasino", "WooCasino"]
        },
        "QBet": {
            prematch: 4,
            live: -1
        },
        "BetOnline": {
            prematch: 4,
            live: -1,
            clones: ['SportsBetting', 'TigerGaming']
        },
        "CloverSpin": {
            prematch: 3,
            live: -1,
            clones: ["Winz", "1Red", "BetiBet", "N1Bet", "Wild", "RocketPlay", "BelaBet", "PlatinCasino", "SixDot", "ZotaBet", "LamaBet", "BetBeast", "MetaSpins", "DailySpins", "CoinsGame"]
        },
        "YoniBet": {
            prematch: 3,
            live: -1,
            clones: ["Casinozer", "Betify", "BCGame", "RooBet", "RollBit", 'LadyLinda', 'BdmBet', 'BetSpino', 'Betiro', 'BetOnRed', 'BitUBet', 'CasinoEstrella', 'CryptoLeo', 'HexaBet2', 'Rollino', 'SuperBoss', 'WsmCasino', 'BlueChip', 'Bons', 'Casino-x', 'FortuneJack', 'WettenLive', 'BetFury', 'RamenBet', 'NineCasino', 'BrunoCasino', 'JoyCasino', 'AnonymBet', 'BetTilt', 'Bitz9', 'BlackMagicCasino', 'BloodMoonCasino', 'ChillBet', 'Chips', 'Crashino', 'Esportiva', 'GoldenReels', 'HiperWin', 'LuckOfSpins', 'LuckyBlock', 'MegaDice', 'SlotsNBets', 'PlayersClubVip', 'RoyalOakCasino', 'SnatchCasino6', 'TivitBet', 'Bet7K', 'Blaze1', 'SlotsSafari', 'TrBet', 'RajaBets', 'CaptainsBet']
        },
    };

    return (
        <table className="bookmakersList">
            <thead>
                <tr>
                    <th>{t("Bookmaker")}</th>
                    <th>{t("Clones")}</th>
                    <th>{t("Volume pré-match")}</th>
                    <th>
                        <span className="flex">
                            {t("Volume")}
                            <span className="liveContainer">
                                <span className="liveIcon"></span>{t("live")}
                            </span>
                        </span>
                    </th>
                </tr>
            </thead>
            <tbody>
                {
                    Object.keys(worldBooks).map((book, index) => {
                        return <tr key={index}>
                            <td className="book">{book}</td>
                            <td className="mirors">{worldBooks[book].clones ? worldBooks[book].clones.join(", ") : "-"}</td>
                            <td><NotationStars key={`${index}-prematch`} number={worldBooks[book].prematch} /></td>
                            <td><NotationStars key={`${index}-live`} number={worldBooks[book].live} /></td>
                        </tr>
                    })
                }
            </tbody>
        </table>
    )
}

export default WorldBooksNotation
