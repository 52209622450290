import { BiSolidTennisBall } from 'react-icons/bi';
import { IoMdFootball } from 'react-icons/io';
import { FaBasketballBall, FaFootballBall, FaHockeyPuck, FaVolleyballBall } from 'react-icons/fa';
import { CiBaseball } from 'react-icons/ci';
import { MdSportsRugby, MdSportsHandball } from 'react-icons/md';
import i18n from './i18n';
import axios from 'axios';

const allTotalsMarkets = ["totals", "totalsGames", "totalsPoints", "totalsFirstHalf", "totalsSecondHalf", "totalsFirstQuarter", "totalsSecondQuarter", "totalsThirdQuarter", "totalsFourthQuarter", "totalsFirstSet", "totalsSecondSet", "totalsThirdSet", "totalsFourthSet", "totalsFifthSet", "totalsGamesFirstSet", "totalsGamesSecondSet", "totalsGamesThirdSet", "totalsGamesFourthSet", "totalsGamesFifthSet", "totalsPointsFirstSet", "totalsPointsSecondSet", "totalsPointsThirdSet", "totalsPointsFourthSet", "totalsPointsFifthSet", "totalsFirstPeriod", "totalsSecondPeriod", "totalsThirdPeriod", "totalsRegularTime", "totalsSacks", "totalsFieldGoalsMade", "totalsFirstInning", "totalsSecondInning", "totalsThirdInning", "totalsFourthInning", "totalsFifthInning", "totalsSixthInning", "totalsSeventhInning", "totalsEighthInning", "totalsNinthInning"];
const allTeamTotalsMarkets = ["teamTotals", "teamTotalsGames", "teamTotalsPoints", "teamTotalsFirstHalf", "teamTotalsSecondHalf", "teamTotalsFirstQuarter", "teamTotalsSecondQuarter", "teamTotalsThirdQuarter", "teamTotalsFourthQuarter", "teamTotalsFirstSet", "teamTotalsSecondSet", "teamTotalsThirdSet", "teamTotalsFourthSet", "teamTotalsFifthSet", "teamTotalsGamesFirstSet", "teamTotalsGamesSecondSet", "teamTotalsGamesThirdSet", "teamTotalsGamesFourthSet", "teamTotalsGamesFifthSet", "teamTotalsPointsFirstSet", "teamTotalsPointsSecondSet", "teamTotalsPointsThirdSet", "teamTotalsPointsFourthSet", "teamTotalsPointsFifthSet", "teamTotalsFirstPeriod", "teamTotalsSecondPeriod", "teamTotalsThirdPeriod", "teamTotalsRegularTime", "teamTotalsFirstInning", "teamTotalsSecondInning", "teamTotalsThirdInning", "teamTotalsFourthInning", "teamTotalsFifthInning", "teamTotalsSixthInning", "teamTotalsSeventhInning", "teamTotalsEighthInning", "teamTotalsNinthInning"];
const pointsUnitsBySport = {
    football: i18n.t("buts"),
    tennis: { games: i18n.t("jeux"), sets: i18n.t("sets") },
    basketball: i18n.t("points"),
    baseball: i18n.t("points"),
    rugby: i18n.t("points"),
    americanFootball: i18n.t("points"),
    hockey: i18n.t("buts"),
    handball: i18n.t("points"),
    volleyball: { points: i18n.t("points"), sets: i18n.t("sets") }
}

const stylizedSport = {
    "football": <IoMdFootball fontSize={30} />,
    "tennis": <BiSolidTennisBall fontSize={30} />,
    "basketball": <FaBasketballBall fontSize={30} />,
    "baseball": <CiBaseball fontSize={30} />,
    "rugby": <MdSportsRugby fontSize={30} />,
    "americanFootball": <FaFootballBall fontSize={30} />,
    "hockey": <FaHockeyPuck fontSize={30} />,
    "handball": <MdSportsHandball fontSize={30} />,
    "volleyball": <FaVolleyballBall fontSize={30} />
};
const allSports = [
  { value: "football", label: i18n.t(i18n.t("Football")) },
  { value: "tennis", label: i18n.t("Tennis") },
  { value: "basketball", label: i18n.t("Basketball") },
  { value: "baseball", label: i18n.t("Baseball") },
  { value: "rugby", label: i18n.t("Rugby") },
  { value: "americanFootball", label: i18n.t("Football américain") },
  { value: "hockey", label: i18n.t("Hockey sur glace") },
  { value: "handball", label: i18n.t("Handball") },
  { value: "volleyball", label: i18n.t("Volleyball") },
];
const stylizedSportsName = { 
    "football": i18n.t("Football"), 
    "tennis": i18n.t("Tennis"), 
    "basketball": i18n.t("Basketball"), 
    "baseball": i18n.t("Baseball"), 
    "rugby": i18n.t("Rugby"), 
    "americanFootball": i18n.t("Football américain"), 
    "hockey": i18n.t("Hockey sur glace"), 
    "handball": i18n.t("Handball"), 
    "volleyball": i18n.t("Volleyball") 
};

const stylizedBookName = { 
	"betclic": "BetClic", 
	"psel": "PSEL", 
	"netbet": "NetBet", 
	"winamax": "WinAMax", 
	"unibet": "UniBet", 
	"zebet": "ZeBet", 
	"pmu": "PMU", 
	"francepari": "FrancePari", 
	"genybet": "GenyBet", 
	"feelingbet" : "FeelingBet", 
	"bwin" : "Bwin", 

	"stake": "Stake", 

	"mystake": "MyStake", 
	"freshbet": "FreshBet", 
	"31bet": "31Bet", 
	"goldenbet": "GoldenBet", 
	"jackbit": "JackBit", 
	"velobet": "VeloBet", 
	"rolletto": "Rolletto", 
	"donbet": "DonBet", 
	"cosmobet": "CosmoBet", 
	'20bets': '20Bets', 

	'1xbet': '1xBet', 
	'bet2fun': 'Bet2Fun', 
	'22bet': '22Bet', 
	'coinplay': 'CoinPlay', 
	'vivatbet': 'VivatBet', 
	'melbet': 'MelBet', 
	'betwinner': 'BetWinner', 
	'888starz': '888Starz', 
	'betpari': 'BetPari', 
	'helabet': 'HelaBet', 
	'megapari': 'MegaPari', 
	'paripesa': 'PariPesa', 
	'sapphirebet': 'SapphireBet', 

	'yonibet': 'YoniBet', 
    'casinozer': 'Casinozer', 
    'betify': 'Betify', 
    'bcgame': 'BCGame', 
    'roobet': 'RooBet', 
    'rollbit': 'RollBit', 
    'ladylinda': 'LadyLinda',
    'bdmbet': 'BdmBet',
    'betspino': 'BetSpino',
    'betiro': 'Betiro',
    'betonred': 'BetOnRed',
    'bitubet': 'BitUBet',
    'casinoestrella': 'CasinoEstrella',
    'cryptoleo': 'CryptoLeo',
    'hexabet2': 'HexaBet2',
    'rollino': 'Rollino',
    'superboss': 'SuperBoss',
    'wsmcasino': 'WsmCasino',
    'bluechip': 'BlueChip',
    'bons': 'Bons',
    'casinox': 'Casino-x',
    'fortunejack': 'FortuneJack',
    'wettenlive': 'WettenLive',
    'betfury': 'BetFury',
    'ramenbet': 'RamenBet',
    'ninecasino': 'NineCasino',
    'brunocasino': 'BrunoCasino',
    'joycasino': 'JoyCasino',
    'anonymbet': 'AnonymBet',
    'bettilt': 'BetTilt',
    'bitz9': 'Bitz9',
    'blackmagiccasino': 'BlackMagicCasino',
    'bloodmooncasino': 'BloodMoonCasino',
    'chillbet': 'ChillBet',
    'chips': 'Chips',
    'crashino': 'Crashino',
    'esportiva': 'Esportiva',
    'goldenreels': 'GoldenReels',
    'hiperwin': 'HiperWin',
    'luckofspins': 'LuckOfSpins',
    'luckyblock': 'LuckyBlock',
    'megadice': 'MegaDice',
    'slotsnbets': 'SlotsNBets',
    'playersclubvip': 'PlayersClubVip',
    'royaloakcasino': 'RoyalOakCasino',
    'snatchcasino6': 'SnatchCasino6',
    'tivitbet': 'TivitBet',
    "bet7k": "Bet7K",
    "blaze1": "Blaze1",
    "slotssafari": "SlotsSafari",
    "trbet": "TrBet",
    "rajabets": "RajaBets",
    "captainsbet": "CaptainsBet",

    'betonline': 'BetOnline', 
    'sportsbetting': 'SportsBetting', 
    'tigergaming': 'TigerGaming', 

    "betrebels": "BetRebels", 
    "sportaza": "Sportaza", 
    "rabona": "Rabona", 
    "fezbet": "FezBet", 
    "amunra": "Amunra",
	"maxxwin": "MaxxWin",
    "24bettle": "24Bettle",
    "4kasino": "4Kasino",
    "bbets": "BBets",
    "bankonbet": "BankOnBet",
    "betinia": "Betinia",
    "betriot": "BetRiot",
    "betti": "Betti",
    "campeonbet": "CampeOnBet",
    "big5casino": "Big5Casino",
    "cashed": "Cashed",
    "gozabet": "GozaBet",
    "casinoly": "Casinoly",
    "divasluckcasino": "DivasLuckCasino",
    "excitewin": "ExciteWin",
    "greatwin": "GreatWin",
    "lightcasino": "LightCasino",
    "luckybull": "LuckyBull",
    "gambeta10": "Gambeta10",
    "casinosieger": "CasinoSieger",
    "mrpacho": "MrPacho",
    "nomini": "Nomini",
    "palmsbet": "PalmsBet",
    "playzilla": "PlayZilla",
    "ohmyspins": "OhMySpins",
    "powbet": "PowBet",
    "slotshammer": "SlotsHammer",
    "svenplay": "SvenPlay",
    "viparabclub": "VipArabClub",
    "wazamba": "Wazamba",
    "winpot": "Winpot",
    "zet": "Zet",
    "rembrandtcasino": "RembrandtCasino",
    "pedalada10": "Pedalada10",
	"spincashwin": "SpinCashWin",
    "evobet": "EvoBet",

    "betsio": "Bets.io", 
    "cobracasino": "CobraCasino", 
    "woocasino": "WooCasino", 
    "qbet": "QBet",

	"cloverspin": "CloverSpin", 
    "winz": "Winz", 
    "1red": "1Red", 
    "betibet": "BetiBet", 
    "n1bet": "N1Bet", 
    "wild": "Wild", 
    "rocketplay": "RocketPlay", 
    "belabet": "BelaBet", 
    "platincasino": "PlatinCasino", 
    "sixdot": "SixDot", 
    "zotabet": "ZotaBet", 
    "lamabet": "LamaBet", 
    "betbeast": "BetBeast", 
    "metaspins": "MetaSpins", 
    "dailyspins": "DailySpins", 
    "coinsgame": "CoinsGame",

    "unibetcom": "UniBet.com"
};

const allFrenchBooks = [
    { value: "betclic", label: "BetClic" },
    { value: "psel", label: "PSEL" },
    { value: "netbet", label: "NetBet" },
    { value: "winamax", label: "WinAMax" },
    { value: "unibet", label: "UniBet" },
    { value: "zebet", label: "ZeBet" },
    { value: "pmu", label: "PMU" },
    { value: "francepari", label: "FrancePari" },
    { value: "genybet", label: "GenyBet" },
    { value: "feelingbet", label: "FeelingBet" },
    { value: "bwin", label: "Bwin" }
];

const allWorldBooks = {
	"Stake": [
    	{ value: "stake", label: "Stake" }
	],

	"MyStake": [
		{ value: "mystake", label: "MyStake" },
		{ value: "freshbet", label: "FreshBet" },
		{ value: "31bet", label: "31Bet" },
		{ value: "goldenbet", label: "GoldenBet" },
		{ value: "jackbit", label: "JackBit" },
		{ value: "velobet", label: "VeloBet" }, 
		{ value: "rolletto", label: "Rolletto" }, 
		{ value: "donbet", label: "DonBet" }, 
		{ value: "cosmobet", label: "CosmoBet" },
		{ value: '20bets', label: '20Bets' }
	],

	"1xBet": [
		{ value: "1xbet", label: "1xBet"},
		{ value: "bet2fun", label: "Bet2Fun"},
		{ value: "22bet", label: "22Bet"},
		{ value: "coinplay", label: "CoinPlay"},
		{ value: "vivatbet", label: "VivatBet"},
		{ value: "melbet", label: "MelBet"},
		{ value: "betwinner", label: "BetWinner"},
		{ value: "888starz", label: "888Starz"},
		{ value: "betpari", label: "BetPari"},
		{ value: "helabet", label: "HelaBet"},
		{ value: "megapari", label: "MegaPari"},
		{ value: "paripesa", label: "PariPesa"},
		{ value: "sapphirebet", label: "SapphireBet"}
	],

	"YoniBet": [
		{ value: "yonibet", label: "YoniBet"},
		{ value: "casinozer", label: "Casinozer"},
		{ value: "betify", label: "Betify"},
		{ value: "bcgame", label: "BCGame"},
		{ value: "roobet", label: "RooBet"},
		{ value: "rollbit", label: "RollBit"}, 
		{ value: 'ladylinda', label: 'LadyLinda' },
		{ value: 'bdmbet', label: 'BdmBet' },
		{ value: 'betspino', label: 'BetSpino' },
		{ value: 'betiro', label: 'Betiro' },
		{ value: 'betonred', label: 'BetOnRed' },
		{ value: 'bitubet', label: 'BitUBet' },
		{ value: 'casinoestrella', label: 'CasinoEstrella' },
		{ value: 'cryptoleo', label: 'CryptoLeo' },
		{ value: 'hexabet2', label: 'HexaBet2' },
		{ value: 'rollino', label: 'Rollino' },
		{ value: 'superboss', label: 'SuperBoss' },
		{ value: 'wsmcasino', label: 'WsmCasino' },
		{ value: 'bluechip', label: 'BlueChip' },
		{ value: 'bons', label: 'Bons' },
		{ value: 'casinox', label: 'Casino-x' },
		{ value: 'fortunejack', label: 'FortuneJack' },
		{ value: 'wettenlive', label: 'WettenLive' },
		{ value: 'betfury', label: 'BetFury' },
		{ value: 'ramenbet', label: 'RamenBet' },
		{ value: 'ninecasino', label: 'NineCasino' },
		{ value: 'brunocasino', label: 'BrunoCasino' },
		{ value: 'joycasino', label: 'JoyCasino' },
		{ value: 'anonymbet', label: 'AnonymBet' },
		{ value: 'bettilt', label: 'BetTilt' },
		{ value: 'bitz9', label: 'Bitz9' },
		{ value: 'blackmagiccasino', label: 'BlackMagicCasino' },
		{ value: 'bloodmooncasino', label: 'BloodMoonCasino' },
		{ value: 'chillbet', label: 'ChillBet' },
		{ value: 'chips', label: 'Chips' },
		{ value: 'crashino', label: 'Crashino' },
		{ value: 'esportiva', label: 'Esportiva' },
		{ value: 'goldenreels', label: 'GoldenReels' },
		{ value: 'hiperwin', label: 'HiperWin' },
		{ value: 'luckofspins', label: 'LuckOfSpins' },
		{ value: 'luckyblock', label: 'LuckyBlock' },
		{ value: 'megadice', label: 'MegaDice' },
		{ value: 'slotsnbets', label: 'SlotsNBets' },
		{ value: 'playersclubvip', label: 'PlayersClubVip' },
		{ value: 'royaloakcasino', label: 'RoyalOakCasino' },
		{ value: 'snatchcasino6', label: 'SnatchCasino6' },
		{ value: 'tivitbet', label: 'TivitBet' },
		{ value: 'bet7k', label: 'Bet7K' },
		{ value: 'blaze1', label: 'Blaze1' },
		{ value: 'slotssafari', label: 'SlotsSafari' },
		{ value: 'trbet', label: 'TrBet' },
		{ value: 'rajabets', label: 'RajaBets' },
		{ value: 'captainsbet', label: 'CaptainsBet' }
	],

	"BetOnline": [
		{ value: "betonline", label: "BetOnline"},
		{ value: 'sportsbetting', label: 'SportsBetting' },
		{ value: 'tigergaming', label: 'TigerGaming' }
	],

	"BetRebels": [
		{ value: "betrebels", label: "BetRebels" },
		{ value: "sportaza", label: "Sportaza" },
		{ value: "rabona", label: "Rabona" },
		{ value: "fezbet", label: "FezBet" },
		{ value: "amunra", label: "Amunra" },
		{ value: "maxxwin", label: "MaxxWin" },
		{ value: "24bettle", label: "24Bettle" },
		{ value: "4kasino", label: "4Kasino" },
		{ value: "bbets", label: "BBets" },
		{ value: "bankonbet", label: "BankOnBet" },
		{ value: "betinia", label: "Betinia" },
		{ value: "betriot", label: "BetRiot" },
		{ value: "betti", label: "Betti" },
		{ value: "campeonbet", label: "CampeOnBet" },
		{ value: "big5casino", label: "Big5Casino" },
		{ value: "cashed", label: "Cashed" },
		{ value: "gozabet", label: "GozaBet" },
		{ value: "casinoly", label: "Casinoly" },
		{ value: "divasluckcasino", label: "DivasLuckCasino" },
		{ value: "excitewin", label: "ExciteWin" },
		{ value: "greatwin", label: "GreatWin" },
		{ value: "lightcasino", label: "LightCasino" },
		{ value: "luckybull", label: "LuckyBull" },
		{ value: "gambeta10", label: "Gambeta10" },
		{ value: "casinosieger", label: "CasinoSieger" },
		{ value: "mrpacho", label: "MrPacho" },
		{ value: "nomini", label: "Nomini" },
		{ value: "palmsbet", label: "PalmsBet" },
		{ value: "playzilla", label: "PlayZilla" },
		{ value: "ohmyspins", label: "OhMySpins" },
		{ value: "powbet", label: "PowBet" },
		{ value: "slotshammer", label: "SlotsHammer" },
		{ value: "svenplay", label: "SvenPlay" },
		{ value: "viparabclub", label: "VipArabClub" },
		{ value: "wazamba", label: "Wazamba" },
		{ value: "winpot", label: "Winpot" },
		{ value: "zet", label: "Zet" },
		{ value: "rembrandtcasino", label: "RembrandtCasino" },
		{ value: "pedalada10", label: "Pedalada10" },
		{ value: "spincashwin", label: "SpinCashWin" },
		{ value: "evobet", label: "EvoBet" }
	],

	"Bets.io": [
		{ value: "betsio", label: "Bets.io"},
		{ value: "cobracasino", label: "CobraCasino"},
		{ value: "woocasino", label: "WooCasino"},
		{ value: "qbet", label: "QBet"}
	],

	"CloverSpin": [
		{ value: "cloverspin", label: "CloverSpin" }, 
		{ value: "winz", label: "Winz" }, 
		{ value: "1red", label: "1Red" }, 
		{ value: "betibet", label: "BetiBet" }, 
		{ value: "n1bet", label: "N1Bet" }, 
		{ value: "wild", label: "Wild" }, 
		{ value: "rocketplay", label: "RocketPlay" }, 
		{ value: "belabet", label: "BelaBet" }, 
		{ value: "platincasino", label: "PlatinCasino" }, 
		{ value: "sixdot", label: "SixDot" }, 
		{ value: "zotabet", label: "ZotaBet" }, 
		{ value: "lamabet", label: "LamaBet" }, 
		{ value: "betbeast", label: "BetBeast" }, 
		{ value: "metaspins", label: "MetaSpins" }, 
		{ value: "dailyspins", label: "DailySpins" }, 
		{ value: "coinsgame", label: "CoinsGame" },
	],

	"UniBet": [
		{ value: "unibetcom", label: "UniBet.com" }
	]
};

const allWorldBooksByGroup = {
    "stake": ["stake"], 
    "mystake": ["mystake", "freshbet", "31bet", "goldenbet", "jackbit", "velobet", "rolletto", "donbet", "cosmobet", '20bets'],
    '1xbet': ['1xbet', 'bet2fun', '22bet', 'coinplay', 'vivatbet', 'melbet', 'betwinner', '888starz', 'betpari', 'helabet', 'megapari', 'paripesa', 'sapphirebet'],
    'yonibet': ['yonibet', 'casinozer', 'betify', 'bcgame', 'roobet', 'rollbit', 'ladylinda', 'bdmbet', 'betspino', 'betiro', 'betonred', 'bitubet', 'casinoestrella', 'cryptoleo', 'hexabet2', 'rollino', 'superboss', 'wsmcasino', 'bluechip', 'bons', 'casinox', 'fortunejack', 'wettenlive', 'betfury', 'ramenbet', 'ninecasino', 'brunocasino', 'joycasino', 'anonymbet', 'bettilt', 'bitz9', 'blackmagiccasino', 'bloodmooncasino', 'chillbet', 'chips', 'crashino', 'esportiva', 'goldenreels', 'hiperwin', 'luckofspins', 'luckyblock', 'megadice', 'slotsnbets', 'playersclubvip', 'royaloakcasino', 'snatchcasino6', 'tivitbet', "bet7k", "blaze1", "slotssafari", "trbet", "rajabets", "captainsbet"],
    'betonline': ['betonline', 'sportsbetting', 'tigergaming'],
    "betrebels": ["betrebels", "sportaza", "rabona", "fezbet", "amunra", "maxxwin", "24bettle", "4kasino", "bbets", "bankonbet", "betinia", "betriot", "betti", "campeonbet", "big5casino", "cashed", "gozabet", "casinoly", "divasluckcasino", "excitewin", "greatwin", "lightcasino", "luckybull", "gambeta10", "casinosieger", "mrpacho", "nomini", "palmsbet", "playzilla", "ohmyspins", "powbet", "slotshammer", "svenplay", "viparabclub", "wazamba", "winpot", "zet", "rembrandtcasino", "pedalada10", "spincashwin", "evobet"],
    "betsio": ["betsio", "cobracasino", "woocasino"],
    "qbet": ["qbet"],
    "cloverspin": ["cloverspin", "winz", "1red", "betibet", "n1bet", "wild", "rocketplay", "belabet", "platincasino", "sixdot", "zotabet", "lamabet", "betbeast", "metaspins", "dailyspins", "coinsgame"],
    "unibetcom": ["unibetcom"]
};

const allMarkets = [
    {
        label: "Football",
        options: [
            { value: "football_moneyline", label: i18n.t("1N2 (temps réglementaire)") },
            { value: "football_handicap", label: i18n.t("Écart de buts (temps réglementaire)") },
            { value: "football_totals", label: i18n.t("Nombre de buts (temps réglementaire)") },
            { value: "football_teamTotals", label: i18n.t("Nombre de buts par une équipe (temps réglementaire)") },
            { value: "football_moneylineFirstHalf", label: i18n.t("1N2 (1ère mi-temps)") },
            { value: "football_handicapFirstHalf", label: i18n.t("Écart de buts (1ère mi-temps)") },
            { value: "football_totalsFirstHalf", label: i18n.t("Nombre de buts (1ère mi-temps)") },
            { value: "football_teamTotalsFirstHalf", label: i18n.t("Nombre de buts par une équipe (1ère mi-temps)") },
            { value: "football_moneylineSecondHalf", label: i18n.t("1N2 (2ème mi-temps)") },
            { value: "football_handicapSecondHalf", label: i18n.t("Écart de buts (2ème mi-temps)") },
            { value: "football_totalsSecondHalf", label: i18n.t("Nombre de buts (2ème mi-temps)") },
            { value: "football_teamTotalsSecondHalf", label: i18n.t("Nombre de buts par une équipe (2ème mi-temps)") },
            { value: "football_doubleChance", label: i18n.t("Double Chance (temps réglementaire)") },
            { value: "football_doubleChanceFirstHalf", label: i18n.t("Double Chance (1ère mi-temps)") },
            { value: "football_drawNoBet", label: i18n.t("Remboursé si nul (temps réglementaire)") },
            { value: "football_drawNoBetFirstHalf", label: i18n.t("Remboursé si nul (1ère mi-temps)") },
            { value: "football_teamToWinToNil", label: i18n.t("Gagne sans encaisser de but") },
            { value: "football_teamToWinToNilFirstHalf", label: i18n.t("Gagne sans encaisser de but (1ère mi-temps)") },
            { value: "football_oddEvenTotals", label: i18n.t("Nombre de buts pair/impair (temps réglementaire)") },
            { value: "football_oddEvenTotalsFirstHalf", label: i18n.t("Nombre de buts pair/impair (1ère mi-temps)") },
            { value: "football_oddEvenTotalsAndTotals", label: i18n.t("Nombre de buts pair/impair + Nombre de buts (temps réglementaire)") },
            { value: "football_oddEvenTeamTotals", label: i18n.t("Nombre de buts pair/impair d'une équipe (temps réglementaire)") },
            { value: "football_teamToScore", label: i18n.t("L'équipe va marquer (temps réglementaire)") },
            { value: "football_teamToScoreFirstHalf", label: i18n.t("L'équipe va marquer (1ère mi-temps)") },
            { value: "football_bothTeamToScore", label: i18n.t("Les deux équipes marquent (temps réglementaire)") },
            { value: "football_bothTeamToScoreFirstHalf", label: i18n.t("Les deux équipes marquent (1ère mi-temps)") },
            { value: "football_eitherTeamToScore", label: i18n.t("Aucune équipe ne marque (temps réglementaire)") },
            { value: "football_eitherTeamToScoreFirstHalf", label: i18n.t("Aucune équipe ne marque (1ère mi-temps)") },
            { value: "football_firstTeamToScore", label: i18n.t("Première équipe à marquer (temps réglementaire)") },
            { value: "football_winnerAndTotals", label: i18n.t("Vainqueur + Nombre de buts (temps réglementaire)") },
            { value: "football_halfTimeAndFullTime", label: i18n.t("Vainqueur de la mi-temps + Vainqueur final (temps réglementaire)") },
            { value: "football_bothTeamToScoreAndTotals", label: i18n.t("Les deux équipes marquent + Nombre de buts (temps réglementaire)") },
            { value: "football_bothTeamToScoreAndWinner", label: i18n.t("Les deux équipes marquent + Vainqueur (temps réglementaire)") }
        ]
    },
    {
        label: "Tennis",
        options: [
            { value: "tennis_moneyline", label: i18n.t("Vainqueur (match)") },
            { value: "tennis_handicap", label: i18n.t("Écart de sets (match)") },
            { value: "tennis_totals", label: i18n.t("Nombre de sets (match)") },
            { value: "tennis_teamTotals", label: i18n.t("Nombre de sets d'un joueur (match)") },
            { value: "tennis_moneylineGames", label: i18n.t("Vainqueur en nombre de jeux (match)") },
            { value: "tennis_handicapGames", label: i18n.t("Écart de jeux (match)") },
            { value: "tennis_totalsGames", label: i18n.t("Nombre de jeux (match)") },
            { value: "tennis_teamTotalsGames", label: i18n.t("Nombre de jeux d'un joueur (match)") },
            { value: "tennis_moneylineFirstSet", label: i18n.t("Vainqueur (1er set)") },
            { value: "tennis_handicapGamesFirstSet", label: i18n.t("Écart de jeux (1er set)") },
            { value: "tennis_totalsGamesFirstSet", label: i18n.t("Nombre de jeux (1er set)") },
            { value: "tennis_teamTotalsGamesFirstSet", label: i18n.t("Nombre de jeux d'un joueur (1er set)") }
        ]
    },
    {
        label: "Basketball",
        options: [
            { value: "basketball_moneyline", label: i18n.t("Moneyline (prolongations incluses)") },
            { value: "basketball_handicap", label: i18n.t("Écart de points (prolongations incluses)") },
            { value: "basketball_totals", label: i18n.t("Nombre de points (prolongations incluses)") },
            { value: "basketball_teamTotals", label: i18n.t("Nombre de points d'une équipe (prolongations incluses)") },
            { value: "basketball_moneylineFirstHalf", label: i18n.t("Moneyline (1ère mi-temps)") },
            { value: "basketball_handicapFirstHalf", label: i18n.t("Écart de points (1ère mi-temps)") },
            { value: "basketball_totalsFirstHalf", label: i18n.t("Nombre de points (1ère mi-temps)") },
            { value: "basketball_teamTotalsFirstHalf", label: i18n.t("Nombre de points d'une équipe (1ère mi-temps)") },
            { value: "basketball_moneylineSecondHalf", label: i18n.t("Moneyline (2ème mi-temps)") },
            { value: "basketball_handicapSecondHalf", label: i18n.t("Écart de points (2ème mi-temps)") },
            { value: "basketball_totalsSecondHalf", label: i18n.t("Nombre de points (2ème mi-temps)") },
            { value: "basketball_teamTotalsSecondHalf", label: i18n.t("Nombre de points d'une équipe (2ème mi-temps)") },
            { value: "basketball_moneylineFirstQuarter", label: i18n.t("Moneyline (1er quart-temps)") },
            { value: "basketball_handicapFirstQuarter", label: i18n.t("Écart de points (1er quart-temps)") },
            { value: "basketball_totalsFirstQuarter", label: i18n.t("Nombre de points (1er quart-temps)") },
            { value: "basketball_teamTotalsFirstQuarter", label: i18n.t("Nombre de points d'une équipe (1er quart-temps)") },
            { value: "basketball_moneylineSecondQuarter", label: i18n.t("Moneyline (2ème quart-temps)") },
            { value: "basketball_handicapSecondQuarter", label: i18n.t("Écart de points (2ème quart-temps)") },
            { value: "basketball_totalsSecondQuarter", label: i18n.t("Nombre de points (2ème quart-temps)") },
            { value: "basketball_teamTotalsSecondQuarter", label: i18n.t("Nombre de points d'une équipe (2ème quart-temps)") },
            { value: "basketball_moneylineThirdQuarter", label: i18n.t("Moneyline (3ème quart-temps)") },
            { value: "basketball_handicapThirdQuarter", label: i18n.t("Écart de points (3ème quart-temps)") },
            { value: "basketball_totalsThirdQuarter", label: i18n.t("Nombre de points (3ème quart-temps)") },
            { value: "basketball_teamTotalsThirdQuarter", label: i18n.t("Nombre de points d'une équipe (3ème quart-temps)") },
            { value: "basketball_moneylineFourthQuarter", label: i18n.t("Moneyline (4ème quart-temps)") },
            { value: "basketball_handicapFourthQuarter", label: i18n.t("Écart de points (4ème quart-temps)") },
            { value: "basketball_totalsFourthQuarter", label: i18n.t("Nombre de points (4ème quart-temps)") },
            { value: "basketball_teamTotalsFourthQuarter", label: i18n.t("Nombre de points d'une équipe (4ème quart-temps)") },
            { value: "basketball_oddEvenTotals", label: i18n.t("Nombre de points pair/impair (prolongations incluses)") },
            { value: "basketball_oddEvenTeamTotals", label: i18n.t("Nombre de points pair/impair d'une équipe (prolongations incluses)") }
        ]
    },
    {
        label: "Baseball",
        options: [
            { value: "baseball_moneyline", label: i18n.t("Moneyline") },
            { value: "baseball_handicap", label: i18n.t("Écart de points") },
            { value: "baseball_totals", label: i18n.t("Nombre de points") },
            { value: "baseball_teamTotals", label: i18n.t("Nombre de points d'une équipe") },
            { value: "baseball_moneylineFirstHalf", label: i18n.t("Moneyline (1ère mi-temps)") },
            { value: "baseball_handicapFirstHalf", label: i18n.t("Écart de points (1ère mi-temps)") },
            { value: "baseball_totalsFirstHalf", label: i18n.t("Nombre de points (1ère mi-temps)") },
            { value: "baseball_teamTotalsFirstHalf", label: i18n.t("Nombre de points d'une équipe (1ère mi-temps)") },
            { value: "baseball_moneylineSecondHalf", label: i18n.t("Moneyline (2ème mi-temps)") },
            { value: "baseball_handicapSecondHalf", label: i18n.t("Écart de points (2ème mi-temps)") },
            { value: "baseball_totalsSecondHalf", label: i18n.t("Nombre de points (2ème mi-temps)") },
            { value: "baseball_teamTotalsSecondHalf", label: i18n.t("Nombre de points d'une équipe (2ème mi-temps)") },
            { value: "baseball_moneylineFirstInning", label: i18n.t("Moneyline (1ère manche)") },
            { value: "baseball_handicapFirstInning", label: i18n.t("Écart de points (1ère manche)") },
            { value: "baseball_totalsFirstInning", label: i18n.t("Nombre de points (1ère manche)") },
            { value: "baseball_teamTotalsFirstInning", label: i18n.t("Nombre de points d'une équipe (1ère manche)") },
            { value: "baseball_moneylineSecondInning", label: i18n.t("Moneyline (2ème manche)") },
            { value: "baseball_handicapSecondInning", label: i18n.t("Écart de points (2ème manche)") },
            { value: "baseball_totalsSecondInning", label: i18n.t("Nombre de points (2ème manche)") },
            { value: "baseball_teamTotalsSecondInning", label: i18n.t("Nombre de points d'une équipe (2ème manche)") },
            { value: "baseball_moneylineThirdInning", label: i18n.t("Moneyline (3ème manche)") },
            { value: "baseball_handicapThirdInning", label: i18n.t("Écart de points (3ème manche)") },
            { value: "baseball_totalsThirdInning", label: i18n.t("Nombre de points (3ème manche)") },
            { value: "baseball_teamTotalsThirdInning", label: i18n.t("Nombre de points d'une équipe (3ème manche)") },
            { value: "baseball_moneylineFourthInning", label: i18n.t("Moneyline (4ème manche)") },
            { value: "baseball_handicapFourthInning", label: i18n.t("Écart de points (4ème manche)") },
            { value: "baseball_totalsFourthInning", label: i18n.t("Nombre de points (4ème manche)") },
            { value: "baseball_teamTotalsFourthInning", label: i18n.t("Nombre de points d'une équipe (4ème manche)") },
            { value: "baseball_moneylineFifthInning", label: i18n.t("Moneyline (5ème manche)") },
            { value: "baseball_handicapFifthInning", label: i18n.t("Écart de points (5ème manche)") },
            { value: "baseball_totalsFifthInning", label: i18n.t("Nombre de points (5ème manche)") },
            { value: "baseball_teamTotalsFifthInning", label: i18n.t("Nombre de points d'une équipe (5ème manche)") },
            { value: "baseball_moneylineSixthInning", label: i18n.t("Moneyline (6ème manche)") },
            { value: "baseball_handicapSixthInning", label: i18n.t("Écart de points (6ème manche)") },
            { value: "baseball_totalsSixthInning", label: i18n.t("Nombre de points (6ème manche)") },
            { value: "baseball_teamTotalsSixthInning", label: i18n.t("Nombre de points d'une équipe (6ème manche)") },
            { value: "baseball_moneylineSeventh", label: i18n.t("Moneyline (7ème manche)") },
            { value: "baseball_handicapSeventh", label: i18n.t("Écart de points (7ème manche)") },
            { value: "baseball_totalsSeventh", label: i18n.t("Nombre de points (7ème manche)") },
            { value: "baseball_teamTotalsSeventh", label: i18n.t("Nombre de points d'une équipe (7ème manche)") },
            { value: "baseball_moneylineEighth", label: i18n.t("Moneyline (8ème manche)") },
            { value: "baseball_handicapEighth", label: i18n.t("Écart de points (8ème manche)") },
            { value: "baseball_totalsEighth", label: i18n.t("Nombre de points (8ème manche)") },
            { value: "baseball_teamTotalsEighth", label: i18n.t("Nombre de points d'une équipe (8ème manche)") },
            { value: "baseball_moneylineNinthInning", label: i18n.t("Moneyline (9ème manche)") },
            { value: "baseball_handicapNinthInning", label: i18n.t("Écart de points (9ème manche)") },
            { value: "baseball_totalsNinthInning", label: i18n.t("Nombre de points (9ème manche)") },
            { value: "baseball_teamTotalsNinthInning", label: i18n.t("Nombre de points d'une équipe (9ème manche)") }
        ]
    },
    {
        label: "Hockey",
        options: [
            { value: "hockey_moneyline", label: i18n.t("Moneyline (prolongations incluses)") },
            { value: "hockey_handicap", label: i18n.t("Écart de buts (prolongations incluses)") },
            { value: "hockey_totals", label: i18n.t("Nombre de buts (prolongations incluses)") },
            { value: "hockey_teamTotals", label: i18n.t("Nombre de buts d'une équipe (prolongations incluses)") },
            { value: "hockey_moneylineFirstPeriod", label: i18n.t("Moneyline (1ère période)") },
            { value: "hockey_handicapFirstPeriod", label: i18n.t("Écart de buts (1ère période)") },
            { value: "hockey_totalsFirstPeriod", label: i18n.t("Nombre de buts (1ère période)") },
            { value: "hockey_teamTotalsFirstPeriod", label: i18n.t("Nombre de buts d'une équipe (1ère période)") },
            { value: "hockey_moneylineSecondPeriod", label: i18n.t("Moneyline (2ème période)") },
            { value: "hockey_handicapSecondPeriod", label: i18n.t("Écart de buts (2ème période)") },
            { value: "hockey_totalsSecondPeriod", label: i18n.t("Nombre de buts (2ème période)") },
            { value: "hockey_teamTotalsSecondPeriod", label: i18n.t("Nombre de buts d'une équipe (2ème période)") },
            { value: "hockey_moneylineThirdPeriod", label: i18n.t("Moneyline (3ème période)") },
            { value: "hockey_handicapThirdPeriod", label: i18n.t("Écart de buts (3ème période)") },
            { value: "hockey_totalsThirdPeriod", label: i18n.t("Nombre de buts (3ème période)") },
            { value: "hockey_teamTotalsThirdPeriod", label: i18n.t("Nombre de buts d'une équipe (3ème période)") },
            { value: "hockey_moneylineRegularTime", label: i18n.t("1N2 (temps réglementaire)") },
            { value: "hockey_handicapRegularTime", label: i18n.t("Écart de buts (temps réglementaire)") },
            { value: "hockey_totalsRegularTime", label: i18n.t("Nombre de buts (temps réglementaire)") },
            { value: "hockey_teamTotalsRegularTime", label: i18n.t("Nombre de buts d'une équipe (temps réglementaire)") },
            { value: "hockey_teamToScore", label: i18n.t("L'équipe va marquer (prolongations incluses)") },
            { value: "hockey_teamToWinToNil", label: i18n.t("L'équipe gagne sans encaisser de but (prolongations incluses)") },
            { value: "hockey_winnerAndTotals", label: i18n.t("Vainqueur + Nombre de buts (prolongations incluses)") }
        ]
    },
    {
        label: "Football américain",
        options: [
            { value: "americanFootball_moneyline", label: i18n.t("Moneyline (prolongations incluses)") },
            { value: "americanFootball_handicap", label: i18n.t("Écart de points (prolongations incluses)") },
            { value: "americanFootball_totals", label: i18n.t("Nombre de points (prolongations incluses)") },
            { value: "americanFootball_teamTotals", label: i18n.t("Nombre de points d'une équipe (prolongations incluses)") },
            { value: "americanFootball_moneylineFirstHalf", label: i18n.t("Moneyline (1ère mi-temps)") },
            { value: "americanFootball_handicapFirstHalf", label: i18n.t("Écart de points (1ère mi-temps)") },
            { value: "americanFootball_totalsFirstHalf", label: i18n.t("Nombre de points (1ère mi-temps)") },
            { value: "americanFootball_teamTotalsFirstHalf", label: i18n.t("Nombre de points d'une équipe (1ère mi-temps)") },
            { value: "americanFootball_moneylineSecondHalf", label: i18n.t("Moneyline (2ème mi-temps)") },
            { value: "americanFootball_handicapSecondHalf", label: i18n.t("Écart de points (2ème mi-temps)") },
            { value: "americanFootball_totalsSecondHalf", label: i18n.t("Nombre de points (2ème mi-temps)") },
            { value: "americanFootball_teamTotalsSecondHalf", label: i18n.t("Nombre de points d'une équipe (2ème mi-temps)") },
            { value: "americanFootball_moneylineFirstQuarter", label: i18n.t("Moneyline (1er quart-temps)") },
            { value: "americanFootball_handicapFirstQuarter", label: i18n.t("Écart de points (1er quart-temps)") },
            { value: "americanFootball_totalsFirstQuarter", label: i18n.t("Nombre de points (1er quart-temps)") },
            { value: "americanFootball_teamTotalsFirstQuarter", label: i18n.t("Nombre de points d'une équipe (1er quart-temps)") },
            { value: "americanFootball_moneylineSecondQuarter", label: i18n.t("Moneyline (2ème quart-temps)") },
            { value: "americanFootball_handicapSecondQuarter", label: i18n.t("Écart de points (2ème quart-temps)") },
            { value: "americanFootball_totalsSecondQuarter", label: i18n.t("Nombre de points (2ème quart-temps)") },
            { value: "americanFootball_teamTotalsSecondQuarter", label: i18n.t("Nombre de points d'une équipe (2ème quart-temps)") },
            { value: "americanFootball_moneylineThirdQuarter", label: i18n.t("Moneyline (3ème quart-temps)") },
            { value: "americanFootball_handicapThirdQuarter", label: i18n.t("Écart de points (3ème quart-temps)") },
            { value: "americanFootball_totalsThirdQuarter", label: i18n.t("Nombre de points (3ème quart-temps)") },
            { value: "americanFootball_teamTotalsThirdQuarter", label: i18n.t("Nombre de points d'une équipe (3ème quart-temps)") },
            { value: "americanFootball_moneylineFourthQuarter", label: i18n.t("Moneyline (4ème quart-temps)") },
            { value: "americanFootball_handicapFourthQuarter", label: i18n.t("Écart de points (4ème quart-temps)") },
            { value: "americanFootball_totalsFourthQuarter", label: i18n.t("Nombre de points (4ème quart-temps)") },
            { value: "americanFootball_teamTotalsFourthQuarter", label: i18n.t("Nombre de points d'une équipe (4ème quart-temps)") },
            { value: "oddEvenTotals", label: i18n.t("Nombre de points pair/impair (prolongations incluses)") },
            { value: "oddEvenTeamTotals", label: i18n.t("Nombre de points pair/impair pour l'équipe (prolongations incluses)") },
            { value: "oddEvenTeamTotalsFirstHalf", label: i18n.t("Nombre de points pair/impair pour l'équipe (1ère mi-temps)") },
            { value: "oddEvenTotalsFirstHalf", label: i18n.t("Nombre de points pair/impair (1ère mi-temps)") },
            { value: "firstTeamToScore", label: i18n.t("Première équipe à scorer (prolongations incluses)") },
            { value: "totalsSacks", label: i18n.t("Nombre de Sacks (prolongations incluses)") },
            { value: "totalsFieldGoalsMade", label: i18n.t("Nombre de Field Goals (prolongations incluses)") }
        ]
    },
    {
        label: "Rugby",
        options: [
            { value: "rugby_moneyline", label: i18n.t("Moneyline (match)") },
            { value: "rugby_handicap", label: i18n.t("Écart de points (match)") },
            { value: "rugby_totals", label: i18n.t("Nombre de points (match)") },
            { value: "rugby_teamTotals", label: i18n.t("Nombre de points par une équipe (match)") },
            { value: "rugby_moneylineFirstHalf", label: i18n.t("Moneyline (1ère mi-temps)") },
            { value: "rugby_handicapFirstHalf", label: i18n.t("Écart de points (1ère mi-temps)") },
            { value: "rugby_totalsFirstHalf", label: i18n.t("Nombre de points (1ère mi-temps)") },
            { value: "rugby_teamTotalsFirstHalf", label: i18n.t("Nombre de points par une équipe (1ère mi-temps)") },
            { value: "rugby_moneylineSecondHalf", label: i18n.t("Moneyline (2ème mi-temps)") },
            { value: "rugby_handicapSecondHalf", label: i18n.t("Écart de points (2ème mi-temps)") },
            { value: "rugby_totalsSecondHalf", label: i18n.t("Nombre de points (2ème mi-temps)") },
            { value: "rugby_teamTotalsSecondHalf", label: i18n.t("Nombre de points par une équipe (2ème mi-temps)") }
        ]
    },
    {
        label: "Handball",
        options: [
            { value: "handball_moneyline", label: i18n.t("Moneyline (match)") },
            { value: "handball_handicap", label: i18n.t("Écart de buts (match)") },
            { value: "handball_totals", label: i18n.t("Nombre de buts (match)") },
            { value: "handball_teamTotals", label: i18n.t("Nombre de buts par une équipe (match)") },
            { value: "handball_moneylineFirstHalf", label: i18n.t("Moneyline (1ère mi-temps)") },
            { value: "handball_handicapFirstHalf", label: i18n.t("Écart de buts (1ère mi-temps)") },
            { value: "handball_totalsFirstHalf", label: i18n.t("Nombre de buts (1ère mi-temps)") },
            { value: "handball_teamTotalsFirstHalf", label: i18n.t("Nombre de buts par une équipe (1ère mi-temps)") },
            { value: "handball_moneylineSecondHalf", label: i18n.t("Moneyline (2ème mi-temps)") },
            { value: "handball_handicapSecondHalf", label: i18n.t("Écart de buts (2ème mi-temps)") },
            { value: "handball_totalsSecondHalf", label: i18n.t("Nombre de buts (2ème mi-temps)") },
            { value: "handball_teamTotalsSecondHalf", label: i18n.t("Nombre de buts par une équipe (2ème mi-temps)") }
        ]
    },
    {
        label: "Volleyball",
        options: [
            { value: "volleyball_moneyline", label: i18n.t("Vainqueur (match)") },
            { value: "volleyball_handicap", label: i18n.t("Écart de sets (match)") },
            { value: "volleyball_totals", label: i18n.t("Nombre de sets (match)") },
            { value: "volleyball_teamTotals", label: i18n.t("Nombre de sets d'une équipe (match)") },
            { value: "volleyball_moneylinePoints", label: i18n.t("Vainqueur en nombre de points (match)") },
            { value: "volleyball_handicapPoints", label: i18n.t("Écart de points (match)") },
            { value: "volleyball_totalsPoints", label: i18n.t("Nombre de points (match)") },
            { value: "volleyball_teamTotalsPoints", label: i18n.t("Nombre de points d'une équipe (match)") },
            { value: "volleyball_moneylinePointsFirstSet", label: i18n.t("Vainqueur (1er set)") },
            { value: "volleyball_handicapPointsFirstSet", label: i18n.t("Écart de points (1er set)") },
            { value: "volleyball_totalsPointsFirstSet", label: i18n.t("Nombre de points (1er set)") },
            { value: "volleyball_teamTotalsPointsFirstSet", label: i18n.t("Nombre de points d'une équipe (1er set)") },
            { value: "volleyball_moneylinePointsSecondSet", label: i18n.t("Vainqueur (2ème set)") },
            { value: "volleyball_handicapPointsSecondSet", label: i18n.t("Écart de points (2ème set)") },
            { value: "volleyball_totalsPointsSecondSet", label: i18n.t("Nombre de points (2ème set)") },
            { value: "volleyball_teamTotalsPointsSecondSet", label: i18n.t("Nombre de points d'une équipe (2ème set)") },
            { value: "volleyball_moneylinePointsThirdSet", label: i18n.t("Vainqueur (3ème set)") },
            { value: "volleyball_handicapPointsThirdSet", label: i18n.t("Écart de points (3ème set)") },
            { value: "volleyball_totalsPointsThirdSet", label: i18n.t("Nombre de points (3ème set)") },
            { value: "volleyball_teamTotalsPointsThirdSet", label: i18n.t("Nombre de points d'une équipe (3ème set)") },
            { value: "volleyball_moneylinePointsFourthSet", label: i18n.t("Vainqueur (4ème set)") },
            { value: "volleyball_handicapPointsFourthSet", label: i18n.t("Écart de points (4ème set)") },
            { value: "volleyball_totalsPointsFourthSet", label: i18n.t("Nombre de points (4ème set)") },
            { value: "volleyball_teamTotalsPointsFourthSet", label: i18n.t("Nombre de points d'une équipe (4ème set)") },
            { value: "volleyball_moneylinePointsFifthSet", label: i18n.t("Vainqueur (5ème set)") },
            { value: "volleyball_handicapPointsFifthSet", label: i18n.t("Écart de points (5ème set)") },
            { value: "volleyball_totalsPointsFifthSet", label: i18n.t("Nombre de points (5ème set)") },
            { value: "volleyball_teamTotalsPointsFifthSet", label: i18n.t("Nombre de points d'une équipe (5ème set)") }
        ]
    },
];

// BetRebels and Yonibet has same surrender management as french books
const allBooksPayingSurrenderedMatch = [];
Object.keys(allWorldBooksByGroup).filter(group => !["yonibet", "betrebels", "mystake"].includes(group)).map(group => allBooksPayingSurrenderedMatch.push(...allWorldBooksByGroup[group]));

const allBooksPayingOnceFirstSetDone = ["bwin", ...allBooksPayingSurrenderedMatch];

const getTotalsMarketResult = (bet, odds, overUnder, matchResult, isOverBet, betDetails) => {
    let benefit = 0;
    let isRefunded = false;
    
    if (overUnder % 1 === 0.25) {
        // Asian handicap .25 bet
        let baseOverUnder = Math.floor(overUnder);
        if ((isOverBet && matchResult > baseOverUnder) || (!isOverBet && matchResult < baseOverUnder)) {
            benefit = bet * (odds - 1); // Full win
        } else if (matchResult === baseOverUnder) {
            benefit = isOverBet ? -bet / 2 : bet / 2 * (odds - 1); // Half lose-win / Half refunded
        } else {
            benefit = -bet;
        }
    } else if (overUnder % 1 === 0.75) {
        // Asian handicap .75 bet
        let baseOverUnder = Math.floor(overUnder);
        if ((isOverBet && matchResult > baseOverUnder + 1) || (!isOverBet && matchResult < baseOverUnder + 1)) {
            benefit = bet * (odds - 1); // Full win
        } else if (matchResult === baseOverUnder + 1) {
            benefit = isOverBet ? bet / 2 * (odds - 1) : -bet / 2; // Half win / Half refunded
        } else {
            benefit = -bet;
        }
    } else {
        // Classic over/under bet (.5, .0)
        if ((isOverBet && matchResult > overUnder) || (!isOverBet && matchResult < overUnder)) {
            benefit = bet * (odds - 1); // Full win
        } else if (matchResult === overUnder) {
            isRefunded = true; // The bet is refunded
        } else {
            benefit = -bet;
        }
    }

    //if (betDetails.sport === "tennis") console.log(betDetails.id, "Mise : " + bet, " / Cote : " + odds, " / Pari : " + betDetails.side, " / Résultat : " + matchResult+" => Bénéfices : " + benefit + "€", betDetails)

    return {
        "benefit": benefit,
        "hasWon": benefit > 0,
        "isRefunded": isRefunded
    };
}

const getHandicapMarketResult = (bet, odds, handicap, homePoints, awayPoints, isHomeHandicap, betDetails) => {
    let benefit = 0;
    let isRefunded = false;

    let pointsDiff = isHomeHandicap ? homePoints - awayPoints : awayPoints - homePoints;
    let pointsDiffWithHdp = pointsDiff + handicap;

    if (Math.abs(handicap) % 1 === 0.25 || Math.abs(handicap) % 1 === 0.75) {
        // Asian handicap .25 bet
        if (pointsDiffWithHdp > 0.25) {
            benefit = bet * (odds - 1);
        } else if (pointsDiffWithHdp === 0.25) {
            benefit = bet / 2 * (odds - 1); // Half win / Half refunded
        } else if (pointsDiffWithHdp === -0.25) {
            benefit = -bet / 2; // Half lose / Half refunded
        } else {
            benefit = -bet;
        }
    } else {
        // Classic handicap bet (.0)
        if (pointsDiffWithHdp > 0) {
            benefit = bet * (odds - 1);
        } else if (pointsDiffWithHdp === 0) {
            isRefunded = true; // The bet is refunded
        } else {
            benefit = -bet;
        }
    }

    //if (betDetails.sport === "tennis") console.log("Mise : " + bet, " / Cote : " + odds, " / Pari : " + betDetails.side, " / Résultat : " + homePoints+"|"+awayPoints+" ("+pointsDiff+" => "+pointsDiffWithHdp+") => Bénéfices : " + benefit + "€", betDetails)

    return {
        "benefit": benefit,
        "hasWon": benefit > 0,
        "isRefunded": isRefunded
    };
}

const isBetWonDirect = (bet, isFirstSetFinished) => {
    let hasWon = false;

    const odd = parseFloat(bet.odd.split(";")[0]);
    const stake = parseFloat(bet.bet);
    const sanitizedSide = bet.side.replaceAll("[Home]", "home").replaceAll("[Draw]", "draw").replaceAll("[Away]", "away").toLowerCase();

    if (allTotalsMarkets.includes(bet.marketName)) {
        let [cutSide, requiredPoints] = sanitizedSide.split(" ");
        if (bet.resultByMarket && bet.resultByMarket.hasOwnProperty(bet.marketName)) {
            requiredPoints = parseFloat(requiredPoints);
            const donePoints = bet.resultByMarket[bet.marketName];

            const totalsResult = getTotalsMarketResult(stake, odd, requiredPoints, donePoints, cutSide === "over", bet);
            hasWon = totalsResult.hasWon;
        }
    } else if (allTeamTotalsMarkets.includes(bet.marketName)) {
        let [team, cutSide, requiredPoints] = sanitizedSide.split(" ");
        
        if (bet.resultByMarket && bet.resultByMarket.hasOwnProperty(bet.marketName) && bet.resultByMarket[bet.marketName].hasOwnProperty(team)) {
            requiredPoints = parseFloat(requiredPoints);
            const donePoints = bet.resultByMarket[bet.marketName][team];

            const totalsResult = getTotalsMarketResult(stake, odd, requiredPoints, donePoints, cutSide === "over", bet);
            hasWon = totalsResult.hasWon;
        }
    } else if (bet.marketName.includes("handicap")) {
		if (isFirstSetFinished) {
			const [team, handicap] = sanitizedSide.split(" ");
			const correspondingTeamTotalsMarket = bet.marketName.replace("handicap", "teamTotals");
			if (bet.resultByMarket && bet.resultByMarket.hasOwnProperty(correspondingTeamTotalsMarket)) {
				const homePoints = bet.resultByMarket[correspondingTeamTotalsMarket]["home"];
				const awayPoints = bet.resultByMarket[correspondingTeamTotalsMarket]["away"];

				const totalsResult = getHandicapMarketResult(stake, odd, parseFloat(handicap), homePoints, awayPoints, team === "home", bet);
				hasWon = totalsResult.hasWon;
			}
		} else {
			hasWon = false;
		}
    } else if (bet.marketName.includes("doubleChance")) {
        const [first, second] = sanitizedSide.split(" or ");
        const correspondingMoneylineMarket = bet.marketName.replace("doubleChance", "moneyline");

        if (bet.resultByMarket && bet.resultByMarket.hasOwnProperty(correspondingMoneylineMarket)) {
            hasWon = first === bet.resultByMarket[correspondingMoneylineMarket] || second === bet.resultByMarket[correspondingMoneylineMarket];
        }
    } else if (bet.marketName.includes("drawNoBet")) {
        const moneylineResult = bet.resultByMarket[bet.marketName.replace("drawNoBet", "moneyline")];
        if (moneylineResult !== "draw") {
            hasWon = sanitizedSide === moneylineResult; 
        }
    } else if (bet.marketName === "oddEvenTotalsAndTotals") {
        const [oddEven, cutPart] = sanitizedSide.split(" & ");
        const [cutSide, requiredPoints] = cutPart.split(" ");

        if (bet.resultByMarket && bet.resultByMarket.hasOwnProperty("totals") && bet.resultByMarket.hasOwnProperty("oddEvenTotals")) {
            const donePoints = bet.resultByMarket.totals;
            const isTotalsOk = cutSide === "over" ? donePoints > requiredPoints : donePoints < requiredPoints;
            const isOddEvenOk = oddEven === bet.resultByMarket.oddEvenTotals;

            hasWon = isOddEvenOk && isTotalsOk;
        }
    } else if (bet.marketName === "winnerAndTotals") {
        const [winner, cutPart] = sanitizedSide.split(" & ");
        const [cutSide, requiredPoints] = cutPart.split(" ");

        if (bet.resultByMarket && bet.resultByMarket.hasOwnProperty("totals") && bet.resultByMarket.hasOwnProperty("moneyline")) {
            const donePoints = bet.resultByMarket.totals;
            const isWinnerOk = winner === bet.resultByMarket.moneyline;
            const isTotalsOk = cutSide === "over" ? donePoints > requiredPoints : donePoints < requiredPoints;

            hasWon = isWinnerOk && isTotalsOk;
        }
    } else if (bet.marketName === "halfTimeAndFullTime") {
        const [half, full] = sanitizedSide.split(" - ");

        if (bet.resultByMarket && bet.resultByMarket.hasOwnProperty("moneyline") && bet.resultByMarket.hasOwnProperty("moneylineFirstHalf")) {
            const isHalfOk = half === bet.resultByMarket.moneylineFirstHalf;
            const isFullOk = full === bet.resultByMarket.moneyline;

            hasWon = isHalfOk && isFullOk;
        }
    } else if (bet.marketName === "bothTeamToScoreAndTotals") {
        let [bothTeamToScore, cutPart] = sanitizedSide.split(" & ");
        const [cutSide, requiredPoints] = cutPart.split(" ");

        if (bet.resultByMarket && bet.resultByMarket.hasOwnProperty("totals") && bet.resultByMarket.hasOwnProperty("bothTeamToScore")) {
            const donePoints = bet.resultByMarket.totals;
            const isBothTeamToScoreOk = bothTeamToScore === bet.resultByMarket.bothTeamToScore;
            const isTotalsOk = cutSide === "over" ? donePoints > requiredPoints : donePoints < requiredPoints;

            hasWon = isBothTeamToScoreOk && isTotalsOk;
        }
    } else if (bet.marketName === "bothTeamToScoreAndWinner") {
        let [bothTeamToScore, winner] = sanitizedSide.split(" & ");

        if (bet.resultByMarket && bet.resultByMarket.hasOwnProperty("moneyline") && bet.resultByMarket.hasOwnProperty("bothTeamToScore")) {
            const isBothTeamToScoreOk = bothTeamToScore === bet.resultByMarket.bothTeamToScore;
            const isWinnerOk = winner === bet.resultByMarket.moneyline;

            hasWon = isBothTeamToScoreOk && isWinnerOk;
        }
    } else {
        if (bet.resultByMarket.hasOwnProperty(bet.marketName)) {
            if (Array.isArray(bet.resultByMarket[bet.marketName])) {
                hasWon = bet.resultByMarket[bet.marketName].includes(sanitizedSide);
            } else {
                hasWon = sanitizedSide === bet.resultByMarket[bet.marketName];
            }
        }
    }

    return hasWon;
}

const isBetWon = (bet) => {
    let hasWon = false;
    let isRefunded = false;
    let isNotFound = false;
    let benefit;

    const odd = parseFloat(bet.odd.split(";")[0]);
    const stake = parseFloat(bet.bet);

    if (bet.resultByMarket) {
        // New version
        if (bet.resultByMarket.hasOwnProperty("moneyline")) {
            const sanitizedSide = bet.side.replaceAll("[Home]", "home").replaceAll("[Draw]", "draw").replaceAll("[Away]", "away").toLowerCase();

            if (bet.results === "surrender") {
				if (bet.sport === "tennis") {
                    const isFirstSetFinished = bet.resultByMarket['teamTotalsGamesFirstSet'] && (bet.resultByMarket['teamTotalsGamesFirstSet'].home >= 6 || bet.resultByMarket['teamTotalsGamesFirstSet'].away >= 6);
                    const isTotalsMarket = allTotalsMarkets.includes(bet.marketName) || allTeamTotalsMarkets.includes(bet.marketName);
                    const isTotalsUnder = isTotalsMarket && bet.side.includes("under");

					// Market is on a given set
					const sets = ["FirstSet", "SecondSet", "ThirdSet", "FourthSet", "FifthSet"];
					const foundSet = sets.find(set => bet.marketName.includes(set));
					if (foundSet) {
						const isSetFinished = bet.resultByMarket[`teamTotalsGames${foundSet}`] && (bet.resultByMarket[`teamTotalsGames${foundSet}`].home >= 6 || bet.resultByMarket[`teamTotalsGames${foundSet}`].away >= 6);
						isRefunded = !isSetFinished;
					} 
					
					// First set has been played AND is moneyline
					else if (allBooksPayingOnceFirstSetDone.includes(bet.bookName.split(";")[0]) && isFirstSetFinished && bet.marketName === "moneyline") {
                        isRefunded = false;
					}

                    // If the bet is won and not handicapGames then it's payed
                    else if (!bet.marketName.startsWith("handicapGames") && !isTotalsUnder && !bet.marketName.startsWith("moneyline") && isBetWonDirect(bet, isFirstSetFinished)) {
                        isRefunded = false;
                    }
					
					else {
						isRefunded = true;
					}
				} else {
                	isRefunded = true;
				}
            }
            if (bet.marketName.includes("moneyline") && ["basketball", "americanFootball", "rugby", "handball"].includes(bet.sport) && bet.resultByMarket[bet.marketName] === "draw") {
                isRefunded = true;
            }
            
			if (!isRefunded) {
				if (allTotalsMarkets.includes(bet.marketName)) {
					let [cutSide, requiredPoints] = sanitizedSide.split(" ");
					if (bet.resultByMarket && bet.resultByMarket.hasOwnProperty(bet.marketName)) {
						requiredPoints = parseFloat(requiredPoints);
						const donePoints = bet.resultByMarket[bet.marketName];

						const totalsResult = getTotalsMarketResult(stake, odd, requiredPoints, donePoints, cutSide === "over", bet);
						hasWon = totalsResult.hasWon;
						isRefunded = totalsResult.isRefunded;
						benefit = totalsResult.benefit;
					} else {
						isNotFound = true;
					}
				} else if (allTeamTotalsMarkets.includes(bet.marketName)) {
					let [team, cutSide, requiredPoints] = sanitizedSide.split(" ");
					
					if (bet.resultByMarket && bet.resultByMarket.hasOwnProperty(bet.marketName) && bet.resultByMarket[bet.marketName].hasOwnProperty(team)) {
						requiredPoints = parseFloat(requiredPoints);
						const donePoints = bet.resultByMarket[bet.marketName][team];

						const totalsResult = getTotalsMarketResult(stake, odd, requiredPoints, donePoints, cutSide === "over", bet);
						hasWon = totalsResult.hasWon;
						isRefunded = totalsResult.isRefunded;
						benefit = totalsResult.benefit;
					} else {
						isNotFound = true;
					}
				} else if (bet.marketName.includes("handicap")) {
					const [team, handicap] = sanitizedSide.split(" ");
					const correspondingTeamTotalsMarket = bet.marketName.replace("handicap", "teamTotals");
					if (bet.resultByMarket && bet.resultByMarket.hasOwnProperty(correspondingTeamTotalsMarket)) {
						const homePoints = bet.resultByMarket[correspondingTeamTotalsMarket]["home"];
						const awayPoints = bet.resultByMarket[correspondingTeamTotalsMarket]["away"];

						const totalsResult = getHandicapMarketResult(stake, odd, parseFloat(handicap), homePoints, awayPoints, team === "home", bet);
						hasWon = totalsResult.hasWon;
						isRefunded = totalsResult.isRefunded;
						benefit = totalsResult.benefit;
					} else {
						isNotFound = true;
					}
				} else if (bet.marketName.includes("doubleChance")) {
					const [first, second] = sanitizedSide.split(" or ");
					const correspondingMoneylineMarket = bet.marketName.replace("doubleChance", "moneyline");

					if (bet.resultByMarket && bet.resultByMarket.hasOwnProperty(correspondingMoneylineMarket)) {
						hasWon = first === bet.resultByMarket[correspondingMoneylineMarket] || second === bet.resultByMarket[correspondingMoneylineMarket];
					} else {
						isNotFound = true;
					}
				} else if (bet.marketName.includes("drawNoBet")) {
					const moneylineResult = bet.resultByMarket[bet.marketName.replace("drawNoBet", "moneyline")];
					if (moneylineResult === "draw") {
						isRefunded = true;
					} else {
						hasWon = sanitizedSide === moneylineResult; 
					}
				} else if (bet.marketName === "oddEvenTotalsAndTotals") {
					const [oddEven, cutPart] = sanitizedSide.split(" & ");
					const [cutSide, requiredPoints] = cutPart.split(" ");

					if (bet.resultByMarket && bet.resultByMarket.hasOwnProperty("totals") && bet.resultByMarket.hasOwnProperty("oddEvenTotals")) {
						const donePoints = bet.resultByMarket.totals;
						const isTotalsOk = cutSide === "over" ? donePoints > requiredPoints : donePoints < requiredPoints;
						const isOddEvenOk = oddEven === bet.resultByMarket.oddEvenTotals;

						hasWon = isOddEvenOk && isTotalsOk;
					} else {
						isNotFound = true;
					}
				} else if (bet.marketName === "winnerAndTotals") {
					const [winner, cutPart] = sanitizedSide.split(" & ");
					const [cutSide, requiredPoints] = cutPart.split(" ");

					if (bet.resultByMarket && bet.resultByMarket.hasOwnProperty("totals") && bet.resultByMarket.hasOwnProperty("moneyline")) {
						const donePoints = bet.resultByMarket.totals;
						const isWinnerOk = winner === bet.resultByMarket.moneyline;
						const isTotalsOk = cutSide === "over" ? donePoints > requiredPoints : donePoints < requiredPoints;

						hasWon = isWinnerOk && isTotalsOk;
					} else {
						isNotFound = true;
					}
				} else if (bet.marketName === "halfTimeAndFullTime") {
					const [half, full] = sanitizedSide.split(" - ");

					if (bet.resultByMarket && bet.resultByMarket.hasOwnProperty("moneyline") && bet.resultByMarket.hasOwnProperty("moneylineFirstHalf")) {
						const isHalfOk = half === bet.resultByMarket.moneylineFirstHalf;
						const isFullOk = full === bet.resultByMarket.moneyline;

						hasWon = isHalfOk && isFullOk;
					} else {
						isNotFound = true;
					}
				} else if (bet.marketName === "bothTeamToScoreAndTotals") {
					let [bothTeamToScore, cutPart] = sanitizedSide.split(" & ");
					const [cutSide, requiredPoints] = cutPart.split(" ");

					if (bet.resultByMarket && bet.resultByMarket.hasOwnProperty("totals") && bet.resultByMarket.hasOwnProperty("bothTeamToScore")) {
						const donePoints = bet.resultByMarket.totals;
						const isBothTeamToScoreOk = bothTeamToScore === bet.resultByMarket.bothTeamToScore;
						const isTotalsOk = cutSide === "over" ? donePoints > requiredPoints : donePoints < requiredPoints;

						hasWon = isBothTeamToScoreOk && isTotalsOk;
					} else {
						isNotFound = true;
					}
				} else if (bet.marketName === "bothTeamToScoreAndWinner") {
					let [bothTeamToScore, winner] = sanitizedSide.split(" & ");

					if (bet.resultByMarket && bet.resultByMarket.hasOwnProperty("moneyline") && bet.resultByMarket.hasOwnProperty("bothTeamToScore")) {
						const isBothTeamToScoreOk = bothTeamToScore === bet.resultByMarket.bothTeamToScore;
						const isWinnerOk = winner === bet.resultByMarket.moneyline;

						hasWon = isBothTeamToScoreOk && isWinnerOk;
					} else {
						isNotFound = true;
					}
				} else {
					if (bet.resultByMarket.hasOwnProperty(bet.marketName)) {
						if (Array.isArray(bet.resultByMarket[bet.marketName])) {
							hasWon = bet.resultByMarket[bet.marketName].includes(sanitizedSide);
						} else {
							hasWon = sanitizedSide === bet.resultByMarket[bet.marketName];
						}
					} else {
						isNotFound = true;
					}
				}
			}
        } 
        
        // Old version
        else {
            if (bet.results === "surrender") {
                isRefunded = true;
            }
            if (bet.marketName.includes("moneyline") && ["rugby"].includes(bet.sport) && bet.winner === "draw") {
                isRefunded = true;
            }

            if (bet.marketName === "moneyline") {
                hasWon = bet.side === bet.winner;
            } else {
                const resultByMarket = bet.resultByMarket;
                const [side, requiredPoints] = bet.side.split(" ");
                const donePoints = resultByMarket[bet.marketName] !== null ? resultByMarket[bet.marketName] : null;

                if (donePoints !== null)
                    hasWon = side === "over" ? donePoints > requiredPoints : donePoints < requiredPoints;
            }
        }
    } else if (bet.results) {
        if (bet.results === "surrender") {
            isRefunded = true;
        }
        if (bet.marketName.includes("moneyline") && ["rugby"].includes(bet.sport) && bet.winner === "draw") {
            isRefunded = true;
        }
        
        if (bet.marketName === "moneyline") {
            hasWon = bet.side === bet.winner;
        }
    } else {
        isNotFound = true;
    }

    if (isRefunded) {
        benefit = 0;
    } else if (typeof benefit === 'undefined') {
        if (hasWon) {
            benefit = stake * odd - stake;
        } else {
            benefit = -stake;
        }
    }

    return { hasWon, isRefunded, isNotFound, benefit };
}

const getPrintablePeriod = (sport, marketName) => {
    if (marketName.includes("FirstHalf")) {
        return i18n.t("1ère mi-temps");
    } else if (marketName.includes("SecondHalf")) {
        return i18n.t("2ème mi-temps");
    } else if (marketName.includes("FirstQuarter")) {
        return i18n.t("1er quart-temps");
    } else if (marketName.includes("SecondQuarter")) {
        return i18n.t("2ème quart-temps");
    } else if (marketName.includes("ThirdQuarter")) {
        return i18n.t("3ème quart-temps");
    } else if (marketName.includes("FourthQuarter")) {
        return i18n.t("4ème quart-temps");
    } else if (marketName.includes("FirstSet")) {
        return i18n.t("1er set");
    } else if (marketName.includes("SecondSet")) {
        return i18n.t("2ème set");
    } else if (marketName.includes("ThirdSet")) {
        return i18n.t("3ème set");
    } else if (marketName.includes("FourthSet")) {
        return i18n.t("4ème set");
    } else if (marketName.includes("FifthSet")) {
        return i18n.t("5ème set");
    } else if (marketName.includes("FirstPeriod")) {
        return i18n.t("1ère période");
    } else if (marketName.includes("SecondPeriod")) {
        return i18n.t("2ème période");
    } else if (marketName.includes("ThirdPeriod")) {
        return i18n.t("3ème période");
    } else if (marketName.includes("FirstInning")) {
        return i18n.t("1ère manche");
    } else if (marketName.includes("SecondInning")) {
        return i18n.t("2ème manche");
    } else if (marketName.includes("ThirdInning")) {
        return i18n.t("3ème manche");
    } else if (marketName.includes("FourthInning")) {
        return i18n.t("4ème manche");
    } else if (marketName.includes("FifthInning")) {
        return i18n.t("5ème manche");
    } else if (marketName.includes("SixthInning")) {
        return i18n.t("6ème manche");
    } else if (marketName.includes("SeventhInning")) {
        return i18n.t("7ème manche");
    } else if (marketName.includes("EighthInning")) {
        return i18n.t("8ème manche");
    } else if (marketName.includes("NinthInning")) {
        return i18n.t("9ème manche");
    } else if (marketName.includes("RegularTime")) {
        return i18n.t("temps réglementaire");
    } else {
        switch (sport) {
            case "football":
                return i18n.t("temps réglementaire");
            case "basketball": 
            case "americanFootball":
                return i18n.t("prolongations incluses");
            case "hockey":
                return i18n.t("prolongations + TAB inclus");
            default:
                return i18n.t("match");
        }
    }
}

const getPrintableUnit = (sport, marketName) => {
    let unit;
    if (marketName === "totalsSacks") {
        unit = i18n.t("Sacks");
    } else if (marketName === "totalsFieldGoalsMade") {
        unit = i18n.t("Field Goals");
    } else if (sport === "tennis") {
        unit = pointsUnitsBySport[sport][marketName.includes("Games") ? "games" : "sets"];
    } else if (sport === "volleyball") {
        unit = pointsUnitsBySport[sport][marketName.includes("Points") ? "points" : "sets"];
    } else {
        unit = pointsUnitsBySport[sport];
    }

    return unit;
}

const getPrintableBet = (sport, marketName, side, homeTeamName, awayTeamName) => {
    const periodSuffix = getPrintablePeriod(sport, marketName);
    const sanitizedSide = side.replaceAll("[Home]", "home").replaceAll("[Draw]", "draw").replaceAll("[Away]", "away").toLowerCase();

    if (marketName.includes("moneyline")) {
        let prefix;
        if (sanitizedSide === "home" || sanitizedSide === "away") {
            prefix = i18n.t(`X gagne`, { "teamToWin": sanitizedSide === "home" ? homeTeamName : awayTeamName });
        } else {
            prefix = i18n.t("Match nul");
        }
        return `${prefix} (${periodSuffix})`;
    } else if (allTotalsMarkets.includes(marketName)) {
        const [cutSide, points] = sanitizedSide.split(" ");
        
        const cutSidePrintable = cutSide === "over" ? i18n.t("Plus de") : i18n.t("Moins de");
        const pointsPrintable = parseFloat(points).toString().replace(".", ",");
        const unit = getPrintableUnit(sport, marketName);

        return `${cutSidePrintable} ${pointsPrintable} ${unit} (${periodSuffix})`;
    } else if (allTeamTotalsMarkets.includes(marketName)) {
        const [team, cutSide, points] = sanitizedSide.split(" ");
        
        const teamPrintable = team === "home" ? homeTeamName : awayTeamName;
        const cutSidePrintable = cutSide === "over" ? i18n.t("Plus de") : i18n.t("Moins de");
        const pointsPrintable = parseFloat(points).toString().replace(".", ",");
        const unit = getPrintableUnit(sport, marketName);

        return `${cutSidePrintable} ${pointsPrintable} ${unit} ${i18n.t("pour")} ${teamPrintable} (${periodSuffix})`;
    } else if (marketName.includes("handicap")) {
        const [team, handicap] = sanitizedSide.split(" ");
        
        const teamPrintable = team === "home" ? homeTeamName : awayTeamName;
        let handicapPrintable = parseFloat(handicap);
        handicapPrintable = handicapPrintable > 0 ? "+" + handicapPrintable : handicapPrintable;
        handicapPrintable = handicapPrintable.toString().replace(".", ",");
        const unit = getPrintableUnit(sport, marketName);

        return `${teamPrintable} H${handicapPrintable} ${unit} (${periodSuffix})`;
    } else if (marketName.startsWith("doubleChance")) {
        return `${side.replace("Or", i18n.t("ou")).replaceAll("[Home]", homeTeamName).replaceAll("[Away]", awayTeamName).replaceAll("[Draw]", "Nul")} (${periodSuffix})`;
    } else if (marketName.startsWith("drawNoBet")) {
        return `${side.replaceAll("[Home]", homeTeamName).replaceAll("[Away]", awayTeamName).replaceAll("[Draw]", "Nul")} - ${i18n.t("Remboursé si nul")} (${periodSuffix})`;
    } else if (marketName.startsWith("teamToWinToNil")) {
        const [team, toWinToNil] = side.split(" ");
        const teamPrintable = team.replaceAll("[Home]", homeTeamName).replaceAll("[Away]", awayTeamName);
        const unit = getPrintableUnit(sport, marketName);
        const toWinToNilPrintable = i18n.t(toWinToNil === "Yes" ? "X gagne sans encaisser de" : "X gagne en encaissant au moins un", { "teamToWin": teamPrintable, unit });

        return `${toWinToNilPrintable} (${periodSuffix})`;
    } else if (marketName.startsWith("teamToScore")) {
        const [team, toScore] = side.split(" ");
        const teamPrintable = team.replaceAll("[Home]", homeTeamName).replaceAll("[Away]", awayTeamName);
        const toScorePrintable = toScore === "Yes" ? i18n.t("marque au moins un") : i18n.t("ne marque aucun");
        const unit = getPrintableUnit(sport, marketName);

        return `${teamPrintable} ${toScorePrintable} ${unit} (${periodSuffix})`;
    } else if (marketName.startsWith("eitherTeamToScore")) {
        const eitherTeamToScorePrintable = side === "Yes" ? i18n.t("Aucune des deux équipes ne marque") : i18n.t("Au moins une des deux équipes marque");

        return `${eitherTeamToScorePrintable} (${periodSuffix})`;
    } else if (["oddEvenTotals", "oddEvenTotalsFirstHalf"].includes(marketName)) {
        const oddEvenTotalsPrintable = side === "Odd" ? i18n.t("pair") : i18n.t("impair");
        const unit = getPrintableUnit(sport, marketName);

        return `${i18n.t("Nombre de X pair/impair", { unit, "oddOrEven": oddEvenTotalsPrintable })} (${periodSuffix})`;
    } else if (["bothTeamToScore", "bothTeamToScoreFirstHalf"].includes(marketName)) {
        const bothTeamToScorePrintable = side === "Yes" ? i18n.t("Les deux équipes marquent") : i18n.t("Une seule équipe (ou aucune) ne marque");

        return `${bothTeamToScorePrintable} (${periodSuffix})`;
    } else if (marketName === "oddEvenTotalsAndTotals") {
        const [oddEven, cutPart] = sanitizedSide.split(" & ");
        const [cutSide, points] = cutPart.split(" ");

        const oddEvenTotalsPrintable = oddEven === "odd" ? i18n.t("pair") : i18n.t("impair");
        const cutSidePrintable = cutSide === "over" ? i18n.t("Plus de") : i18n.t("Moins de");
        const pointsPrintable = parseFloat(points).toString().replace(".", ",");
        const unit = getPrintableUnit(sport, marketName);

        return `${i18n.t("Nombre de X pair/impair", { unit, "oddOrEven": oddEvenTotalsPrintable })} & ${cutSidePrintable} ${pointsPrintable} ${unit} (${periodSuffix})`;
    } else if (marketName === "winnerAndTotals") {
        const [winner, cutPart] = side.split(" & ");
        const [cutSide, points] = cutPart.split(" ");

        let winnerPrintable;
        if (winner === "[Home]" || winner === "[Away]") {
            winnerPrintable = i18n.t("X gagne", { "teamToWin": winner.replaceAll("[Home]", homeTeamName).replaceAll("[Away]", awayTeamName) });
        } else {
            winnerPrintable = winner.replaceAll("[Draw]", i18n.t("Match nul"));
        }
        const cutSidePrintable = cutSide === "Over" ? i18n.t("Plus de") : i18n.t("Moins de");
        const pointsPrintable = parseFloat(points).toString().replace(".", ",");
        const unit = getPrintableUnit(sport, marketName);

        return `${winnerPrintable} & ${cutSidePrintable} ${pointsPrintable} ${unit} (${periodSuffix})`;
    } else if (marketName === "halfTimeAndFullTime") {
        const [half, full] = side.replaceAll("[Home]", homeTeamName).replaceAll("[Away]", awayTeamName).replaceAll("[Draw]", "Nul").split(" - ");

        return `${half} (${i18n.t("1ère mi-temps")}) & ${full} (${periodSuffix})`;
    } else if (marketName === "bothTeamToScoreAndTotals") {
        let [bothTeamToScore, cutPart] = sanitizedSide.split(" & ");
        const [cutSide, points] = cutPart.split(" ");

        const cutSidePrintable = cutSide === "over" ? i18n.t("Plus de") : i18n.t("Moins de");
        const pointsPrintable = parseFloat(points).toString().replace(".", ",");
        const unit = getPrintableUnit(sport, marketName);
        const bothTeamToScorePrintable = bothTeamToScore === "Yes" ? i18n.t("Les deux équipes marquent") : i18n.t("Une seule équipe (ou aucune) ne marque");

        return `${bothTeamToScorePrintable} & ${cutSidePrintable} ${pointsPrintable} ${unit} (${periodSuffix})`;
        
    } else if (marketName === "bothTeamToScoreAndWinner") {
        let [bothTeamToScore, winner] = sanitizedSide.split(" & ");

        let winnerPrintable;
        if (winner === "[Home]" || winner === "[Away]") {
            winnerPrintable = i18n.t("X gagne", { "teamToWin": winner.replaceAll("[Home]", homeTeamName).replaceAll("[Away]", awayTeamName) });
        } else {
            winnerPrintable = winner.replaceAll("[Draw]", i18n.t("Match nul"));
        }
        const bothTeamToScorePrintable = bothTeamToScore === "Yes" ? i18n.t("Les deux équipes marquent") : i18n.t("Une seule équipe (ou aucune) ne marque");

        return `${bothTeamToScorePrintable} & ${winnerPrintable} (${periodSuffix})`;
    }  else if (marketName.startsWith("firstTeamToScore")) {
        return `${sanitizedSide.replaceAll("home", homeTeamName).replaceAll("away", awayTeamName)} ${i18n.t("marque le premier but")} (${periodSuffix})`;
    }
}

const formatBigNumber = (x) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

const getPrintableDate = (date) => {
    date = new Date(date);

    let day = date.getDate() > 9 ? date.getDate() : "0" + date.getDate();
    let month = date.getMonth() + 1
    month = month > 9 ? month : "0" + month;
    let year = date.getFullYear();

    return i18n.t("d/m/y", { day, month, year });
}

const getPrintableDateTime = (dateIso) => {
    let date = new Date(dateIso);

    let minutes = date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes();
    let hours = date.getHours() > 9 ? date.getHours() : "0" + date.getHours();
    let day = date.getDate() > 9 ? date.getDate() : "0" + date.getDate();
    let month = date.getMonth() + 1
    month = month > 9 ? month : "0" + month;
    let year = date.getFullYear();

    return i18n.t("d/m/y h:m", { day, month, year, hours, minutes });
}

const getShortPrintableDateTime = (dateIso) => {
    let date = new Date(dateIso);

    let minutes = date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes();
    let hours = date.getHours() > 9 ? date.getHours() : "0" + date.getHours();
    let day = date.getDate() > 9 ? date.getDate() : "0" + date.getDate();
    let month = date.getMonth() + 1
    month = month > 9 ? month : "0" + month;
    let year = date.getFullYear();

    return i18n.t("d/m h:m", { day, month, hours, minutes });
}

const getDateForInput = (dateIso) => {
    let date = new Date(dateIso);

    let day = date.getDate() > 9 ? date.getDate() : "0" + date.getDate();
    let month = date.getMonth() + 1
    month = month > 9 ? month : "0" + month;
    let year = date.getFullYear();

    return `${year}-${month}-${day}`;
}

const getPrintableDuration = (durationInSec) => {
    if (durationInSec < 60) {
        return i18n.t("Xs", { "seconds": durationInSec });
    }
    else if (durationInSec < 3600) {
        let minutes = Math.floor(durationInSec / 60);
        let seconds = durationInSec - minutes * 60;

        return i18n.t("XmYs", { minutes, seconds });
    }
    else if (durationInSec < 86400) {
        let hours = Math.floor(durationInSec / 3600);
        let minutes = Math.floor((durationInSec - hours * 3600) / 60);

        return i18n.t("XhYm", { hours, minutes });
    }
    else {
        let days = Math.floor(durationInSec / 86400);
        let hours = Math.floor((durationInSec - days * 86400) / 3600);

        return i18n.t("XjYh", { "day": days, hours });
    }
}

const getUserCountry = async () => {
	const IPInformation = await axios.get(`http://ip-api.com/json/`);
	console.log("IPInformation", IPInformation)
	if (IPInformation.status === 200) {
		return IPInformation.data.country;
	} else {
		const countries = {
			AD: "Andorra",
			AE: "United Arab Emirates",
			AF: "Afghanistan",
			AG: "Antigua and Barbuda",
			AI: "Anguilla",
			AL: "Albania",
			AM: "Armenia",
			AO: "Angola",
			AQ: "Antarctica",
			AR: "Argentina",
			AS: "American Samoa",
			AT: "Austria",
			AU: "Australia",
			AW: "Aruba",
			AX: "Åland Islands",
			AZ: "Azerbaijan",
			BA: "Bosnia and Herzegovina",
			BB: "Barbados",
			BD: "Bangladesh",
			BE: "Belgium",
			BF: "Burkina Faso",
			BG: "Bulgaria",
			BH: "Bahrain",
			BI: "Burundi",
			BJ: "Benin",
			BL: "Saint Barthélemy",
			BM: "Bermuda",
			BN: "Brunei",
			BO: "Bolivia",
			BQ: "Caribbean Netherlands",
			BR: "Brazil",
			BS: "Bahamas",
			BT: "Bhutan",
			BV: "Bouvet Island",
			BW: "Botswana",
			BY: "Belarus",
			BZ: "Belize",
			CA: "Canada",
			CC: "Cocos Islands",
			CD: "Democratic Republic of the Congo",
			CF: "Central African Republic",
			CG: "Republic of the Congo",
			CH: "Switzerland",
			CI: "Ivory Coast",
			CK: "Cook Islands",
			CL: "Chile",
			CM: "Cameroon",
			CN: "China",
			CO: "Colombia",
			CR: "Costa Rica",
			CU: "Cuba",
			CV: "Cabo Verde",
			CW: "Curaçao",
			CX: "Christmas Island",
			CY: "Cyprus",
			CZ: "Czechia",
			DE: "Germany",
			DJ: "Djibouti",
			DK: "Denmark",
			DM: "Dominica",
			DO: "Dominican Republic",
			DZ: "Algeria",
			EC: "Ecuador",
			EE: "Estonia",
			EG: "Egypt",
			EH: "Western Sahara",
			ER: "Eritrea",
			ES: "Spain",
			ET: "Ethiopia",
			FI: "Finland",
			FJ: "Fiji",
			FK: "Falkland Islands",
			FM: "Micronesia",
			FO: "Faroe Islands",
			FR: "France",
			GA: "Gabon",
			GB: "United Kingdom",
			GD: "Grenada",
			GE: "Georgia",
			GF: "French Guiana",
			GG: "Guernsey",
			GH: "Ghana",
			GI: "Gibraltar",
			GL: "Greenland",
			GM: "Gambia",
			GN: "Guinea",
			GP: "Guadeloupe",
			GQ: "Equatorial Guinea",
			GR: "Greece",
			GS: "South Georgia and the South Sandwich Islands",
			GT: "Guatemala",
			GU: "Guam",
			GW: "Guinea-Bissau",
			GY: "Guyana",
			HK: "Hong Kong",
			HM: "Heard Island and McDonald Islands",
			HN: "Honduras",
			HR: "Croatia",
			HT: "Haiti",
			HU: "Hungary",
			ID: "Indonesia",
			IE: "Ireland",
			IL: "Israel",
			IM: "Isle of Man",
			IN: "India",
			IO: "British Indian Ocean Territory",
			IQ: "Iraq",
			IR: "Iran",
			IS: "Iceland",
			IT: "Italy",
			JE: "Jersey",
			JM: "Jamaica",
			JO: "Jordan",
			JP: "Japan",
			KE: "Kenya",
			KG: "Kyrgyzstan",
			KH: "Cambodia",
			KI: "Kiribati",
			KM: "Comoros",
			KN: "Saint Kitts and Nevis",
			KP: "North Korea",
			KR: "South Korea",
			KW: "Kuwait",
			KY: "Cayman Islands",
			KZ: "Kazakhstan",
			LA: "Laos",
			LB: "Lebanon",
			LC: "Saint Lucia",
			LI: "Liechtenstein",
			LK: "Sri Lanka",
			LR: "Liberia",
			LS: "Lesotho",
			LT: "Lithuania",
			LU: "Luxembourg",
			LV: "Latvia",
			LY: "Libya",
			MA: "Morocco",
			MC: "Monaco",
			MD: "Moldova",
			ME: "Montenegro",
			MF: "Saint Martin",
			MG: "Madagascar",
			MH: "Marshall Islands",
			MK: "North Macedonia",
			ML: "Mali",
			MM: "Myanmar",
			MN: "Mongolia",
			MO: "Macao",
			MP: "Northern Mariana Islands",
			MQ: "Martinique",
			MR: "Mauritania",
			MS: "Montserrat",
			MT: "Malta",
			MU: "Mauritius",
			MV: "Maldives",
			MW: "Malawi",
			MX: "Mexico",
			MY: "Malaysia",
			MZ: "Mozambique",
			NA: "Namibia",
			NC: "New Caledonia",
			NE: "Niger",
			NF: "Norfolk Island",
			NG: "Nigeria",
			NI: "Nicaragua",
			NL: "Netherlands",
			NO: "Norway",
			NP: "Nepal",
			NR: "Nauru",
			NU: "Niue",
			NZ: "New Zealand",
			OM: "Oman",
			PA: "Panama",
			PE: "Peru",
			PF: "French Polynesia",
			PG: "Papua New Guinea",
			PH: "Philippines",
			PK: "Pakistan",
			PL: "Poland",
			PM: "Saint Pierre and Miquelon",
			PN: "Pitcairn",
			PR: "Puerto Rico",
			PS: "Palestine",
			PT: "Portugal",
			PW: "Palau",
			PY: "Paraguay",
			QA: "Qatar",
			RE: "Réunion",
			RO: "Romania",
			RS: "Serbia",
			RU: "Russia",
			RW: "Rwanda",
			SA: "Saudi Arabia",
			SB: "Solomon Islands",
			SC: "Seychelles",
			SD: "Sudan",
			SE: "Sweden",
			SG: "Singapore",
			SH: "Saint Helena, Ascension and Tristan da Cunha",
			SI: "Slovenia",
			SJ: "Svalbard and Jan Mayen",
			SK: "Slovakia",
			SL: "Sierra Leone",
			SM: "San Marino",
			SN: "Senegal",
			SO: "Somalia",
			SR: "Suriname",
			SS: "South Sudan",
			ST: "Sao Tome and Principe",
			SV: "El Salvador",
			SX: "Sint Maarten",
			SY: "Syria",
			SZ: "Eswatini",
			TC: "Turks and Caicos Islands",
			TD: "Chad",
			TF: "French Southern Territories",
			TG: "Togo",
			TH: "Thailand",
			TJ: "Tajikistan",
			TK: "Tokelau",
			TL: "Timor-Leste",
			TM: "Turkmenistan",
			TN: "Tunisia",
			TO: "Tonga",
			TR: "Turkey",
			TT: "Trinidad and Tobago",
			TV: "Tuvalu",
			TW: "Taiwan",
			TZ: "Tanzania",
			UA: "Ukraine",
			UG: "Uganda",
			UM: "United States Minor Outlying Islands",
			US: "United States of America",
			UY: "Uruguay",
			UZ: "Uzbekistan",
			VA: "Holy See",
			VC: "Saint Vincent and the Grenadines",
			VE: "Venezuela",
			VG: "Virgin Islands (UK)",
			VI: "Virgin Islands (US)",
			VN: "Vietnam",
			VU: "Vanuatu",
			WF: "Wallis and Futuna",
			WS: "Samoa",
			YE: "Yemen",
			YT: "Mayotte",
			ZA: "South Africa",
			ZM: "Zambia",
			ZW: "Zimbabwe"
		};

		const timezones = {
			"Africa/Abidjan": {
				u: 0,
				c: ["CI", "BF", "GH", "GM", "GN", "ML", "MR", "SH", "SL", "SN", "TG"]
			},
			"Africa/Accra": {
				a: "Africa/Abidjan",
				c: ["GH"],
				r: 1
			},
			"Africa/Addis_Ababa": {
				a: "Africa/Nairobi",
				c: ["ET"],
				r: 1
			},
			"Africa/Algiers": {
				u: 60,
				c: ["DZ"]
			},
			"Africa/Asmara": {
				a: "Africa/Nairobi",
				c: ["ER"],
				r: 1
			},
			"Africa/Asmera": {
				a: "Africa/Nairobi",
				c: ["ER"],
				r: 1
			},
			"Africa/Bamako": {
				a: "Africa/Abidjan",
				c: ["ML"],
				r: 1
			},
			"Africa/Bangui": {
				a: "Africa/Lagos",
				c: ["CF"],
				r: 1
			},
			"Africa/Banjul": {
				a: "Africa/Abidjan",
				c: ["GM"],
				r: 1
			},
			"Africa/Bissau": {
				u: 0,
				c: ["GW"]
			},
			"Africa/Blantyre": {
				a: "Africa/Maputo",
				c: ["MW"],
				r: 1
			},
			"Africa/Brazzaville": {
				a: "Africa/Lagos",
				c: ["CG"],
				r: 1
			},
			"Africa/Bujumbura": {
				a: "Africa/Maputo",
				c: ["BI"],
				r: 1
			},
			"Africa/Cairo": {
				u: 120,
				c: ["EG"]
			},
			"Africa/Casablanca": {
				u: 60,
				d: 0,
				c: ["MA"]
			},
			"Africa/Ceuta": {
				u: 60,
				d: 120,
				c: ["ES"]
			},
			"Africa/Conakry": {
				a: "Africa/Abidjan",
				c: ["GN"],
				r: 1
			},
			"Africa/Dakar": {
				a: "Africa/Abidjan",
				c: ["SN"],
				r: 1
			},
			"Africa/Dar_es_Salaam": {
				a: "Africa/Nairobi",
				c: ["TZ"],
				r: 1
			},
			"Africa/Djibouti": {
				a: "Africa/Nairobi",
				c: ["DJ"],
				r: 1
			},
			"Africa/Douala": {
				a: "Africa/Lagos",
				c: ["CM"],
				r: 1
			},
			"Africa/El_Aaiun": {
				u: 60,
				d: 0,
				c: ["EH"]
			},
			"Africa/Freetown": {
				a: "Africa/Abidjan",
				c: ["SL"],
				r: 1
			},
			"Africa/Gaborone": {
				a: "Africa/Maputo",
				c: ["BW"],
				r: 1
			},
			"Africa/Harare": {
				a: "Africa/Maputo",
				c: ["ZW"],
				r: 1
			},
			"Africa/Johannesburg": {
				u: 120,
				c: ["ZA", "LS", "SZ"]
			},
			"Africa/Juba": {
				u: 120,
				c: ["SS"]
			},
			"Africa/Kampala": {
				a: "Africa/Nairobi",
				c: ["UG"],
				r: 1
			},
			"Africa/Khartoum": {
				u: 120,
				c: ["SD"]
			},
			"Africa/Kigali": {
				a: "Africa/Maputo",
				c: ["RW"],
				r: 1
			},
			"Africa/Kinshasa": {
				a: "Africa/Lagos",
				c: ["CD"],
				r: 1
			},
			"Africa/Lagos": {
				u: 60,
				c: ["NG", "AO", "BJ", "CD", "CF", "CG", "CM", "GA", "GQ", "NE"]
			},
			"Africa/Libreville": {
				a: "Africa/Lagos",
				c: ["GA"],
				r: 1
			},
			"Africa/Lome": {
				a: "Africa/Abidjan",
				c: ["TG"],
				r: 1
			},
			"Africa/Luanda": {
				a: "Africa/Lagos",
				c: ["AO"],
				r: 1
			},
			"Africa/Lubumbashi": {
				a: "Africa/Maputo",
				c: ["CD"],
				r: 1
			},
			"Africa/Lusaka": {
				a: "Africa/Maputo",
				c: ["ZM"],
				r: 1
			},
			"Africa/Malabo": {
				a: "Africa/Lagos",
				c: ["GQ"],
				r: 1
			},
			"Africa/Maputo": {
				u: 120,
				c: ["MZ", "BI", "BW", "CD", "MW", "RW", "ZM", "ZW"]
			},
			"Africa/Maseru": {
				a: "Africa/Johannesburg",
				c: ["LS"],
				r: 1
			},
			"Africa/Mbabane": {
				a: "Africa/Johannesburg",
				c: ["SZ"],
				r: 1
			},
			"Africa/Mogadishu": {
				a: "Africa/Nairobi",
				c: ["SO"],
				r: 1
			},
			"Africa/Monrovia": {
				u: 0,
				c: ["LR"]
			},
			"Africa/Nairobi": {
				u: 180,
				c: ["KE", "DJ", "ER", "ET", "KM", "MG", "SO", "TZ", "UG", "YT"]
			},
			"Africa/Ndjamena": {
				u: 60,
				c: ["TD"]
			},
			"Africa/Niamey": {
				a: "Africa/Lagos",
				c: ["NE"],
				r: 1
			},
			"Africa/Nouakchott": {
				a: "Africa/Abidjan",
				c: ["MR"],
				r: 1
			},
			"Africa/Ouagadougou": {
				a: "Africa/Abidjan",
				c: ["BF"],
				r: 1
			},
			"Africa/Porto-Novo": {
				a: "Africa/Lagos",
				c: ["BJ"],
				r: 1
			},
			"Africa/Sao_Tome": {
				u: 0,
				c: ["ST"]
			},
			"Africa/Timbuktu": {
				a: "Africa/Abidjan",
				c: ["ML"],
				r: 1
			},
			"Africa/Tripoli": {
				u: 120,
				c: ["LY"]
			},
			"Africa/Tunis": {
				u: 60,
				c: ["TN"]
			},
			"Africa/Windhoek": {
				u: 120,
				c: ["NA"]
			},
			"America/Adak": {
				u: -600,
				d: -540,
				c: ["US"]
			},
			"America/Anchorage": {
				u: -540,
				d: -480,
				c: ["US"]
			},
			"America/Anguilla": {
				a: "America/Puerto_Rico",
				c: ["AI"],
				r: 1
			},
			"America/Antigua": {
				a: "America/Puerto_Rico",
				c: ["AG"],
				r: 1
			},
			"America/Araguaina": {
				u: -180,
				c: ["BR"]
			},
			"America/Argentina/Buenos_Aires": {
				u: -180,
				c: ["AR"]
			},
			"America/Argentina/Catamarca": {
				u: -180,
				c: ["AR"]
			},
			"America/Argentina/ComodRivadavia": {
				a: "America/Argentina/Catamarca",
				r: 1
			},
			"America/Argentina/Cordoba": {
				u: -180,
				c: ["AR"]
			},
			"America/Argentina/Jujuy": {
				u: -180,
				c: ["AR"]
			},
			"America/Argentina/La_Rioja": {
				u: -180,
				c: ["AR"]
			},
			"America/Argentina/Mendoza": {
				u: -180,
				c: ["AR"]
			},
			"America/Argentina/Rio_Gallegos": {
				u: -180,
				c: ["AR"]
			},
			"America/Argentina/Salta": {
				u: -180,
				c: ["AR"]
			},
			"America/Argentina/San_Juan": {
				u: -180,
				c: ["AR"]
			},
			"America/Argentina/San_Luis": {
				u: -180,
				c: ["AR"]
			},
			"America/Argentina/Tucuman": {
				u: -180,
				c: ["AR"]
			},
			"America/Argentina/Ushuaia": {
				u: -180,
				c: ["AR"]
			},
			"America/Aruba": {
				a: "America/Puerto_Rico",
				c: ["AW"],
				r: 1
			},
			"America/Asuncion": {
				u: -240,
				d: -180,
				c: ["PY"]
			},
			"America/Atikokan": {
				a: "America/Panama",
				c: ["CA"],
				r: 1
			},
			"America/Atka": {
				a: "America/Adak",
				r: 1
			},
			"America/Bahia": {
				u: -180,
				c: ["BR"]
			},
			"America/Bahia_Banderas": {
				u: -360,
				d: -300,
				c: ["MX"]
			},
			"America/Barbados": {
				u: -240,
				c: ["BB"]
			},
			"America/Belem": {
				u: -180,
				c: ["BR"]
			},
			"America/Belize": {
				u: -360,
				c: ["BZ"]
			},
			"America/Blanc-Sablon": {
				a: "America/Puerto_Rico",
				c: ["CA"],
				r: 1
			},
			"America/Boa_Vista": {
				u: -240,
				c: ["BR"]
			},
			"America/Bogota": {
				u: -300,
				c: ["CO"]
			},
			"America/Boise": {
				u: -420,
				d: -360,
				c: ["US"]
			},
			"America/Buenos_Aires": {
				a: "America/Argentina/Buenos_Aires",
				r: 1
			},
			"America/Cambridge_Bay": {
				u: -420,
				d: -360,
				c: ["CA"]
			},
			"America/Campo_Grande": {
				u: -240,
				c: ["BR"]
			},
			"America/Cancun": {
				u: -300,
				c: ["MX"]
			},
			"America/Caracas": {
				u: -240,
				c: ["VE"]
			},
			"America/Catamarca": {
				a: "America/Argentina/Catamarca",
				r: 1
			},
			"America/Cayenne": {
				u: -180,
				c: ["GF"]
			},
			"America/Cayman": {
				a: "America/Panama",
				c: ["KY"],
				r: 1
			},
			"America/Chicago": {
				u: -360,
				d: -300,
				c: ["US"]
			},
			"America/Chihuahua": {
				u: -420,
				d: -360,
				c: ["MX"]
			},
			"America/Coral_Harbour": {
				a: "America/Panama",
				c: ["CA"],
				r: 1
			},
			"America/Cordoba": {
				a: "America/Argentina/Cordoba",
				r: 1
			},
			"America/Costa_Rica": {
				u: -360,
				c: ["CR"]
			},
			"America/Creston": {
				a: "America/Phoenix",
				c: ["CA"],
				r: 1
			},
			"America/Cuiaba": {
				u: -240,
				c: ["BR"]
			},
			"America/Curacao": {
				a: "America/Puerto_Rico",
				c: ["CW"],
				r: 1
			},
			"America/Danmarkshavn": {
				u: 0,
				c: ["GL"]
			},
			"America/Dawson": {
				u: -420,
				c: ["CA"]
			},
			"America/Dawson_Creek": {
				u: -420,
				c: ["CA"]
			},
			"America/Denver": {
				u: -420,
				d: -360,
				c: ["US"]
			},
			"America/Detroit": {
				u: -300,
				d: -240,
				c: ["US"]
			},
			"America/Dominica": {
				a: "America/Puerto_Rico",
				c: ["DM"],
				r: 1
			},
			"America/Edmonton": {
				u: -420,
				d: -360,
				c: ["CA"]
			},
			"America/Eirunepe": {
				u: -300,
				c: ["BR"]
			},
			"America/El_Salvador": {
				u: -360,
				c: ["SV"]
			},
			"America/Ensenada": {
				a: "America/Tijuana",
				r: 1
			},
			"America/Fort_Nelson": {
				u: -420,
				c: ["CA"]
			},
			"America/Fort_Wayne": {
				a: "America/Indiana/Indianapolis",
				r: 1
			},
			"America/Fortaleza": {
				u: -180,
				c: ["BR"]
			},
			"America/Glace_Bay": {
				u: -240,
				d: -180,
				c: ["CA"]
			},
			"America/Godthab": {
				a: "America/Nuuk",
				r: 1
			},
			"America/Goose_Bay": {
				u: -240,
				d: -180,
				c: ["CA"]
			},
			"America/Grand_Turk": {
				u: -300,
				d: -240,
				c: ["TC"]
			},
			"America/Grenada": {
				a: "America/Puerto_Rico",
				c: ["GD"],
				r: 1
			},
			"America/Guadeloupe": {
				a: "America/Puerto_Rico",
				c: ["GP"],
				r: 1
			},
			"America/Guatemala": {
				u: -360,
				c: ["GT"]
			},
			"America/Guayaquil": {
				u: -300,
				c: ["EC"]
			},
			"America/Guyana": {
				u: -240,
				c: ["GY"]
			},
			"America/Halifax": {
				u: -240,
				d: -180,
				c: ["CA"]
			},
			"America/Havana": {
				u: -300,
				d: -240,
				c: ["CU"]
			},
			"America/Hermosillo": {
				u: -420,
				c: ["MX"]
			},
			"America/Indiana/Indianapolis": {
				u: -300,
				d: -240,
				c: ["US"]
			},
			"America/Indiana/Knox": {
				u: -360,
				d: -300,
				c: ["US"]
			},
			"America/Indiana/Marengo": {
				u: -300,
				d: -240,
				c: ["US"]
			},
			"America/Indiana/Petersburg": {
				u: -300,
				d: -240,
				c: ["US"]
			},
			"America/Indiana/Tell_City": {
				u: -360,
				d: -300,
				c: ["US"]
			},
			"America/Indiana/Vevay": {
				u: -300,
				d: -240,
				c: ["US"]
			},
			"America/Indiana/Vincennes": {
				u: -300,
				d: -240,
				c: ["US"]
			},
			"America/Indiana/Winamac": {
				u: -300,
				d: -240,
				c: ["US"]
			},
			"America/Indianapolis": {
				a: "America/Indiana/Indianapolis",
				r: 1
			},
			"America/Inuvik": {
				u: -420,
				d: -360,
				c: ["CA"]
			},
			"America/Iqaluit": {
				u: -300,
				d: -240,
				c: ["CA"]
			},
			"America/Jamaica": {
				u: -300,
				c: ["JM"]
			},
			"America/Jujuy": {
				a: "America/Argentina/Jujuy",
				r: 1
			},
			"America/Juneau": {
				u: -540,
				d: -480,
				c: ["US"]
			},
			"America/Kentucky/Louisville": {
				u: -300,
				d: -240,
				c: ["US"]
			},
			"America/Kentucky/Monticello": {
				u: -300,
				d: -240,
				c: ["US"]
			},
			"America/Knox_IN": {
				a: "America/Indiana/Knox",
				r: 1
			},
			"America/Kralendijk": {
				a: "America/Puerto_Rico",
				c: ["BQ"],
				r: 1
			},
			"America/La_Paz": {
				u: -240,
				c: ["BO"]
			},
			"America/Lima": {
				u: -300,
				c: ["PE"]
			},
			"America/Los_Angeles": {
				u: -480,
				d: -420,
				c: ["US"]
			},
			"America/Louisville": {
				a: "America/Kentucky/Louisville",
				r: 1
			},
			"America/Lower_Princes": {
				a: "America/Puerto_Rico",
				c: ["SX"],
				r: 1
			},
			"America/Maceio": {
				u: -180,
				c: ["BR"]
			},
			"America/Managua": {
				u: -360,
				c: ["NI"]
			},
			"America/Manaus": {
				u: -240,
				c: ["BR"]
			},
			"America/Marigot": {
				a: "America/Puerto_Rico",
				c: ["MF"],
				r: 1
			},
			"America/Martinique": {
				u: -240,
				c: ["MQ"]
			},
			"America/Matamoros": {
				u: -360,
				d: -300,
				c: ["MX"]
			},
			"America/Mazatlan": {
				u: -420,
				d: -360,
				c: ["MX"]
			},
			"America/Mendoza": {
				a: "America/Argentina/Mendoza",
				r: 1
			},
			"America/Menominee": {
				u: -360,
				d: -300,
				c: ["US"]
			},
			"America/Merida": {
				u: -360,
				d: -300,
				c: ["MX"]
			},
			"America/Metlakatla": {
				u: -540,
				d: -480,
				c: ["US"]
			},
			"America/Mexico_City": {
				u: -360,
				d: -300,
				c: ["MX"]
			},
			"America/Miquelon": {
				u: -180,
				d: -120,
				c: ["PM"]
			},
			"America/Moncton": {
				u: -240,
				d: -180,
				c: ["CA"]
			},
			"America/Monterrey": {
				u: -360,
				d: -300,
				c: ["MX"]
			},
			"America/Montevideo": {
				u: -180,
				c: ["UY"]
			},
			"America/Montreal": {
				a: "America/Toronto",
				c: ["CA"],
				r: 1
			},
			"America/Montserrat": {
				a: "America/Puerto_Rico",
				c: ["MS"],
				r: 1
			},
			"America/Nassau": {
				a: "America/Toronto",
				c: ["BS"],
				r: 1
			},
			"America/New_York": {
				u: -300,
				d: -240,
				c: ["US"]
			},
			"America/Nipigon": {
				u: -300,
				d: -240,
				c: ["CA"]
			},
			"America/Nome": {
				u: -540,
				d: -480,
				c: ["US"]
			},
			"America/Noronha": {
				u: -120,
				c: ["BR"]
			},
			"America/North_Dakota/Beulah": {
				u: -360,
				d: -300,
				c: ["US"]
			},
			"America/North_Dakota/Center": {
				u: -360,
				d: -300,
				c: ["US"]
			},
			"America/North_Dakota/New_Salem": {
				u: -360,
				d: -300,
				c: ["US"]
			},
			"America/Nuuk": {
				u: -180,
				d: -120,
				c: ["GL"]
			},
			"America/Ojinaga": {
				u: -420,
				d: -360,
				c: ["MX"]
			},
			"America/Panama": {
				u: -300,
				c: ["PA", "CA", "KY"]
			},
			"America/Pangnirtung": {
				u: -300,
				d: -240,
				c: ["CA"]
			},
			"America/Paramaribo": {
				u: -180,
				c: ["SR"]
			},
			"America/Phoenix": {
				u: -420,
				c: ["US", "CA"]
			},
			"America/Port-au-Prince": {
				u: -300,
				d: -240,
				c: ["HT"]
			},
			"America/Port_of_Spain": {
				a: "America/Puerto_Rico",
				c: ["TT"],
				r: 1
			},
			"America/Porto_Acre": {
				a: "America/Rio_Branco",
				r: 1
			},
			"America/Porto_Velho": {
				u: -240,
				c: ["BR"]
			},
			"America/Puerto_Rico": {
				u: -240,
				c: [
					"PR",
					"AG",
					"CA",
					"AI",
					"AW",
					"BL",
					"BQ",
					"CW",
					"DM",
					"GD",
					"GP",
					"KN",
					"LC",
					"MF",
					"MS",
					"SX",
					"TT",
					"VC",
					"VG",
					"VI"
				]
			},
			"America/Punta_Arenas": {
				u: -180,
				c: ["CL"]
			},
			"America/Rainy_River": {
				u: -360,
				d: -300,
				c: ["CA"]
			},
			"America/Rankin_Inlet": {
				u: -360,
				d: -300,
				c: ["CA"]
			},
			"America/Recife": {
				u: -180,
				c: ["BR"]
			},
			"America/Regina": {
				u: -360,
				c: ["CA"]
			},
			"America/Resolute": {
				u: -360,
				d: -300,
				c: ["CA"]
			},
			"America/Rio_Branco": {
				u: -300,
				c: ["BR"]
			},
			"America/Rosario": {
				a: "America/Argentina/Cordoba",
				r: 1
			},
			"America/Santa_Isabel": {
				a: "America/Tijuana",
				r: 1
			},
			"America/Santarem": {
				u: -180,
				c: ["BR"]
			},
			"America/Santiago": {
				u: -240,
				d: -180,
				c: ["CL"]
			},
			"America/Santo_Domingo": {
				u: -240,
				c: ["DO"]
			},
			"America/Sao_Paulo": {
				u: -180,
				c: ["BR"]
			},
			"America/Scoresbysund": {
				u: -60,
				d: 0,
				c: ["GL"]
			},
			"America/Shiprock": {
				a: "America/Denver",
				r: 1
			},
			"America/Sitka": {
				u: -540,
				d: -480,
				c: ["US"]
			},
			"America/St_Barthelemy": {
				a: "America/Puerto_Rico",
				c: ["BL"],
				r: 1
			},
			"America/St_Johns": {
				u: -150,
				d: -90,
				c: ["CA"]
			},
			"America/St_Kitts": {
				a: "America/Puerto_Rico",
				c: ["KN"],
				r: 1
			},
			"America/St_Lucia": {
				a: "America/Puerto_Rico",
				c: ["LC"],
				r: 1
			},
			"America/St_Thomas": {
				a: "America/Puerto_Rico",
				c: ["VI"],
				r: 1
			},
			"America/St_Vincent": {
				a: "America/Puerto_Rico",
				c: ["VC"],
				r: 1
			},
			"America/Swift_Current": {
				u: -360,
				c: ["CA"]
			},
			"America/Tegucigalpa": {
				u: -360,
				c: ["HN"]
			},
			"America/Thule": {
				u: -240,
				d: -180,
				c: ["GL"]
			},
			"America/Thunder_Bay": {
				u: -300,
				d: -240,
				c: ["CA"]
			},
			"America/Tijuana": {
				u: -480,
				d: -420,
				c: ["MX"]
			},
			"America/Toronto": {
				u: -300,
				d: -240,
				c: ["CA", "BS"]
			},
			"America/Tortola": {
				a: "America/Puerto_Rico",
				c: ["VG"],
				r: 1
			},
			"America/Vancouver": {
				u: -480,
				d: -420,
				c: ["CA"]
			},
			"America/Virgin": {
				a: "America/Puerto_Rico",
				c: ["VI"],
				r: 1
			},
			"America/Whitehorse": {
				u: -420,
				c: ["CA"]
			},
			"America/Winnipeg": {
				u: -360,
				d: -300,
				c: ["CA"]
			},
			"America/Yakutat": {
				u: -540,
				d: -480,
				c: ["US"]
			},
			"America/Yellowknife": {
				u: -420,
				d: -360,
				c: ["CA"]
			},
			"Antarctica/Casey": {
				u: 660,
				c: ["AQ"]
			},
			"Antarctica/Davis": {
				u: 420,
				c: ["AQ"]
			},
			"Antarctica/DumontDUrville": {
				a: "Pacific/Port_Moresby",
				c: ["AQ"],
				r: 1
			},
			"Antarctica/Macquarie": {
				u: 600,
				d: 660,
				c: ["AU"]
			},
			"Antarctica/Mawson": {
				u: 300,
				c: ["AQ"]
			},
			"Antarctica/McMurdo": {
				a: "Pacific/Auckland",
				c: ["AQ"],
				r: 1
			},
			"Antarctica/Palmer": {
				u: -180,
				c: ["AQ"]
			},
			"Antarctica/Rothera": {
				u: -180,
				c: ["AQ"]
			},
			"Antarctica/South_Pole": {
				a: "Pacific/Auckland",
				c: ["AQ"],
				r: 1
			},
			"Antarctica/Syowa": {
				a: "Asia/Riyadh",
				c: ["AQ"],
				r: 1
			},
			"Antarctica/Troll": {
				u: 0,
				d: 120,
				c: ["AQ"]
			},
			"Antarctica/Vostok": {
				u: 360,
				c: ["AQ"]
			},
			"Arctic/Longyearbyen": {
				a: "Europe/Oslo",
				c: ["SJ"],
				r: 1
			},
			"Asia/Aden": {
				a: "Asia/Riyadh",
				c: ["YE"],
				r: 1
			},
			"Asia/Almaty": {
				u: 360,
				c: ["KZ"]
			},
			"Asia/Amman": {
				u: 120,
				d: 180,
				c: ["JO"]
			},
			"Asia/Anadyr": {
				u: 720,
				c: ["RU"]
			},
			"Asia/Aqtau": {
				u: 300,
				c: ["KZ"]
			},
			"Asia/Aqtobe": {
				u: 300,
				c: ["KZ"]
			},
			"Asia/Ashgabat": {
				u: 300,
				c: ["TM"]
			},
			"Asia/Ashkhabad": {
				a: "Asia/Ashgabat",
				r: 1
			},
			"Asia/Atyrau": {
				u: 300,
				c: ["KZ"]
			},
			"Asia/Baghdad": {
				u: 180,
				c: ["IQ"]
			},
			"Asia/Bahrain": {
				a: "Asia/Qatar",
				c: ["BH"],
				r: 1
			},
			"Asia/Baku": {
				u: 240,
				c: ["AZ"]
			},
			"Asia/Bangkok": {
				u: 420,
				c: ["TH", "KH", "LA", "VN"]
			},
			"Asia/Barnaul": {
				u: 420,
				c: ["RU"]
			},
			"Asia/Beirut": {
				u: 120,
				d: 180,
				c: ["LB"]
			},
			"Asia/Bishkek": {
				u: 360,
				c: ["KG"]
			},
			"Asia/Brunei": {
				u: 480,
				c: ["BN"]
			},
			"Asia/Calcutta": {
				a: "Asia/Kolkata",
				r: 1
			},
			"Asia/Chita": {
				u: 540,
				c: ["RU"]
			},
			"Asia/Choibalsan": {
				u: 480,
				c: ["MN"]
			},
			"Asia/Chongqing": {
				a: "Asia/Shanghai",
				r: 1
			},
			"Asia/Chungking": {
				a: "Asia/Shanghai",
				r: 1
			},
			"Asia/Colombo": {
				u: 330,
				c: ["LK"]
			},
			"Asia/Dacca": {
				a: "Asia/Dhaka",
				r: 1
			},
			"Asia/Damascus": {
				u: 120,
				d: 180,
				c: ["SY"]
			},
			"Asia/Dhaka": {
				u: 360,
				c: ["BD"]
			},
			"Asia/Dili": {
				u: 540,
				c: ["TL"]
			},
			"Asia/Dubai": {
				u: 240,
				c: ["AE", "OM"]
			},
			"Asia/Dushanbe": {
				u: 300,
				c: ["TJ"]
			},
			"Asia/Famagusta": {
				u: 120,
				d: 180,
				c: ["CY"]
			},
			"Asia/Gaza": {
				u: 120,
				d: 180,
				c: ["PS"]
			},
			"Asia/Harbin": {
				a: "Asia/Shanghai",
				r: 1
			},
			"Asia/Hebron": {
				u: 120,
				d: 180,
				c: ["PS"]
			},
			"Asia/Ho_Chi_Minh": {
				u: 420,
				c: ["VN"]
			},
			"Asia/Hong_Kong": {
				u: 480,
				c: ["HK"]
			},
			"Asia/Hovd": {
				u: 420,
				c: ["MN"]
			},
			"Asia/Irkutsk": {
				u: 480,
				c: ["RU"]
			},
			"Asia/Istanbul": {
				a: "Europe/Istanbul",
				r: 1
			},
			"Asia/Jakarta": {
				u: 420,
				c: ["ID"]
			},
			"Asia/Jayapura": {
				u: 540,
				c: ["ID"]
			},
			"Asia/Jerusalem": {
				u: 120,
				d: 180,
				c: ["IL"]
			},
			"Asia/Kabul": {
				u: 270,
				c: ["AF"]
			},
			"Asia/Kamchatka": {
				u: 720,
				c: ["RU"]
			},
			"Asia/Karachi": {
				u: 300,
				c: ["PK"]
			},
			"Asia/Kashgar": {
				a: "Asia/Urumqi",
				r: 1
			},
			"Asia/Kathmandu": {
				u: 345,
				c: ["NP"]
			},
			"Asia/Katmandu": {
				a: "Asia/Kathmandu",
				r: 1
			},
			"Asia/Khandyga": {
				u: 540,
				c: ["RU"]
			},
			"Asia/Kolkata": {
				u: 330,
				c: ["IN"]
			},
			"Asia/Krasnoyarsk": {
				u: 420,
				c: ["RU"]
			},
			"Asia/Kuala_Lumpur": {
				u: 480,
				c: ["MY"]
			},
			"Asia/Kuching": {
				u: 480,
				c: ["MY"]
			},
			"Asia/Kuwait": {
				a: "Asia/Riyadh",
				c: ["KW"],
				r: 1
			},
			"Asia/Macao": {
				a: "Asia/Macau",
				r: 1
			},
			"Asia/Macau": {
				u: 480,
				c: ["MO"]
			},
			"Asia/Magadan": {
				u: 660,
				c: ["RU"]
			},
			"Asia/Makassar": {
				u: 480,
				c: ["ID"]
			},
			"Asia/Manila": {
				u: 480,
				c: ["PH"]
			},
			"Asia/Muscat": {
				a: "Asia/Dubai",
				c: ["OM"],
				r: 1
			},
			"Asia/Nicosia": {
				u: 120,
				d: 180,
				c: ["CY"]
			},
			"Asia/Novokuznetsk": {
				u: 420,
				c: ["RU"]
			},
			"Asia/Novosibirsk": {
				u: 420,
				c: ["RU"]
			},
			"Asia/Omsk": {
				u: 360,
				c: ["RU"]
			},
			"Asia/Oral": {
				u: 300,
				c: ["KZ"]
			},
			"Asia/Phnom_Penh": {
				a: "Asia/Bangkok",
				c: ["KH"],
				r: 1
			},
			"Asia/Pontianak": {
				u: 420,
				c: ["ID"]
			},
			"Asia/Pyongyang": {
				u: 540,
				c: ["KP"]
			},
			"Asia/Qatar": {
				u: 180,
				c: ["QA", "BH"]
			},
			"Asia/Qostanay": {
				u: 360,
				c: ["KZ"]
			},
			"Asia/Qyzylorda": {
				u: 300,
				c: ["KZ"]
			},
			"Asia/Rangoon": {
				a: "Asia/Yangon",
				r: 1
			},
			"Asia/Riyadh": {
				u: 180,
				c: ["SA", "AQ", "KW", "YE"]
			},
			"Asia/Saigon": {
				a: "Asia/Ho_Chi_Minh",
				r: 1
			},
			"Asia/Sakhalin": {
				u: 660,
				c: ["RU"]
			},
			"Asia/Samarkand": {
				u: 300,
				c: ["UZ"]
			},
			"Asia/Seoul": {
				u: 540,
				c: ["KR"]
			},
			"Asia/Shanghai": {
				u: 480,
				c: ["CN"]
			},
			"Asia/Singapore": {
				u: 480,
				c: ["SG", "MY"]
			},
			"Asia/Srednekolymsk": {
				u: 660,
				c: ["RU"]
			},
			"Asia/Taipei": {
				u: 480,
				c: ["TW"]
			},
			"Asia/Tashkent": {
				u: 300,
				c: ["UZ"]
			},
			"Asia/Tbilisi": {
				u: 240,
				c: ["GE"]
			},
			"Asia/Tehran": {
				u: 210,
				d: 270,
				c: ["IR"]
			},
			"Asia/Tel_Aviv": {
				a: "Asia/Jerusalem",
				r: 1
			},
			"Asia/Thimbu": {
				a: "Asia/Thimphu",
				r: 1
			},
			"Asia/Thimphu": {
				u: 360,
				c: ["BT"]
			},
			"Asia/Tokyo": {
				u: 540,
				c: ["JP"]
			},
			"Asia/Tomsk": {
				u: 420,
				c: ["RU"]
			},
			"Asia/Ujung_Pandang": {
				a: "Asia/Makassar",
				r: 1
			},
			"Asia/Ulaanbaatar": {
				u: 480,
				c: ["MN"]
			},
			"Asia/Ulan_Bator": {
				a: "Asia/Ulaanbaatar",
				r: 1
			},
			"Asia/Urumqi": {
				u: 360,
				c: ["CN"]
			},
			"Asia/Ust-Nera": {
				u: 600,
				c: ["RU"]
			},
			"Asia/Vientiane": {
				a: "Asia/Bangkok",
				c: ["LA"],
				r: 1
			},
			"Asia/Vladivostok": {
				u: 600,
				c: ["RU"]
			},
			"Asia/Yakutsk": {
				u: 540,
				c: ["RU"]
			},
			"Asia/Yangon": {
				u: 390,
				c: ["MM"]
			},
			"Asia/Yekaterinburg": {
				u: 300,
				c: ["RU"]
			},
			"Asia/Yerevan": {
				u: 240,
				c: ["AM"]
			},
			"Atlantic/Azores": {
				u: -60,
				d: 0,
				c: ["PT"]
			},
			"Atlantic/Bermuda": {
				u: -240,
				d: -180,
				c: ["BM"]
			},
			"Atlantic/Canary": {
				u: 0,
				d: 60,
				c: ["ES"]
			},
			"Atlantic/Cape_Verde": {
				u: -60,
				c: ["CV"]
			},
			"Atlantic/Faeroe": {
				a: "Atlantic/Faroe",
				r: 1
			},
			"Atlantic/Faroe": {
				u: 0,
				d: 60,
				c: ["FO"]
			},
			"Atlantic/Jan_Mayen": {
				a: "Europe/Oslo",
				c: ["SJ"],
				r: 1
			},
			"Atlantic/Madeira": {
				u: 0,
				d: 60,
				c: ["PT"]
			},
			"Atlantic/Reykjavik": {
				u: 0,
				c: ["IS"]
			},
			"Atlantic/South_Georgia": {
				u: -120,
				c: ["GS"]
			},
			"Atlantic/St_Helena": {
				a: "Africa/Abidjan",
				c: ["SH"],
				r: 1
			},
			"Atlantic/Stanley": {
				u: -180,
				c: ["FK"]
			},
			"Australia/ACT": {
				a: "Australia/Sydney",
				r: 1
			},
			"Australia/Adelaide": {
				u: 570,
				d: 630,
				c: ["AU"]
			},
			"Australia/Brisbane": {
				u: 600,
				c: ["AU"]
			},
			"Australia/Broken_Hill": {
				u: 570,
				d: 630,
				c: ["AU"]
			},
			"Australia/Canberra": {
				a: "Australia/Sydney",
				r: 1
			},
			"Australia/Currie": {
				a: "Australia/Hobart",
				r: 1
			},
			"Australia/Darwin": {
				u: 570,
				c: ["AU"]
			},
			"Australia/Eucla": {
				u: 525,
				c: ["AU"]
			},
			"Australia/Hobart": {
				u: 600,
				d: 660,
				c: ["AU"]
			},
			"Australia/LHI": {
				a: "Australia/Lord_Howe",
				r: 1
			},
			"Australia/Lindeman": {
				u: 600,
				c: ["AU"]
			},
			"Australia/Lord_Howe": {
				u: 630,
				d: 660,
				c: ["AU"]
			},
			"Australia/Melbourne": {
				u: 600,
				d: 660,
				c: ["AU"]
			},
			"Australia/NSW": {
				a: "Australia/Sydney",
				r: 1
			},
			"Australia/North": {
				a: "Australia/Darwin",
				r: 1
			},
			"Australia/Perth": {
				u: 480,
				c: ["AU"]
			},
			"Australia/Queensland": {
				a: "Australia/Brisbane",
				r: 1
			},
			"Australia/South": {
				a: "Australia/Adelaide",
				r: 1
			},
			"Australia/Sydney": {
				u: 600,
				d: 660,
				c: ["AU"]
			},
			"Australia/Tasmania": {
				a: "Australia/Hobart",
				r: 1
			},
			"Australia/Victoria": {
				a: "Australia/Melbourne",
				r: 1
			},
			"Australia/West": {
				a: "Australia/Perth",
				r: 1
			},
			"Australia/Yancowinna": {
				a: "Australia/Broken_Hill",
				r: 1
			},
			"Brazil/Acre": {
				a: "America/Rio_Branco",
				r: 1
			},
			"Brazil/DeNoronha": {
				a: "America/Noronha",
				r: 1
			},
			"Brazil/East": {
				a: "America/Sao_Paulo",
				r: 1
			},
			"Brazil/West": {
				a: "America/Manaus",
				r: 1
			},
			CET: {
				u: 60,
				d: 120
			},
			CST6CDT: {
				u: -360,
				d: -300
			},
			"Canada/Atlantic": {
				a: "America/Halifax",
				r: 1
			},
			"Canada/Central": {
				a: "America/Winnipeg",
				r: 1
			},
			"Canada/Eastern": {
				a: "America/Toronto",
				c: ["CA"],
				r: 1
			},
			"Canada/Mountain": {
				a: "America/Edmonton",
				r: 1
			},
			"Canada/Newfoundland": {
				a: "America/St_Johns",
				r: 1
			},
			"Canada/Pacific": {
				a: "America/Vancouver",
				r: 1
			},
			"Canada/Saskatchewan": {
				a: "America/Regina",
				r: 1
			},
			"Canada/Yukon": {
				a: "America/Whitehorse",
				r: 1
			},
			"Chile/Continental": {
				a: "America/Santiago",
				r: 1
			},
			"Chile/EasterIsland": {
				a: "Pacific/Easter",
				r: 1
			},
			Cuba: {
				a: "America/Havana",
				r: 1
			},
			EET: {
				u: 120,
				d: 180
			},
			EST: {
				u: -300
			},
			EST5EDT: {
				u: -300,
				d: -240
			},
			Egypt: {
				a: "Africa/Cairo",
				r: 1
			},
			Eire: {
				a: "Europe/Dublin",
				r: 1
			},
			"Etc/GMT": {
				u: 0
			},
			"Etc/GMT+0": {
				a: "Etc/GMT",
				r: 1
			},
			"Etc/GMT+1": {
				u: -60
			},
			"Etc/GMT+10": {
				u: -600
			},
			"Etc/GMT+11": {
				u: -660
			},
			"Etc/GMT+12": {
				u: -720
			},
			"Etc/GMT+2": {
				u: -120
			},
			"Etc/GMT+3": {
				u: -180
			},
			"Etc/GMT+4": {
				u: -240
			},
			"Etc/GMT+5": {
				u: -300
			},
			"Etc/GMT+6": {
				u: -360
			},
			"Etc/GMT+7": {
				u: -420
			},
			"Etc/GMT+8": {
				u: -480
			},
			"Etc/GMT+9": {
				u: -540
			},
			"Etc/GMT-0": {
				a: "Etc/GMT",
				r: 1
			},
			"Etc/GMT-1": {
				u: 60
			},
			"Etc/GMT-10": {
				u: 600
			},
			"Etc/GMT-11": {
				u: 660
			},
			"Etc/GMT-12": {
				u: 720
			},
			"Etc/GMT-13": {
				u: 780
			},
			"Etc/GMT-14": {
				u: 840
			},
			"Etc/GMT-2": {
				u: 120
			},
			"Etc/GMT-3": {
				u: 180
			},
			"Etc/GMT-4": {
				u: 240
			},
			"Etc/GMT-5": {
				u: 300
			},
			"Etc/GMT-6": {
				u: 360
			},
			"Etc/GMT-7": {
				u: 420
			},
			"Etc/GMT-8": {
				u: 480
			},
			"Etc/GMT-9": {
				u: 540
			},
			"Etc/GMT0": {
				a: "Etc/GMT",
				r: 1
			},
			"Etc/Greenwich": {
				a: "Etc/GMT",
				r: 1
			},
			"Etc/UCT": {
				a: "Etc/UTC",
				r: 1
			},
			"Etc/UTC": {
				u: 0
			},
			"Etc/Universal": {
				a: "Etc/UTC",
				r: 1
			},
			"Etc/Zulu": {
				a: "Etc/UTC",
				r: 1
			},
			"Europe/Amsterdam": {
				u: 60,
				d: 120,
				c: ["NL"]
			},
			"Europe/Andorra": {
				u: 60,
				d: 120,
				c: ["AD"]
			},
			"Europe/Astrakhan": {
				u: 240,
				c: ["RU"]
			},
			"Europe/Athens": {
				u: 120,
				d: 180,
				c: ["GR"]
			},
			"Europe/Belfast": {
				a: "Europe/London",
				c: ["GB"],
				r: 1
			},
			"Europe/Belgrade": {
				u: 60,
				d: 120,
				c: ["RS", "BA", "HR", "ME", "MK", "SI"]
			},
			"Europe/Berlin": {
				u: 60,
				d: 120,
				c: ["DE"]
			},
			"Europe/Bratislava": {
				a: "Europe/Prague",
				c: ["SK"],
				r: 1
			},
			"Europe/Brussels": {
				u: 60,
				d: 120,
				c: ["BE"]
			},
			"Europe/Bucharest": {
				u: 120,
				d: 180,
				c: ["RO"]
			},
			"Europe/Budapest": {
				u: 60,
				d: 120,
				c: ["HU"]
			},
			"Europe/Busingen": {
				a: "Europe/Zurich",
				c: ["DE"],
				r: 1
			},
			"Europe/Chisinau": {
				u: 120,
				d: 180,
				c: ["MD"]
			},
			"Europe/Copenhagen": {
				u: 60,
				d: 120,
				c: ["DK"]
			},
			"Europe/Dublin": {
				u: 60,
				d: 0,
				c: ["IE"]
			},
			"Europe/Gibraltar": {
				u: 60,
				d: 120,
				c: ["GI"]
			},
			"Europe/Guernsey": {
				a: "Europe/London",
				c: ["GG"],
				r: 1
			},
			"Europe/Helsinki": {
				u: 120,
				d: 180,
				c: ["FI", "AX"]
			},
			"Europe/Isle_of_Man": {
				a: "Europe/London",
				c: ["IM"],
				r: 1
			},
			"Europe/Istanbul": {
				u: 180,
				c: ["TR"]
			},
			"Europe/Jersey": {
				a: "Europe/London",
				c: ["JE"],
				r: 1
			},
			"Europe/Kaliningrad": {
				u: 120,
				c: ["RU"]
			},
			"Europe/Kiev": {
				u: 120,
				d: 180,
				c: ["UA"]
			},
			"Europe/Kirov": {
				u: 180,
				c: ["RU"]
			},
			"Europe/Lisbon": {
				u: 0,
				d: 60,
				c: ["PT"]
			},
			"Europe/Ljubljana": {
				a: "Europe/Belgrade",
				c: ["SI"],
				r: 1
			},
			"Europe/London": {
				u: 0,
				d: 60,
				c: ["GB", "GG", "IM", "JE"]
			},
			"Europe/Luxembourg": {
				u: 60,
				d: 120,
				c: ["LU"]
			},
			"Europe/Madrid": {
				u: 60,
				d: 120,
				c: ["ES"]
			},
			"Europe/Malta": {
				u: 60,
				d: 120,
				c: ["MT"]
			},
			"Europe/Mariehamn": {
				a: "Europe/Helsinki",
				c: ["AX"],
				r: 1
			},
			"Europe/Minsk": {
				u: 180,
				c: ["BY"]
			},
			"Europe/Monaco": {
				u: 60,
				d: 120,
				c: ["MC"]
			},
			"Europe/Moscow": {
				u: 180,
				c: ["RU"]
			},
			"Europe/Nicosia": {
				a: "Asia/Nicosia",
				r: 1
			},
			"Europe/Oslo": {
				u: 60,
				d: 120,
				c: ["NO", "SJ", "BV"]
			},
			"Europe/Paris": {
				u: 60,
				d: 120,
				c: ["FR"]
			},
			"Europe/Podgorica": {
				a: "Europe/Belgrade",
				c: ["ME"],
				r: 1
			},
			"Europe/Prague": {
				u: 60,
				d: 120,
				c: ["CZ", "SK"]
			},
			"Europe/Riga": {
				u: 120,
				d: 180,
				c: ["LV"]
			},
			"Europe/Rome": {
				u: 60,
				d: 120,
				c: ["IT", "SM", "VA"]
			},
			"Europe/Samara": {
				u: 240,
				c: ["RU"]
			},
			"Europe/San_Marino": {
				a: "Europe/Rome",
				c: ["SM"],
				r: 1
			},
			"Europe/Sarajevo": {
				a: "Europe/Belgrade",
				c: ["BA"],
				r: 1
			},
			"Europe/Saratov": {
				u: 240,
				c: ["RU"]
			},
			"Europe/Simferopol": {
				u: 180,
				c: ["RU", "UA"]
			},
			"Europe/Skopje": {
				a: "Europe/Belgrade",
				c: ["MK"],
				r: 1
			},
			"Europe/Sofia": {
				u: 120,
				d: 180,
				c: ["BG"]
			},
			"Europe/Stockholm": {
				u: 60,
				d: 120,
				c: ["SE"]
			},
			"Europe/Tallinn": {
				u: 120,
				d: 180,
				c: ["EE"]
			},
			"Europe/Tirane": {
				u: 60,
				d: 120,
				c: ["AL"]
			},
			"Europe/Tiraspol": {
				a: "Europe/Chisinau",
				r: 1
			},
			"Europe/Ulyanovsk": {
				u: 240,
				c: ["RU"]
			},
			"Europe/Uzhgorod": {
				u: 120,
				d: 180,
				c: ["UA"]
			},
			"Europe/Vaduz": {
				a: "Europe/Zurich",
				c: ["LI"],
				r: 1
			},
			"Europe/Vatican": {
				a: "Europe/Rome",
				c: ["VA"],
				r: 1
			},
			"Europe/Vienna": {
				u: 60,
				d: 120,
				c: ["AT"]
			},
			"Europe/Vilnius": {
				u: 120,
				d: 180,
				c: ["LT"]
			},
			"Europe/Volgograd": {
				u: 180,
				c: ["RU"]
			},
			"Europe/Warsaw": {
				u: 60,
				d: 120,
				c: ["PL"]
			},
			"Europe/Zagreb": {
				a: "Europe/Belgrade",
				c: ["HR"],
				r: 1
			},
			"Europe/Zaporozhye": {
				u: 120,
				d: 180,
				c: ["UA"]
			},
			"Europe/Zurich": {
				u: 60,
				d: 120,
				c: ["CH", "DE", "LI"]
			},
			Factory: {
				u: 0
			},
			GB: {
				a: "Europe/London",
				c: ["GB"],
				r: 1
			},
			"GB-Eire": {
				a: "Europe/London",
				c: ["GB"],
				r: 1
			},
			GMT: {
				a: "Etc/GMT",
				r: 1
			},
			"GMT+0": {
				a: "Etc/GMT",
				r: 1
			},
			"GMT-0": {
				a: "Etc/GMT",
				r: 1
			},
			GMT0: {
				a: "Etc/GMT",
				r: 1
			},
			Greenwich: {
				a: "Etc/GMT",
				r: 1
			},
			HST: {
				u: -600
			},
			Hongkong: {
				a: "Asia/Hong_Kong",
				r: 1
			},
			Iceland: {
				a: "Atlantic/Reykjavik",
				r: 1
			},
			"Indian/Antananarivo": {
				a: "Africa/Nairobi",
				c: ["MG"],
				r: 1
			},
			"Indian/Chagos": {
				u: 360,
				c: ["IO"]
			},
			"Indian/Christmas": {
				u: 420,
				c: ["CX"]
			},
			"Indian/Cocos": {
				u: 390,
				c: ["CC"]
			},
			"Indian/Comoro": {
				a: "Africa/Nairobi",
				c: ["KM"],
				r: 1
			},
			"Indian/Kerguelen": {
				u: 300,
				c: ["TF", "HM"]
			},
			"Indian/Mahe": {
				u: 240,
				c: ["SC"]
			},
			"Indian/Maldives": {
				u: 300,
				c: ["MV"]
			},
			"Indian/Mauritius": {
				u: 240,
				c: ["MU"]
			},
			"Indian/Mayotte": {
				a: "Africa/Nairobi",
				c: ["YT"],
				r: 1
			},
			"Indian/Reunion": {
				u: 240,
				c: ["RE", "TF"]
			},
			Iran: {
				a: "Asia/Tehran",
				r: 1
			},
			Israel: {
				a: "Asia/Jerusalem",
				r: 1
			},
			Jamaica: {
				a: "America/Jamaica",
				r: 1
			},
			Japan: {
				a: "Asia/Tokyo",
				r: 1
			},
			Kwajalein: {
				a: "Pacific/Kwajalein",
				r: 1
			},
			Libya: {
				a: "Africa/Tripoli",
				r: 1
			},
			MET: {
				u: 60,
				d: 120
			},
			MST: {
				u: -420
			},
			MST7MDT: {
				u: -420,
				d: -360
			},
			"Mexico/BajaNorte": {
				a: "America/Tijuana",
				r: 1
			},
			"Mexico/BajaSur": {
				a: "America/Mazatlan",
				r: 1
			},
			"Mexico/General": {
				a: "America/Mexico_City",
				r: 1
			},
			NZ: {
				a: "Pacific/Auckland",
				c: ["NZ"],
				r: 1
			},
			"NZ-CHAT": {
				a: "Pacific/Chatham",
				r: 1
			},
			Navajo: {
				a: "America/Denver",
				r: 1
			},
			PRC: {
				a: "Asia/Shanghai",
				r: 1
			},
			PST8PDT: {
				u: -480,
				d: -420
			},
			"Pacific/Apia": {
				u: 780,
				c: ["WS"]
			},
			"Pacific/Auckland": {
				u: 720,
				d: 780,
				c: ["NZ", "AQ"]
			},
			"Pacific/Bougainville": {
				u: 660,
				c: ["PG"]
			},
			"Pacific/Chatham": {
				u: 765,
				d: 825,
				c: ["NZ"]
			},
			"Pacific/Chuuk": {
				u: 600,
				c: ["FM"]
			},
			"Pacific/Easter": {
				u: -360,
				d: -300,
				c: ["CL"]
			},
			"Pacific/Efate": {
				u: 660,
				c: ["VU"]
			},
			"Pacific/Enderbury": {
				a: "Pacific/Kanton",
				r: 1
			},
			"Pacific/Fakaofo": {
				u: 780,
				c: ["TK"]
			},
			"Pacific/Fiji": {
				u: 720,
				d: 780,
				c: ["FJ"]
			},
			"Pacific/Funafuti": {
				u: 720,
				c: ["TV"]
			},
			"Pacific/Galapagos": {
				u: -360,
				c: ["EC"]
			},
			"Pacific/Gambier": {
				u: -540,
				c: ["PF"]
			},
			"Pacific/Guadalcanal": {
				u: 660,
				c: ["SB"]
			},
			"Pacific/Guam": {
				u: 600,
				c: ["GU", "MP"]
			},
			"Pacific/Honolulu": {
				u: -600,
				c: ["US", "UM"]
			},
			"Pacific/Johnston": {
				a: "Pacific/Honolulu",
				c: ["UM"],
				r: 1
			},
			"Pacific/Kanton": {
				u: 780,
				c: ["KI"]
			},
			"Pacific/Kiritimati": {
				u: 840,
				c: ["KI"]
			},
			"Pacific/Kosrae": {
				u: 660,
				c: ["FM"]
			},
			"Pacific/Kwajalein": {
				u: 720,
				c: ["MH"]
			},
			"Pacific/Majuro": {
				u: 720,
				c: ["MH"]
			},
			"Pacific/Marquesas": {
				u: -510,
				c: ["PF"]
			},
			"Pacific/Midway": {
				a: "Pacific/Pago_Pago",
				c: ["UM"],
				r: 1
			},
			"Pacific/Nauru": {
				u: 720,
				c: ["NR"]
			},
			"Pacific/Niue": {
				u: -660,
				c: ["NU"]
			},
			"Pacific/Norfolk": {
				u: 660,
				d: 720,
				c: ["NF"]
			},
			"Pacific/Noumea": {
				u: 660,
				c: ["NC"]
			},
			"Pacific/Pago_Pago": {
				u: -660,
				c: ["AS", "UM"]
			},
			"Pacific/Palau": {
				u: 540,
				c: ["PW"]
			},
			"Pacific/Pitcairn": {
				u: -480,
				c: ["PN"]
			},
			"Pacific/Pohnpei": {
				u: 660,
				c: ["FM"]
			},
			"Pacific/Ponape": {
				a: "Pacific/Pohnpei",
				r: 1
			},
			"Pacific/Port_Moresby": {
				u: 600,
				c: ["PG", "AQ"]
			},
			"Pacific/Rarotonga": {
				u: -600,
				c: ["CK"]
			},
			"Pacific/Saipan": {
				a: "Pacific/Guam",
				c: ["MP"],
				r: 1
			},
			"Pacific/Samoa": {
				a: "Pacific/Pago_Pago",
				c: ["WS"],
				r: 1
			},
			"Pacific/Tahiti": {
				u: -600,
				c: ["PF"]
			},
			"Pacific/Tarawa": {
				u: 720,
				c: ["KI"]
			},
			"Pacific/Tongatapu": {
				u: 780,
				c: ["TO"]
			},
			"Pacific/Truk": {
				a: "Pacific/Chuuk",
				r: 1
			},
			"Pacific/Wake": {
				u: 720,
				c: ["UM"]
			},
			"Pacific/Wallis": {
				u: 720,
				c: ["WF"]
			},
			"Pacific/Yap": {
				a: "Pacific/Chuuk",
				r: 1
			},
			Poland: {
				a: "Europe/Warsaw",
				r: 1
			},
			Portugal: {
				a: "Europe/Lisbon",
				r: 1
			},
			ROC: {
				a: "Asia/Taipei",
				r: 1
			},
			ROK: {
				a: "Asia/Seoul",
				r: 1
			},
			Singapore: {
				a: "Asia/Singapore",
				c: ["SG"],
				r: 1
			},
			Turkey: {
				a: "Europe/Istanbul",
				r: 1
			},
			UCT: {
				a: "Etc/UTC",
				r: 1
			},
			"US/Alaska": {
				a: "America/Anchorage",
				r: 1
			},
			"US/Aleutian": {
				a: "America/Adak",
				r: 1
			},
			"US/Arizona": {
				a: "America/Phoenix",
				c: ["US"],
				r: 1
			},
			"US/Central": {
				a: "America/Chicago",
				r: 1
			},
			"US/East-Indiana": {
				a: "America/Indiana/Indianapolis",
				r: 1
			},
			"US/Eastern": {
				a: "America/New_York",
				r: 1
			},
			"US/Hawaii": {
				a: "Pacific/Honolulu",
				c: ["US"],
				r: 1
			},
			"US/Indiana-Starke": {
				a: "America/Indiana/Knox",
				r: 1
			},
			"US/Michigan": {
				a: "America/Detroit",
				r: 1
			},
			"US/Mountain": {
				a: "America/Denver",
				r: 1
			},
			"US/Pacific": {
				a: "America/Los_Angeles",
				r: 1
			},
			"US/Samoa": {
				a: "Pacific/Pago_Pago",
				c: ["WS"],
				r: 1
			},
			UTC: {
				a: "Etc/UTC",
				r: 1
			},
			Universal: {
				a: "Etc/UTC",
				r: 1
			},
			"W-SU": {
				a: "Europe/Moscow",
				r: 1
			},
			WET: {
				u: 0,
				d: 60
			},
			Zulu: {
				a: "Etc/UTC",
				r: 1
			}
		};

		const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

		if (timezone === "" || !timezone) {
			return null;
		}

		const _country = timezones[timezone].c[0];
		const country = countries[_country];

		return country;
	}
}

export {
	allTotalsMarkets,
    allTeamTotalsMarkets,
    stylizedBookName,
    pointsUnitsBySport,
    stylizedSport,
    allFrenchBooks,
    allWorldBooks,
    stylizedSportsName,
    allSports,
    allMarkets,
    isBetWon,
    getPrintablePeriod,
    getPrintableBet,
    getPrintableUnit,
    formatBigNumber,
    getPrintableDate,
    getPrintableDateTime,
    getShortPrintableDateTime,
    getDateForInput,
    getPrintableDuration,
    getUserCountry
}