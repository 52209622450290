import React, { useEffect, useState } from 'react';
import axios from 'axios';
import toast from "react-hot-toast";
import _ from 'lodash';
import { BsFillSignStopFill } from "react-icons/bs";
import { FaCheck } from "react-icons/fa";
import FormField from '../components/FormField';
import { stylizedSport, getPrintablePeriod, getPrintableDateTime, allTotalsMarkets, allTeamTotalsMarkets } from "../utils";

function Administration({ t, isAxiosReady, setIsLoading, setLoadingText }) {
    const [bets, setBets] = useState([]);

    if (!isAxiosReady) {
        setIsLoading(true);
        setLoadingText("Chargement des paris...");
    }

    useEffect(() => {
        if (isAxiosReady) {
            let launchingDate = new Date();
            launchingDate.setDate(launchingDate.getDate() - 4);
            const endDate = new Date();
            endDate.setHours(endDate.getHours() - 2);

            axios.get(`/bet/admin?filters=startDate:${launchingDate.getTime()},endDate:${endDate.getTime()}`).then(response => {
                setBets(response.data);
                setIsLoading(false);
            }).catch(err => {
                console.log(err);
                setIsLoading(false);
            });
        }
    }, [isAxiosReady]);

    const onClosingUpdate = (eventId, marketName, newValue, team = null) => {
        const currentBetIndex = bets.findIndex(bet => bet.eventId === eventId);
        let newBets = [...bets];

        if (!newBets[currentBetIndex].hasOwnProperty("newClosings")) {
            newBets[currentBetIndex].newClosings = {};
        }

        if (marketName.includes("moneyline")) {
            newBets[currentBetIndex].newClosings[marketName] = newValue;
        } else if (allTotalsMarkets.includes(marketName)) {
            if (!newBets[currentBetIndex].newClosings.hasOwnProperty(marketName)) {
                newBets[currentBetIndex].newClosings[marketName] = [newValue];
            } else {
                const totalsIndex = newBets[currentBetIndex].newClosings[marketName].findIndex(t => t.points === newValue.points);
                if (totalsIndex === -1) {
                    newBets[currentBetIndex].newClosings[marketName].push(newValue);
                } else {
                    newBets[currentBetIndex].newClosings[marketName][totalsIndex] = newValue;
                }
            }
        } else if (marketName.includes("handicap")) {
            if (!newBets[currentBetIndex].newClosings.hasOwnProperty(marketName)) {
                newBets[currentBetIndex].newClosings[marketName] = [newValue];
            } else {
                const totalsIndex = newBets[currentBetIndex].newClosings[marketName].findIndex(t => t.points === newValue.points);
                if (totalsIndex === -1) {
                    newBets[currentBetIndex].newClosings[marketName].push(newValue);
                } else {
                    ["home", "away"].forEach(team => {
                        if (newValue.hasOwnProperty(team)) {
                            newBets[currentBetIndex].newClosings[marketName][totalsIndex][team] = newValue[team];
                        }
                    })
                }
            }
        } else if (allTeamTotalsMarkets.includes(marketName)) {
            if (!newBets[currentBetIndex].newClosings.hasOwnProperty(marketName)) {
                newBets[currentBetIndex].newClosings[marketName] = {};
            }
            if (!newBets[currentBetIndex].newClosings[marketName].hasOwnProperty(team)) {
                newBets[currentBetIndex].newClosings[marketName][team] = [newValue];
            } else {
                const totalsIndex = newBets[currentBetIndex].newClosings[marketName][team].findIndex(t => t.points === newValue.points);
                if (totalsIndex === -1) {
                    newBets[currentBetIndex].newClosings[marketName][team].push(newValue);
                } else {
                    newBets[currentBetIndex].newClosings[marketName][team][totalsIndex] = newValue;
                }
            }
        } else {
            newBets[currentBetIndex].newClosings[marketName] = newValue;
        }

        setBets(newBets);
    }

    const onResultUpdate = (eventId, newValue) => {
        const currentBetIndex = bets.findIndex(bet => bet.eventId === eventId);
        let newBets = [...bets];

        if (!newBets[currentBetIndex].hasOwnProperty("newResults")) {
            newBets[currentBetIndex].newResults = {};
        }
        newBets[currentBetIndex].newResults = {
            ...newBets[currentBetIndex].newResults,
            ...newValue
        };

        setBets(newBets);
    }

    const onSurrenderClicked = (eventId) => {
        const surrenderedBet = bets.find(bet => bet.eventId === eventId);
        axios.put("/bet/admin", { "bet": surrenderedBet, "surrender": true }).then(response => {
            toast.success("Le pari a bien été modifié !");

            const currentBetIndex = bets.findIndex(bet => bet.eventId === eventId);

            // Delete the bet from the list
            let newBets = [...bets];
            newBets.splice(currentBetIndex, 1);
            setBets(newBets);
        }).catch(err => {
            console.log(err);
            toast.error("Le pari n'a pas pu être modifié !");
        });
    }

    const onValidateClicked = (eventId) => {
        const validatedBet = bets.find(bet => bet.eventId === eventId);
        axios.put("/bet/admin", { "bet": validatedBet }).then(response => {
            toast.success("Le pari a bien été modifié !");

            const currentBetIndex = bets.findIndex(bet => bet.eventId === eventId);

            // Delete the bet from the list
            let newBets = [...bets];
            newBets[currentBetIndex].hidden = true;
            setBets(newBets);
        }).catch(err => {
            console.log(err);
            toast.error("Le pari n'a pas pu être modifié !");
        });
    }

    return (
        <div id="administration">
            <h1>Édition des cotes brutes et résultats manquants</h1>

            {
                bets.map((bet, indexBet) => {
                    return (
                        <div className={bet.hidden ? "hidden betContainer" : "betContainer"}>
                            <h2>{stylizedSport[bet.sport]} {bet.homeTeamName} vs. {bet.awayTeamName} - {getPrintableDateTime(bet.closureDate)}</h2>
                            <div className="splitView">
                                <div className="closingsContainer">
                                    <h3>Closings</h3>
                                    {bet.closings && Object.keys(bet.closings).map((marketName, indexMarket) => <ClosingItem key={bet.eventId + "-" + indexBet + "-" + indexMarket} eventId={bet.eventId} sport={bet.sport} marketName={marketName} closings={bet.closings[marketName]} onClosingUpdate={onClosingUpdate} />)}
                                </div>
                                <div className="resultsContainer">
                                    <h3>Résultats</h3>
                                    {bet.results && <ResultItem eventId={bet.eventId} sport={bet.sport} results={bet.results} onResultUpdate={onResultUpdate} />}
                                </div>
                                <div className="actionsContainer">
                                    <BsFillSignStopFill className="surrender" fontSize={30} onClick={() => onSurrenderClicked(bet.eventId)} />
                                    <FaCheck className="validate" fontSize={30} onClick={() => onValidateClicked(bet.eventId)} />
                                </div>
                            </div>
                        </div>
                    );
                })
            }
        </div>
    )
}

export default Administration

//#region Closings items
const ClosingItem = ({ eventId, sport, marketName, closings, onClosingUpdate }) => {
    const onOddUpdate = (marketName, newValue, team) => onClosingUpdate(eventId, marketName, newValue, team);

    if (marketName.includes("moneyline")) {
        return (
            <div className="closingContainer">
                <MoneylineClosing marketName={marketName} sport={sport} onOddUpdate={onOddUpdate} />
            </div>
        );
    } else if (marketName.includes("drawNoBet")) {
        return (
            <div className="closingContainer">
                {
                    closings.map(valuableSide => {
                        return <DrawNoBetClosing key={marketName + "-" + eventId + "-" + valuableSide} marketName={marketName} sport={sport} valuableSide={valuableSide} onOddUpdate={onOddUpdate} />
                    })
                }
            </div>
        );
    } else if (marketName.includes("doubleChance")) {
        return (
            <div className="closingContainer">
                {
                    closings.map(valuableSide => {
                        return <DoubleChanceClosing key={marketName + "-" + eventId + "-" + valuableSide} marketName={marketName} sport={sport} valuableSide={valuableSide} onOddUpdate={onOddUpdate} />
                    })
                }
            </div>
        );
    } else if (allTotalsMarkets.includes(marketName)) {
        return (
            <div className="closingContainer">
                {
                    closings.map(valuableSide => {
                        return <OverUnderClosing key={marketName + "-" + eventId + "-" + valuableSide} marketName={marketName} sport={sport} valuableSide={valuableSide} onOddUpdate={onOddUpdate} />
                    })
                }
            </div>
        );
    } else if (allTeamTotalsMarkets.includes(marketName)) {
        return (
            <div className="closingContainer">
                {
                    closings.map(valuableSide => {
                        return <TeamOverUnderClosing key={marketName + "-" + eventId + "-" + valuableSide} marketName={marketName} sport={sport} valuableSide={valuableSide} onOddUpdate={onOddUpdate} />
                    })
                }
            </div>
        );
    } else if (marketName.includes("handicap")) {
        return (
            <div className="closingContainer">
                {
                    closings.map(valuableSide => {
                        return <HandicapClosing key={marketName + "-" + eventId + "-" + valuableSide} marketName={marketName} sport={sport} valuableSide={valuableSide} onOddUpdate={onOddUpdate} />
                    })
                }
            </div>
        );
    } else if (marketName.startsWith("firstTeamToScore")) {
        return <FirstTeamToScoreClosing marketName={marketName} sport={sport} onOddUpdate={onOddUpdate} />;
    } else if (marketName.startsWith("bothTeamToScoreAndWinner")) {
        return <BothTeamToScoreAndWinnerClosing marketName={marketName} sport={sport} onOddUpdate={onOddUpdate} />;
    } else {
        return (
            <div className="closingContainer">{marketName}</div>
        );
    }
}

const MoneylineClosing = ({ sport, marketName, onOddUpdate }) => {
    const [home, setHome] = useState(0);
    const [draw, setDraw] = useState(0);
    const [away, setAway] = useState(0);

    const hasDrawOption = sport === "football" || (sport === "hockey" && marketName.includes("RegularTime"));

    useEffect(() => {
        onOddUpdate(marketName, hasDrawOption ? { "home": parseFloat(home), "draw": parseFloat(draw), "away": parseFloat(away) } : { "home": parseFloat(home), "away": parseFloat(away) });
    }, [home, draw, away]);

    return (
        <div className="formFieldsGroup">
            <FormField type="number" id="home" label={"Moneyline (" + getPrintablePeriod(sport, marketName) + ") - Home"} value={home} onChange={setHome} />
            {hasDrawOption && <FormField type="number" id="draw" label=" / Draw" value={draw} onChange={setDraw} />}
            <FormField type="number" id="away" label=" / Away" value={away} onChange={setAway} />
        </div>
    );
}

const DrawNoBetClosing = ({ sport, marketName, onOddUpdate }) => {
    const [home, setHome] = useState(0);
    const [away, setAway] = useState(0);

    useEffect(() => {
        onOddUpdate(marketName, { "home": parseFloat(home), "away": parseFloat(away) });
    }, [home, away]);

    return (
        <div className="formFieldsGroup">
            <FormField type="number" id="home" label={"DNB (" + getPrintablePeriod(sport, marketName) + ") - Home"} value={home} onChange={setHome} />
            <FormField type="number" id="away" label=" / Away" value={away} onChange={setAway} />
        </div>
    );
}

const DoubleChanceClosing = ({ sport, marketName, onOddUpdate }) => {
    const [homeDraw, setHomeDraw] = useState(0);
    const [awayDraw, setAwayDraw] = useState(0);
    const [homeAway, setHomeAway] = useState(0);

    useEffect(() => {
        onOddUpdate(marketName, { "[Home] Or [Draw]": parseFloat(homeDraw), "[Draw] Or [Away]": parseFloat(awayDraw), "[Home] Or [Away]": parseFloat(homeAway) });
    }, [homeDraw, awayDraw, homeAway]);

    return (
        <div className="formFieldsGroup">
            <FormField type="number" id="homeDraw" label={"DNB (" + getPrintablePeriod(sport, marketName) + ") - 1X"} value={homeDraw} onChange={setHomeDraw} />
            <FormField type="number" id="homeAway" label=" / 12" value={homeAway} onChange={setHomeAway} />
            <FormField type="number" id="awayDraw" label=" / X2" value={awayDraw} onChange={setAwayDraw} />
        </div>
    );
}

const OverUnderClosing = ({ sport, marketName, valuableSide, onOddUpdate }) => {
    const [over, setOver] = useState(0);
    const [under, setUnder] = useState(0);

    const [side, points] = valuableSide.split(" ");

    useEffect(() => {
        onOddUpdate(marketName, { "points": parseFloat(points), "over": parseFloat(over), "under": parseFloat(under) });
    }, [over, under]);

    return (
        <div className="formFieldsGroup">
            <FormField type="number" id="over" label={"O/U " + points + " (" + getPrintablePeriod(sport, marketName) + ") - Over"} value={over} onChange={setOver} />
            <FormField type="number" id="under" label=" / Under" value={under} onChange={setUnder} />
        </div>
    );
}

const TeamOverUnderClosing = ({ sport, marketName, valuableSide, onOddUpdate }) => {
    const [over, setOver] = useState(0);
    const [under, setUnder] = useState(0);

    const [team, side, points] = valuableSide.split(" ");

    useEffect(() => {
        onOddUpdate(marketName, { "points": parseFloat(points), "over": parseFloat(over), "under": parseFloat(under) }, team);
    }, [over, under]);

    return (
        <div className="formFieldsGroup">
            <FormField type="number" id="over" label={"O/U " + team + " " + points + " (" + getPrintablePeriod(sport, marketName) + ") - Over"} value={over} onChange={setOver} />
            <FormField type="number" id="under" label=" / Under" value={under} onChange={setUnder} />
        </div>
    );
}

const HandicapClosing = ({ sport, marketName, valuableSide, onOddUpdate }) => {
    const [home, setHome] = useState(0);
    const [away, setAway] = useState(0);

    const [team, handicap] = valuableSide.split(" ");

    useEffect(() => {
        if (team === "home") {
            onOddUpdate(marketName, { "points": parseFloat(handicap), "home": parseFloat(home) });
            onOddUpdate(marketName, { "points": parseFloat(handicap) * -1, "away": parseFloat(away) });
        } else {
            onOddUpdate(marketName, { "points": parseFloat(handicap), "away": parseFloat(away) });
            onOddUpdate(marketName, { "points": parseFloat(handicap) * -1, "home": parseFloat(home) });
        }
    }, [home, away]);

    return (
        <>
            <div className="formFieldsGroup">
                <FormField type="number" id="home" label={"Handicap " + (marketName.includes("Games") ? "jeux " : "") + "" + team + " " + handicap + " (" + getPrintablePeriod(sport, marketName) + ") - Home"} value={home} onChange={setHome} />
                <FormField type="number" id="away" label=" / Away" value={away} onChange={setAway} />
            </div>
        </>
    );
}

const FirstTeamToScoreClosing = ({ sport, marketName, onOddUpdate }) => {
    const [home, setHome] = useState(0);
    const [away, setAway] = useState(0);
    const [neither, setNeither] = useState(0);

    useEffect(() => {
        onOddUpdate(marketName, { "[Home]": parseFloat(home), "[Away]": parseFloat(away), "Neither": parseFloat(neither) });
    }, [home, away, neither]);

    return (
        <div className="formFieldsGroup">
            <label className="marketTitle">Première équipe à marquer</label>
            <FormField type="number" id="home" label="Home" value={home} onChange={setHome} />
            <FormField type="number" id="away" label="Away" value={away} onChange={setAway} />
            <FormField type="number" id="neither" label="Neither" value={neither} onChange={setNeither} />
        </div>
    );
}

const BothTeamToScoreAndWinnerClosing = ({ sport, marketName, onOddUpdate }) => {
    const [yesHome, setYesHome] = useState(0);
    const [noHome, setNoHome] = useState(0);
    const [yesAway, setYesAway] = useState(0);
    const [noAway, setNoAway] = useState(0);
    const [yesDraw, setYesDraw] = useState(0);
    const [noDraw, setNoDraw] = useState(0);

    useEffect(() => {
        onOddUpdate(marketName, {
            "Yes & [Home]": parseFloat(yesHome),
            "No & [Home]": parseFloat(noHome),
            "Yes & [Away]": parseFloat(yesAway),
            "No & [Away]": parseFloat(noAway),
            "Yes & [Draw]": parseFloat(yesDraw),
            "No & [Draw]": parseFloat(noDraw)
        });
    }, [yesHome, noHome, yesAway, noAway, yesDraw, noDraw]);

    return (
        <div className="formFieldsGroup">
            <label className="marketTitle">Les deux équipes marquent & Vainqueur</label>
            <FormField type="number" id="yesHome" label="Yes & Home" value={yesHome} onChange={setYesHome} />
            <FormField type="number" id="noHome" label="No & Home" value={noHome} onChange={setNoHome} />
            <FormField type="number" id="yesAway" label="Yes & Away" value={yesAway} onChange={setYesAway} />
            <FormField type="number" id="noAway" label="No & Away" value={noAway} onChange={setNoAway} />
            <FormField type="number" id="yesDraw" label="Yes & Draw" value={yesDraw} onChange={setYesDraw} />
            <FormField type="number" id="noDraw" label="No & Draw" value={noDraw} onChange={setNoDraw} />
        </div>
    );
}
//#endregion

//#region Results items
const ResultItem = ({ eventId, sport, results, onResultUpdate }) => {
    const onResultUpdateLocal = (newValue) => onResultUpdate(eventId, newValue);

    return (
        <div className="closingContainer">
            {
                results.includes("moneyline") && (
                    ["basketball", "americanFootball"].includes(sport) ? <BasicResultsWithQuarters sport={sport} onResultUpdate={onResultUpdateLocal} />
                        : ["tennis", "volleyball"].includes(sport) ? <BasicResultsWithSets sport={sport} onResultUpdate={onResultUpdateLocal} />
                            : ["hockey"].includes(sport) ? <BasicResultsWithPeriods sport={sport} onResultUpdate={onResultUpdateLocal} />
                                : ["baseball"].includes(sport) ? <BasicResultsWithInnings sport={sport} onResultUpdate={onResultUpdateLocal} />
                                    : <BasicResults sport={sport} onResultUpdate={onResultUpdateLocal} />
                )
            }
            {
                results.filter(r => r.includes("firstTeamToScore")).length > 0 && results.filter(r => r.includes("firstTeamToScore")).map(marketName => <FirstTeamToScoreResults key={marketName} sport={sport} marketName={marketName} onResultUpdate={onResultUpdateLocal} />)
            }
            {
                results.includes("totalsSacks") && <p>"Total sacks" non géré !</p>
            }
            {
                results.includes("totalsFieldGoalsMade") && <p>"Total field goals made" non géré !</p>
            }
        </div>
    );
}

const BasicResults = ({ sport, onResultUpdate }) => {
    const [totalsHomeFirstHalf, setTotalsHomeFirstHalf] = useState(0);
    const [totalsAwayFirstHalf, setTotalsAwayFirstHalf] = useState(0);
    const [totalsHomeSecondHalf, setTotalsHomeSecondHalf] = useState(0);
    const [totalsAwaySecondHalf, setTotalsAwaySecondHalf] = useState(0);

    useEffect(() => {
        const homeTotals = parseInt(totalsHomeFirstHalf) + parseInt(totalsHomeSecondHalf);
        const awayTotals = parseInt(totalsAwayFirstHalf) + parseInt(totalsAwaySecondHalf);
        const moneyline = homeTotals > awayTotals ? "home" : homeTotals < awayTotals ? "away" : "draw";
        const moneylineFirstHalf = totalsHomeFirstHalf > totalsAwayFirstHalf ? "home" : totalsHomeFirstHalf < totalsAwayFirstHalf ? "away" : "draw";
        const moneylineSecondHalf = totalsHomeSecondHalf > totalsAwaySecondHalf ? "home" : totalsHomeSecondHalf < totalsAwaySecondHalf ? "away" : "draw";

        onResultUpdate({
            "moneyline": moneyline,
            "totals": homeTotals + awayTotals,
            "teamTotals": { "home": homeTotals, "away": awayTotals },
            "teamToWinToNil": getTeamToWinNilSuccessOptions(moneyline, homeTotals, awayTotals),
            "oddEvenTotals": getOddEvenTotalsSuccessOptions(homeTotals + awayTotals),
            "oddEvenTeamTotals": getOddEvenTeamTotalsSuccessOptions(homeTotals, awayTotals),
            "teamToScore": getTeamToScoreSuccessOptions(homeTotals, awayTotals),
            "bothTeamToScore": getBothTeamToScoreSuccessOptions(homeTotals, awayTotals),
            "eitherTeamToScore": getEitherTeamToScoreSuccessOptions(homeTotals, awayTotals),

            "moneylineFirstHalf": moneylineFirstHalf,
            "totalsFirstHalf": parseInt(totalsHomeFirstHalf) + parseInt(totalsAwayFirstHalf),
            "teamTotalsFirstHalf": { "home": parseInt(totalsHomeFirstHalf), "away": parseInt(totalsAwayFirstHalf) },
            "teamToWinToNilFirstHalf": getTeamToWinNilSuccessOptions(moneylineFirstHalf, parseInt(totalsHomeFirstHalf), parseInt(totalsAwayFirstHalf)),
            "oddEvenTotalsFirstHalf": getOddEvenTotalsSuccessOptions(parseInt(totalsHomeFirstHalf) + parseInt(totalsAwayFirstHalf)),
            "oddEvenTeamTotalsFirstHalf": getOddEvenTeamTotalsSuccessOptions(parseInt(totalsHomeFirstHalf), parseInt(totalsAwayFirstHalf)),
            "teamToScoreFirstHalf": getTeamToScoreSuccessOptions(parseInt(totalsHomeFirstHalf), parseInt(totalsAwayFirstHalf)),
            "bothTeamToScoreFirstHalf": getBothTeamToScoreSuccessOptions(parseInt(totalsHomeFirstHalf), parseInt(totalsAwayFirstHalf)),
            "eitherTeamToScoreFirstHalf": getEitherTeamToScoreSuccessOptions(parseInt(totalsHomeFirstHalf), parseInt(totalsAwayFirstHalf)),

            "moneylineSecondHalf": moneylineSecondHalf,
            "totalsSecondHalf": parseInt(totalsHomeSecondHalf) + parseInt(totalsAwaySecondHalf),
            "teamTotalsSecondHalf": { "home": parseInt(totalsHomeSecondHalf), "away": parseInt(totalsAwaySecondHalf) },
        });
    }, [totalsHomeFirstHalf, totalsAwayFirstHalf, totalsHomeSecondHalf, totalsAwaySecondHalf]);

    return (
        <>
            <div className="formFieldsGroup">
                <FormField type="number" id="totalsHomeFirstHalf" label={"Totals (" + getPrintablePeriod(sport, "FirstHalf") + ") - Home"} value={totalsHomeFirstHalf} onChange={setTotalsHomeFirstHalf} />
                <FormField type="number" id="totalsAwayFirstHalf" label=" / Away" value={totalsAwayFirstHalf} onChange={setTotalsAwayFirstHalf} />
            </div>

            <div className="formFieldsGroup">
                <FormField type="number" id="totalsHomeSecondHalf" label={"Totals (" + getPrintablePeriod(sport, "SecondHalf") + ") - Home"} value={totalsHomeSecondHalf} onChange={setTotalsHomeSecondHalf} />
                <FormField type="number" id="totalsAwaySecondHalf" label=" / Away" value={totalsAwaySecondHalf} onChange={setTotalsAwaySecondHalf} />
            </div>
        </>
    );
}

const BasicResultsWithQuarters = ({ sport, onResultUpdate }) => {
    const [totalsHomeFirstQuarter, setTotalsHomeFirstQuarter] = useState(0);
    const [totalsAwayFirstQuarter, setTotalsAwayFirstQuarter] = useState(0);
    const [totalsHomeSecondQuarter, setTotalsHomeSecondQuarter] = useState(0);
    const [totalsAwaySecondQuarter, setTotalsAwaySecondQuarter] = useState(0);
    const [totalsHomeThirdQuarter, setTotalsHomeThirdQuarter] = useState(0);
    const [totalsAwayThirdQuarter, setTotalsAwayThirdQuarter] = useState(0);
    const [totalsHomeFourthQuarter, setTotalsHomeFourthQuarter] = useState(0);
    const [totalsAwayFourthQuarter, setTotalsAwayFourthQuarter] = useState(0);
    const [totalsHomeProlongations, setTotalsHomeProlongations] = useState(0);
    const [totalsAwayProlongations, setTotalsAwayProlongations] = useState(0);

    useEffect(() => {
        const homeTotalsFirstHalf = parseInt(totalsHomeFirstQuarter) + parseInt(totalsHomeSecondQuarter);
        const awayTotalsFirstHalf = parseInt(totalsAwayFirstQuarter) + parseInt(totalsAwaySecondQuarter);
        const homeTotalsSecondHalf = parseInt(totalsHomeThirdQuarter) + parseInt(totalsHomeFourthQuarter);
        const awayTotalsSecondHalf = parseInt(totalsAwayThirdQuarter) + parseInt(totalsAwayFourthQuarter);
        const homeTotals = homeTotalsFirstHalf + homeTotalsSecondHalf + parseInt(totalsHomeProlongations);
        const awayTotals = awayTotalsFirstHalf + awayTotalsSecondHalf + parseInt(totalsAwayProlongations);

        onResultUpdate({
            "moneyline": homeTotals > awayTotals ? "home" : homeTotals < awayTotals ? "away" : "draw",
            "totals": homeTotals + awayTotals,
            "teamTotals": { "home": homeTotals, "away": awayTotals },
            "oddEvenTotals": getOddEvenTotalsSuccessOptions(homeTotals + awayTotals),
            "oddEvenTeamTotals": getOddEvenTeamTotalsSuccessOptions(homeTotals, awayTotals),

            "moneylineFirstHalf": homeTotalsFirstHalf > awayTotalsFirstHalf ? "home" : homeTotalsFirstHalf < awayTotalsFirstHalf ? "away" : "draw",
            "totalsFirstHalf": homeTotalsFirstHalf + awayTotalsFirstHalf,
            "teamTotalsFirstHalf": { "home": homeTotalsFirstHalf, "away": awayTotalsFirstHalf },
            "oddEvenTotalsFirstHalf": getOddEvenTotalsSuccessOptions(homeTotalsFirstHalf + awayTotalsFirstHalf),
            "oddEvenTeamTotalsFirstHalf": getOddEvenTeamTotalsSuccessOptions(homeTotalsFirstHalf, awayTotalsFirstHalf),

            "moneylineSecondHalf": homeTotalsSecondHalf > awayTotalsSecondHalf ? "home" : homeTotalsSecondHalf < awayTotalsSecondHalf ? "away" : "draw",
            "totalsSecondHalf": homeTotalsSecondHalf + awayTotalsSecondHalf,
            "teamTotalsSecondHalf": { "home": homeTotalsSecondHalf, "away": awayTotalsSecondHalf },

            "moneylineFirstQuarter": parseInt(totalsHomeFirstQuarter) > parseInt(totalsAwayFirstQuarter) ? "home" : parseInt(totalsHomeFirstQuarter) < parseInt(totalsAwayFirstQuarter) ? "away" : "draw",
            "totalsFirstQuarter": parseInt(totalsHomeFirstQuarter) + parseInt(totalsAwayFirstQuarter),
            "teamTotalsFirstQuarter": { "home": parseInt(totalsHomeFirstQuarter), "away": parseInt(totalsAwayFirstQuarter) },

            "moneylineSecondQuarter": parseInt(totalsHomeSecondQuarter) > parseInt(totalsAwaySecondQuarter) ? "home" : parseInt(totalsHomeSecondQuarter) < parseInt(totalsAwaySecondQuarter) ? "away" : "draw",
            "totalsSecondQuarter": parseInt(totalsHomeSecondQuarter) + parseInt(totalsAwaySecondQuarter),
            "teamTotalsSecondQuarter": { "home": parseInt(totalsHomeSecondQuarter), "away": parseInt(totalsAwaySecondQuarter) },

            "moneylineThirdQuarter": parseInt(totalsHomeThirdQuarter) > parseInt(totalsAwayThirdQuarter) ? "home" : parseInt(totalsHomeThirdQuarter) < parseInt(totalsAwayThirdQuarter) ? "away" : "draw",
            "totalsThirdQuarter": parseInt(totalsHomeThirdQuarter) + parseInt(totalsAwayThirdQuarter),
            "teamTotalsThirdQuarter": { "home": parseInt(totalsHomeThirdQuarter), "away": parseInt(totalsAwayThirdQuarter) },

            "moneylineFourthQuarter": parseInt(totalsHomeFourthQuarter) > parseInt(totalsAwayFourthQuarter) ? "home" : parseInt(totalsHomeFourthQuarter) < parseInt(totalsAwayFourthQuarter) ? "away" : "draw",
            "totalsFourthQuarter": parseInt(totalsHomeFourthQuarter) + parseInt(totalsAwayFourthQuarter),
            "teamTotalsFourthQuarter": { "home": parseInt(totalsHomeFourthQuarter), "away": parseInt(totalsAwayFourthQuarter) }
        });
    }, [totalsHomeFirstQuarter, totalsAwayFirstQuarter, totalsHomeSecondQuarter, totalsAwaySecondQuarter, totalsHomeThirdQuarter, totalsAwayThirdQuarter, totalsHomeFourthQuarter, totalsAwayFourthQuarter, totalsHomeProlongations, totalsAwayProlongations]);
    return (
        <>
            <div className="formFieldsGroup">
                <FormField type="number" id="totalsHomeFirstQuarter" label={"Totals (" + getPrintablePeriod(sport, "FirstQuarter") + ") - Home"} value={totalsHomeFirstQuarter} onChange={setTotalsHomeFirstQuarter} />
                <FormField type="number" id="totalsAwayFirstQuarter" label=" / Away" value={totalsAwayFirstQuarter} onChange={setTotalsAwayFirstQuarter} />
            </div>

            <div className="formFieldsGroup">
                <FormField type="number" id="totalsHomeSecondQuarter" label={"Totals (" + getPrintablePeriod(sport, "SecondQuarter") + ") - Home"} value={totalsHomeSecondQuarter} onChange={setTotalsHomeSecondQuarter} />
                <FormField type="number" id="totalsAwaySecondQuarter" label=" / Away" value={totalsAwaySecondQuarter} onChange={setTotalsAwaySecondQuarter} />
            </div>

            <div className="formFieldsGroup">
                <FormField type="number" id="totalsHomeThirdQuarter" label={"Totals (" + getPrintablePeriod(sport, "ThirdQuarter") + ") - Home"} value={totalsHomeThirdQuarter} onChange={setTotalsHomeThirdQuarter} />
                <FormField type="number" id="totalsAwayThirdQuarter" label=" / Away" value={totalsAwayThirdQuarter} onChange={setTotalsAwayThirdQuarter} />
            </div>

            <div className="formFieldsGroup">
                <FormField type="number" id="totalsHomeFourthQuarter" label={"Totals (" + getPrintablePeriod(sport, "FourthQuarter") + ") - Home"} value={totalsHomeFourthQuarter} onChange={setTotalsHomeFourthQuarter} />
                <FormField type="number" id="totalsAwayFourthQuarter" label=" / Away" value={totalsAwayFourthQuarter} onChange={setTotalsAwayFourthQuarter} />
            </div>

            <div className="formFieldsGroup">
                <FormField type="number" id="totalsHomeProlongations" label="Totals (prolongations) - Home" value={totalsHomeProlongations} onChange={setTotalsHomeProlongations} />
                <FormField type="number" id="totalsAwayProlongations" label=" / Away" value={totalsAwayProlongations} onChange={setTotalsAwayProlongations} />
            </div>
        </>
    );
}

const BasicResultsWithSets = ({ sport, onResultUpdate }) => {
    const [totalsHomeFirstSet, setTotalsHomeFirstSet] = useState(0);
    const [totalsAwayFirstSet, setTotalsAwayFirstSet] = useState(0);
    const [totalsHomeSecondSet, setTotalsHomeSecondSet] = useState(0);
    const [totalsAwaySecondSet, setTotalsAwaySecondSet] = useState(0);
    const [totalsHomeThirdSet, setTotalsHomeThirdSet] = useState(0);
    const [totalsAwayThirdSet, setTotalsAwayThirdSet] = useState(0);
    const [totalsHomeFourthSet, setTotalsHomeFourthSet] = useState(0);
    const [totalsAwayFourthSet, setTotalsAwayFourthSet] = useState(0);
    const [totalsHomeFifthSet, setTotalsHomeFifthSet] = useState(0);
    const [totalsAwayFifthSet, setTotalsAwayFifthSet] = useState(0);

    useEffect(() => {
        let toUpdate = {};

        let homeTotals = 0, awayTotals = 0;
        parseInt(totalsHomeFirstSet) > parseInt(totalsAwayFirstSet) ? homeTotals += 1 : awayTotals += 1;
        parseInt(totalsHomeSecondSet) > parseInt(totalsAwaySecondSet) ? homeTotals += 1 : awayTotals += 1;
        if (parseInt(totalsHomeThirdSet) !== 0 || parseInt(totalsAwayThirdSet) !== 0) {
            parseInt(totalsHomeThirdSet) > parseInt(totalsAwayThirdSet) ? homeTotals += 1 : awayTotals += 1;
        }
        if (parseInt(totalsHomeFourthSet) !== 0 || parseInt(totalsAwayFourthSet) !== 0) {
            parseInt(totalsHomeFourthSet) > parseInt(totalsAwayFourthSet) ? homeTotals += 1 : awayTotals += 1;
        }
        if (parseInt(totalsHomeFifthSet) !== 0 || parseInt(totalsAwayFifthSet) !== 0) {
            parseInt(totalsHomeFifthSet) > parseInt(totalsAwayFifthSet) ? homeTotals += 1 : awayTotals += 1;
        }
        toUpdate.teamTotals = { "home": homeTotals, "away": awayTotals };
        toUpdate.totals = homeTotals + awayTotals;
        toUpdate.moneyline = homeTotals > awayTotals ? "home" : "away";

        toUpdate.moneylineFirstSet = parseInt(totalsHomeFirstSet) > parseInt(totalsAwayFirstSet) ? "home" : "away";
        toUpdate.moneylineSecondSet = parseInt(totalsHomeSecondSet) > parseInt(totalsAwaySecondSet) ? "home" : "away";
        if (parseInt(totalsHomeThirdSet) !== 0 || parseInt(totalsAwayThirdSet) !== 0) {
            toUpdate.moneylineThirdSet = parseInt(totalsHomeThirdSet) > parseInt(totalsAwayThirdSet) ? "home" : "away";
        }
        if (parseInt(totalsHomeFourthSet) !== 0 || parseInt(totalsAwayFourthSet) !== 0) {
            toUpdate.moneylineFourthSet = parseInt(totalsHomeFourthSet) > parseInt(totalsAwayFourthSet) ? "home" : "away";
        }
        if (parseInt(totalsHomeFifthSet) !== 0 || parseInt(totalsAwayFifthSet) !== 0) {
            toUpdate.moneylineFifthSet = parseInt(totalsHomeFifthSet) > parseInt(totalsAwayFifthSet) ? "home" : "away";
        }

        if (sport === "tennis") {
            toUpdate.totalsGames = parseInt(totalsHomeFirstSet) + parseInt(totalsHomeSecondSet) + parseInt(totalsHomeThirdSet) + parseInt(totalsHomeFourthSet) + parseInt(totalsHomeFifthSet) + parseInt(totalsAwayFirstSet) + parseInt(totalsAwaySecondSet) + parseInt(totalsAwayThirdSet) + parseInt(totalsAwayFourthSet) + parseInt(totalsAwayFifthSet);
            toUpdate.teamTotalsGames = { "home": parseInt(totalsHomeFirstSet) + parseInt(totalsHomeSecondSet) + parseInt(totalsHomeThirdSet) + parseInt(totalsHomeFourthSet) + parseInt(totalsHomeFifthSet), "away": parseInt(totalsAwayFirstSet) + parseInt(totalsAwaySecondSet) + parseInt(totalsAwayThirdSet) + parseInt(totalsAwayFourthSet) + parseInt(totalsAwayFifthSet) };

            toUpdate.totalsGamesFirstSet = parseInt(totalsHomeFirstSet) + parseInt(totalsAwayFirstSet);
            toUpdate.teamTotalsGamesFirstSet = { "home": parseInt(totalsHomeFirstSet), "away": parseInt(totalsAwayFirstSet) };

            toUpdate.totalsGamesSecondSet = parseInt(totalsHomeSecondSet) + parseInt(totalsAwaySecondSet);
            toUpdate.teamTotalsGamesSecondSet = { "home": parseInt(totalsHomeSecondSet), "away": parseInt(totalsAwaySecondSet) };

            if (parseInt(totalsHomeThirdSet) !== 0 || parseInt(totalsAwayThirdSet) !== 0) {
                toUpdate.totalsGamesThirdSet = parseInt(totalsHomeThirdSet) + parseInt(totalsAwayThirdSet);
                toUpdate.teamTotalsGamesThirdSet = { "home": parseInt(totalsHomeThirdSet), "away": parseInt(totalsAwayThirdSet) };
            }

            if (parseInt(totalsHomeFourthSet) !== 0 || parseInt(totalsAwayFourthSet) !== 0) {
                toUpdate.totalsGamesFourthSet = parseInt(totalsHomeFourthSet) + parseInt(totalsAwayFourthSet);
                toUpdate.teamTotalsGamesFourthSet = { "home": parseInt(totalsHomeFourthSet), "away": parseInt(totalsAwayFourthSet) };
            }

            if (parseInt(totalsHomeFifthSet) !== 0 || parseInt(totalsAwayFifthSet) !== 0) {
                toUpdate.totalsGamesFifthSet = parseInt(totalsHomeFifthSet) + parseInt(totalsAwayFifthSet);
                toUpdate.teamTotalsGamesFifthSet = { "home": parseInt(totalsHomeFifthSet), "away": parseInt(totalsAwayFifthSet) };
            }
        }

        if (sport === "volleyball") {
            toUpdate.moneylinePointsFirstSet = toUpdate.moneylineFirstSet;
            toUpdate.moneylinePointsSecondSet = toUpdate.moneylineSecondSet;
            if (parseInt(totalsHomeThirdSet) !== 0 || parseInt(totalsAwayThirdSet) !== 0) {
                toUpdate.moneylinePointsThirdSet = toUpdate.moneylineThirdSet;
            }
            if (parseInt(totalsHomeFourthSet) !== 0 || parseInt(totalsAwayFourthSet) !== 0) {
                toUpdate.moneylinePointsFourthSet = toUpdate.moneylineFourthSet;
            }
            if (parseInt(totalsHomeFifthSet) !== 0 || parseInt(totalsAwayFifthSet) !== 0) {
                toUpdate.moneylinePointsFifthSet = toUpdate.moneylineFifthSet;
            }

            toUpdate.totalsPoints = parseInt(totalsHomeFirstSet) + parseInt(totalsHomeSecondSet) + parseInt(totalsHomeThirdSet) + parseInt(totalsHomeFourthSet) + parseInt(totalsHomeFifthSet) + parseInt(totalsAwayFirstSet) + parseInt(totalsAwaySecondSet) + parseInt(totalsAwayThirdSet) + parseInt(totalsAwayFourthSet) + parseInt(totalsAwayFifthSet);
            toUpdate.teamTotalsPoints = { "home": parseInt(totalsHomeFirstSet) + parseInt(totalsHomeSecondSet) + parseInt(totalsHomeThirdSet) + parseInt(totalsHomeFourthSet) + parseInt(totalsHomeFifthSet), "away": parseInt(totalsAwayFirstSet) + parseInt(totalsAwaySecondSet) + parseInt(totalsAwayThirdSet) + parseInt(totalsAwayFourthSet) + parseInt(totalsAwayFifthSet) };

            toUpdate.totalsPointsFirstSet = parseInt(totalsHomeFirstSet) + parseInt(totalsAwayFirstSet);
            toUpdate.teamTotalsPointsFirstSet = { "home": parseInt(totalsHomeFirstSet), "away": parseInt(totalsAwayFirstSet) };

            toUpdate.totalsPointsSecondSet = parseInt(totalsHomeSecondSet) + parseInt(totalsAwaySecondSet);
            toUpdate.teamTotalsPointsSecondSet = { "home": parseInt(totalsHomeSecondSet), "away": parseInt(totalsAwaySecondSet) };

            toUpdate.totalsPointsThirdSet = parseInt(totalsHomeThirdSet) + parseInt(totalsAwayThirdSet);
            toUpdate.teamTotalsPointsThirdSet = { "home": parseInt(totalsHomeThirdSet), "away": parseInt(totalsAwayThirdSet) };

            if (parseInt(totalsHomeFourthSet) !== 0 || parseInt(totalsAwayFourthSet) !== 0) {
                toUpdate.totalsPointsFourthSet = parseInt(totalsHomeFourthSet) + parseInt(totalsAwayFourthSet);
                toUpdate.teamTotalsPointsFourthSet = { "home": parseInt(totalsHomeFourthSet), "away": parseInt(totalsAwayFourthSet) };
            }

            if (parseInt(totalsHomeFifthSet) !== 0 || parseInt(totalsAwayFifthSet) !== 0) {
                toUpdate.totalsPointsFifthSet = parseInt(totalsHomeFifthSet) + parseInt(totalsAwayFifthSet);
                toUpdate.teamTotalsPointsFifthSet = { "home": parseInt(totalsHomeFifthSet), "away": parseInt(totalsAwayFifthSet) };
            }
        }

        onResultUpdate(toUpdate);
    }, [totalsHomeFirstSet, totalsAwayFirstSet, totalsHomeSecondSet, totalsAwaySecondSet, totalsHomeThirdSet, totalsAwayThirdSet, totalsHomeFourthSet, totalsAwayFourthSet, totalsHomeFifthSet, totalsAwayFifthSet]);
    return (
        <>
            <div className="formFieldsGroup">
                <FormField type="number" id="totalsHomeFirstSet" label={"Totals (" + getPrintablePeriod(sport, "FirstSet") + ") - Home"} value={totalsHomeFirstSet} onChange={setTotalsHomeFirstSet} />
                <FormField type="number" id="totalsAwayFirstSet" label=" / Away" value={totalsAwayFirstSet} onChange={setTotalsAwayFirstSet} />
            </div>

            <div className="formFieldsGroup">
                <FormField type="number" id="totalsHomeSecondSet" label={"Totals (" + getPrintablePeriod(sport, "SecondSet") + ") - Home"} value={totalsHomeSecondSet} onChange={setTotalsHomeSecondSet} />
                <FormField type="number" id="totalsAwaySecondSet" label=" / Away" value={totalsAwaySecondSet} onChange={setTotalsAwaySecondSet} />
            </div>

            <div className="formFieldsGroup">
                <FormField type="number" id="totalsHomeThirdSet" label={"Totals (" + getPrintablePeriod(sport, "ThirdSet") + ") - Home"} value={totalsHomeThirdSet} onChange={setTotalsHomeThirdSet} />
                <FormField type="number" id="totalsAwayThirdSet" label=" / Away" value={totalsAwayThirdSet} onChange={setTotalsAwayThirdSet} />
            </div>

            <div className="formFieldsGroup">
                <FormField type="number" id="totalsHomeFourthSet" label={"Totals (" + getPrintablePeriod(sport, "FourthSet") + ") - Home"} value={totalsHomeFourthSet} onChange={setTotalsHomeFourthSet} />
                <FormField type="number" id="totalsAwayFourthSet" label=" / Away" value={totalsAwayFourthSet} onChange={setTotalsAwayFourthSet} />
            </div>

            <div className="formFieldsGroup">
                <FormField type="number" id="totalsHomeFifthSet" label={"Totals (" + getPrintablePeriod(sport, "FifthSet") + ") - Home"} value={totalsHomeFifthSet} onChange={setTotalsHomeFifthSet} />
                <FormField type="number" id="totalsAwayFifthSet" label=" / Away" value={totalsAwayFifthSet} onChange={setTotalsAwayFifthSet} />
            </div>
        </>
    );
}

const BasicResultsWithPeriods = ({ sport, onResultUpdate }) => {
    const [totalsHomeFirstPeriod, setTotalsHomeFirstPeriod] = useState(0);
    const [totalsAwayFirstPeriod, setTotalsAwayFirstPeriod] = useState(0);
    const [totalsHomeSecondPeriod, setTotalsHomeSecondPeriod] = useState(0);
    const [totalsAwaySecondPeriod, setTotalsAwaySecondPeriod] = useState(0);
    const [totalsHomeThirdPeriod, setTotalsHomeThirdPeriod] = useState(0);
    const [totalsAwayThirdPeriod, setTotalsAwayThirdPeriod] = useState(0);
    const [totalsHomeProlongations, setTotalsHomeProlongations] = useState(0);
    const [totalsAwayProlongations, setTotalsAwayProlongations] = useState(0);

    useEffect(() => {
        const homeTotalsRegularTime = parseInt(totalsHomeFirstPeriod) + parseInt(totalsHomeSecondPeriod) + parseInt(totalsHomeThirdPeriod);
        const homeTotals = homeTotalsRegularTime + parseInt(totalsHomeProlongations);
        const awayTotalsRegularTime = parseInt(totalsAwayFirstPeriod) + parseInt(totalsAwaySecondPeriod) + parseInt(totalsAwayThirdPeriod);
        const awayTotals = awayTotalsRegularTime + parseInt(totalsAwayProlongations);
        const moneyline = homeTotals > awayTotals ? "home" : homeTotals < awayTotals ? "away" : "draw";

        onResultUpdate({
            "moneyline": moneyline,
            "totals": homeTotals + awayTotals,
            "teamTotals": { "home": homeTotals, "away": awayTotals },
            "teamToWinToNil": getTeamToWinNilSuccessOptions(moneyline, homeTotals, awayTotals),
            "teamToScore": getTeamToScoreSuccessOptions(homeTotals, awayTotals),

            "moneylineFirstPeriod": parseInt(totalsHomeFirstPeriod) > parseInt(totalsAwayFirstPeriod) ? "home" : parseInt(totalsHomeFirstPeriod) < parseInt(totalsAwayFirstPeriod) ? "away" : "draw",
            "totalsFirstPeriod": parseInt(totalsHomeFirstPeriod) + parseInt(totalsAwayFirstPeriod),
            "teamTotalsFirstPeriod": { "home": parseInt(totalsHomeFirstPeriod), "away": parseInt(totalsAwayFirstPeriod) },

            "moneylineSecondPeriod": parseInt(totalsHomeSecondPeriod) > parseInt(totalsAwaySecondPeriod) ? "home" : parseInt(totalsHomeSecondPeriod) < parseInt(totalsAwaySecondPeriod) ? "away" : "draw",
            "totalsSecondPeriod": parseInt(totalsHomeSecondPeriod) + parseInt(totalsAwaySecondPeriod),
            "teamTotalsSecondPeriod": { "home": parseInt(totalsHomeSecondPeriod), "away": parseInt(totalsAwaySecondPeriod) },

            "moneylineThirdPeriod": parseInt(totalsHomeThirdPeriod) > parseInt(totalsAwayThirdPeriod) ? "home" : parseInt(totalsHomeThirdPeriod) < parseInt(totalsAwayThirdPeriod) ? "away" : "draw",
            "totalsThirdPeriod": parseInt(totalsHomeThirdPeriod) + parseInt(totalsAwayThirdPeriod),
            "teamTotalsThirdPeriod": { "home": parseInt(totalsHomeThirdPeriod), "away": parseInt(totalsAwayThirdPeriod) },

            "moneylineRegularTime": homeTotalsRegularTime > awayTotalsRegularTime ? "home" : homeTotalsRegularTime < awayTotalsRegularTime ? "away" : "draw",
            "totalsRegularTime": homeTotalsRegularTime + awayTotalsRegularTime,
            "teamTotalsRegularTime": { "home": homeTotalsRegularTime, "away": awayTotalsRegularTime }
        });
    }, [totalsHomeFirstPeriod, totalsAwayFirstPeriod, totalsHomeSecondPeriod, totalsAwaySecondPeriod, totalsHomeThirdPeriod, totalsAwayThirdPeriod, totalsHomeProlongations, totalsAwayProlongations]);

    return (
        <>
            <div className="formFieldsGroup">
                <FormField type="number" id="totalsHomeFirstPeriod" label={"Totals (" + getPrintablePeriod(sport, "FirstPeriod") + ") - Home"} value={totalsHomeFirstPeriod} onChange={setTotalsHomeFirstPeriod} />
                <FormField type="number" id="totalsAwayFirstPeriod" label=" / Away" value={totalsAwayFirstPeriod} onChange={setTotalsAwayFirstPeriod} />
            </div>

            <div className="formFieldsGroup">
                <FormField type="number" id="totalsHomeSecondPeriod" label={"Totals (" + getPrintablePeriod(sport, "SecondPeriod") + ") - Home"} value={totalsHomeSecondPeriod} onChange={setTotalsHomeSecondPeriod} />
                <FormField type="number" id="totalsAwaySecondPeriod" label=" / Away" value={totalsAwaySecondPeriod} onChange={setTotalsAwaySecondPeriod} />
            </div>

            <div className="formFieldsGroup">
                <FormField type="number" id="totalsHomeThirdPeriod" label={"Totals (" + getPrintablePeriod(sport, "ThirdPeriod") + ") - Home"} value={totalsHomeThirdPeriod} onChange={setTotalsHomeThirdPeriod} />
                <FormField type="number" id="totalsAwayThirdPeriod" label=" / Away" value={totalsAwayThirdPeriod} onChange={setTotalsAwayThirdPeriod} />
            </div>

            <div className="formFieldsGroup">
                <FormField type="number" id="totalsHomeProlongations" label="Totals (prolongations) - Home" value={totalsHomeProlongations} onChange={setTotalsHomeProlongations} />
                <FormField type="number" id="totalsAwayProlongations" label=" / Away" value={totalsAwayProlongations} onChange={setTotalsAwayProlongations} />
            </div>
        </>
    );
}

const BasicResultsWithInnings = ({ sport, onResultUpdate }) => {
    const [totalsHomeFirstInning, setTotalsHomeFirstInning] = useState(0);
    const [totalsAwayFirstInning, setTotalsAwayFirstInning] = useState(0);
    const [totalsHomeSecondInning, setTotalsHomeSecondInning] = useState(0);
    const [totalsAwaySecondInning, setTotalsAwaySecondInning] = useState(0);
    const [totalsHomeThirdInning, setTotalsHomeThirdInning] = useState(0);
    const [totalsAwayThirdInning, setTotalsAwayThirdInning] = useState(0);
    const [totalsHomeFourthInning, setTotalsHomeFourthInning] = useState(0);
    const [totalsAwayFourthInning, setTotalsAwayFourthInning] = useState(0);
    const [totalsHomeFifthInning, setTotalsHomeFifthInning] = useState(0);
    const [totalsAwayFifthInning, setTotalsAwayFifthInning] = useState(0);
    const [totalsHomeSixthInning, setTotalsHomeSixthInning] = useState(0);
    const [totalsAwaySixthInning, setTotalsAwaySixthInning] = useState(0);
    const [totalsHomeSeventhInning, setTotalsHomeSeventhInning] = useState(0);
    const [totalsAwaySeventhInning, setTotalsAwaySeventhInning] = useState(0);
    const [totalsHomeEighthInning, setTotalsHomeEighthInning] = useState(0);
    const [totalsAwayEighthInning, setTotalsAwayEighthInning] = useState(0);
    const [totalsHomeNinthInning, setTotalsHomeNinthInning] = useState(0);
    const [totalsAwayNinthInning, setTotalsAwayNinthInning] = useState(0);

    useEffect(() => {
        const homeTotalsFirstHalf = parseInt(totalsHomeFirstInning) + parseInt(totalsHomeSecondInning) + parseInt(totalsHomeThirdInning) + parseInt(totalsHomeFourthInning) + parseInt(totalsHomeFifthInning);
        const awayTotalsFirstHalf = parseInt(totalsAwayFirstInning) + parseInt(totalsAwaySecondInning) + parseInt(totalsAwayThirdInning) + parseInt(totalsAwayFourthInning) + parseInt(totalsAwayFifthInning);
        const homeTotalsSecondHalf = parseInt(totalsHomeSixthInning) + parseInt(totalsHomeSeventhInning) + parseInt(totalsHomeEighthInning);
        const awayTotalsSecondHalf = parseInt(totalsAwaySixthInning) + parseInt(totalsAwaySeventhInning) + parseInt(totalsAwayEighthInning);
        const homeTotals = homeTotalsFirstHalf + homeTotalsSecondHalf + parseInt(totalsHomeNinthInning);
        const awayTotals = awayTotalsFirstHalf + awayTotalsSecondHalf + parseInt(totalsAwayNinthInning);

        onResultUpdate({
            "moneyline": homeTotals > awayTotals ? "home" : homeTotals < awayTotals ? "away" : "draw",
            "totals": homeTotals + awayTotals,
            "teamTotals": { "home": homeTotals, "away": awayTotals },

            "moneylineFirstHalf": homeTotalsFirstHalf > awayTotalsFirstHalf ? "home" : homeTotalsFirstHalf < awayTotalsFirstHalf ? "away" : "draw",
            "totalsFirstHalf": homeTotalsFirstHalf + awayTotalsFirstHalf,
            "teamTotalsFirstHalf": { "home": homeTotalsFirstHalf, "away": awayTotalsFirstHalf },

            "moneylineSecondHalf": homeTotalsSecondHalf > awayTotalsSecondHalf ? "home" : homeTotalsSecondHalf < awayTotalsSecondHalf ? "away" : "draw",
            "totalsSecondHalf": homeTotalsSecondHalf + awayTotalsSecondHalf,
            "teamTotalsSecondHalf": { "home": homeTotalsSecondHalf, "away": awayTotalsSecondHalf },

            "moneylineFirstInning": parseInt(totalsHomeFirstInning) > parseInt(totalsAwayFirstInning) ? "home" : parseInt(totalsHomeFirstInning) < parseInt(totalsAwayFirstInning) ? "away" : "draw",
            "totalsFirstInning": parseInt(totalsHomeFirstInning) + parseInt(totalsAwayFirstInning),
            "teamTotalsFirstInning": { "home": parseInt(totalsHomeFirstInning), "away": parseInt(totalsAwayFirstInning) },

            "moneylineSecondInning": parseInt(totalsHomeSecondInning) > parseInt(totalsAwaySecondInning) ? "home" : parseInt(totalsHomeSecondInning) < parseInt(totalsAwaySecondInning) ? "away" : "draw",
            "totalsSecondInning": parseInt(totalsHomeSecondInning) + parseInt(totalsAwaySecondInning),
            "teamTotalsSecondInning": { "home": parseInt(totalsHomeSecondInning), "away": parseInt(totalsAwaySecondInning) },

            "moneylineThirdInning": parseInt(totalsHomeThirdInning) > parseInt(totalsAwayThirdInning) ? "home" : parseInt(totalsHomeThirdInning) < parseInt(totalsAwayThirdInning) ? "away" : "draw",
            "totalsThirdInning": parseInt(totalsHomeThirdInning) + parseInt(totalsAwayThirdInning),
            "teamTotalsThirdInning": { "home": parseInt(totalsHomeThirdInning), "away": parseInt(totalsAwayThirdInning) },

            "moneylineFourthInning": parseInt(totalsHomeFourthInning) > parseInt(totalsAwayFourthInning) ? "home" : parseInt(totalsHomeFourthInning) < parseInt(totalsAwayFourthInning) ? "away" : "draw",
            "totalsFourthInning": parseInt(totalsHomeFourthInning) + parseInt(totalsAwayFourthInning),
            "teamTotalsFourthInning": { "home": parseInt(totalsHomeFourthInning), "away": parseInt(totalsAwayFourthInning) },

            "moneylineFifthInning": parseInt(totalsHomeFifthInning) > parseInt(totalsAwayFifthInning) ? "home" : parseInt(totalsHomeFifthInning) < parseInt(totalsAwayFifthInning) ? "away" : "draw",
            "totalsFifthInning": parseInt(totalsHomeFifthInning) + parseInt(totalsAwayFifthInning),
            "teamTotalsFifthInning": { "home": parseInt(totalsHomeFifthInning), "away": parseInt(totalsAwayFifthInning) },

            "moneylineSixthInning": parseInt(totalsHomeSixthInning) > parseInt(totalsAwaySixthInning) ? "home" : parseInt(totalsHomeSixthInning) < parseInt(totalsAwaySixthInning) ? "away" : "draw",
            "totalsSixthInning": parseInt(totalsHomeSixthInning) + parseInt(totalsAwaySixthInning),
            "teamTotalsSixthInning": { "home": parseInt(totalsHomeSixthInning), "away": parseInt(totalsAwaySixthInning) },

            "moneylineSeventhInning": parseInt(totalsHomeSeventhInning) > parseInt(totalsAwaySeventhInning) ? "home" : parseInt(totalsHomeSeventhInning) < parseInt(totalsAwaySeventhInning) ? "away" : "draw",
            "totalsSeventhInning": parseInt(totalsHomeSeventhInning) + parseInt(totalsAwaySeventhInning),
            "teamTotalsSeventhInning": { "home": parseInt(totalsHomeSeventhInning), "away": parseInt(totalsAwaySeventhInning) },

            "moneylineEighthInning": parseInt(totalsHomeEighthInning) > parseInt(totalsAwayEighthInning) ? "home" : parseInt(totalsHomeEighthInning) < parseInt(totalsAwayEighthInning) ? "away" : "draw",
            "totalsEighthInning": parseInt(totalsHomeEighthInning) + parseInt(totalsAwayEighthInning),
            "teamTotalsEighthInning": { "home": parseInt(totalsHomeEighthInning), "away": parseInt(totalsAwayEighthInning) }
        });
    }, [totalsHomeFirstInning, totalsAwayFirstInning, totalsHomeSecondInning, totalsAwaySecondInning, totalsHomeThirdInning, totalsAwayThirdInning, totalsHomeFourthInning, totalsAwayFourthInning, totalsHomeFifthInning, totalsAwayFifthInning, totalsHomeSixthInning, totalsAwaySixthInning, totalsHomeSeventhInning, totalsAwaySeventhInning, totalsHomeEighthInning, totalsAwayEighthInning, totalsHomeNinthInning, totalsAwayNinthInning]);

    return (
        <>
            <div className="formFieldsGroup">
                <FormField type="number" id="totalsHomeFirstInning" label={"Totals (" + getPrintablePeriod(sport, "FirstInning") + ") - Home"} value={totalsHomeFirstInning} onChange={setTotalsHomeFirstInning} />
                <FormField type="number" id="totalsAwayFirstInning" label=" / Away" value={totalsAwayFirstInning} onChange={setTotalsAwayFirstInning} />
            </div>

            <div className="formFieldsGroup">
                <FormField type="number" id="totalsHomeSecondInning" label={"Totals (" + getPrintablePeriod(sport, "SecondInning") + ") - Home"} value={totalsHomeSecondInning} onChange={setTotalsHomeSecondInning} />
                <FormField type="number" id="totalsAwaySecondInning" label=" / Away" value={totalsAwaySecondInning} onChange={setTotalsAwaySecondInning} />
            </div>

            <div className="formFieldsGroup">
                <FormField type="number" id="totalsHomeThirdInning" label={"Totals (" + getPrintablePeriod(sport, "ThirdInning") + ") - Home"} value={totalsHomeThirdInning} onChange={setTotalsHomeThirdInning} />
                <FormField type="number" id="totalsAwayThirdInning" label=" / Away" value={totalsAwayThirdInning} onChange={setTotalsAwayThirdInning} />
            </div>

            <div className="formFieldsGroup">
                <FormField type="number" id="totalsHomeFourthInning" label={"Totals (" + getPrintablePeriod(sport, "FourthInning") + ") - Home"} value={totalsHomeFourthInning} onChange={setTotalsHomeFourthInning} />
                <FormField type="number" id="totalsAwayFourthInning" label=" / Away" value={totalsAwayFourthInning} onChange={setTotalsAwayFourthInning} />
            </div>

            <div className="formFieldsGroup">
                <FormField type="number" id="totalsHomeFifthInning" label={"Totals (" + getPrintablePeriod(sport, "FifthInning") + ") - Home"} value={totalsHomeFifthInning} onChange={setTotalsHomeFifthInning} />
                <FormField type="number" id="totalsAwayFifthInning" label=" / Away" value={totalsAwayFifthInning} onChange={setTotalsAwayFifthInning} />
            </div>

            <div className="formFieldsGroup">
                <FormField type="number" id="totalsHomeSixthInning" label={"Totals (" + getPrintablePeriod(sport, "SixthInning") + ") - Home"} value={totalsHomeSixthInning} onChange={setTotalsHomeSixthInning} />
                <FormField type="number" id="totalsAwaySixthInning" label=" / Away" value={totalsAwaySixthInning} onChange={setTotalsAwaySixthInning} />
            </div>

            <div className="formFieldsGroup">
                <FormField type="number" id="totalsHomeSeventhInning" label={"Totals (" + getPrintablePeriod(sport, "SeventhInning") + ") - Home"} value={totalsHomeSeventhInning} onChange={setTotalsHomeSeventhInning} />
                <FormField type="number" id="totalsAwaySeventhInning" label=" / Away" value={totalsAwaySeventhInning} onChange={setTotalsAwaySeventhInning} />
            </div>

            <div className="formFieldsGroup">
                <FormField type="number" id="totalsHomeEighthInning" label={"Totals (" + getPrintablePeriod(sport, "EighthInning") + ") - Home"} value={totalsHomeEighthInning} onChange={setTotalsHomeEighthInning} />
                <FormField type="number" id="totalsAwayEighthInning" label=" / Away" value={totalsAwayEighthInning} onChange={setTotalsAwayEighthInning} />
            </div>

            <div className="formFieldsGroup">
                <FormField type="number" id="totalsHomeNinthInning" label={"Totals (" + getPrintablePeriod(sport, "NinthInning") + ") - Home"} value={totalsHomeNinthInning} onChange={setTotalsHomeNinthInning} />
                <FormField type="number" id="totalsAwayNinthInning" label=" / Away" value={totalsAwayNinthInning} onChange={setTotalsAwayNinthInning} />
            </div>
        </>
    );
}

const FirstTeamToScoreResults = ({ sport, marketName, onResultUpdate }) => {
    const [firstTeamToScore, setFirstTeamToScore] = useState(0);

    const allOptions = [
        { value: "", label: "" },
        { value: "home", label: "Equipe à domicile" },
        { value: "away", label: "Equipe à l'extérieur" },
        { value: "neither", label: "Aucune" }
    ];

    useEffect(() => {
        if (firstTeamToScore !== "") {
            const toUpdate = {};
            toUpdate[marketName] = firstTeamToScore;

            onResultUpdate(toUpdate);
        }
    }, [firstTeamToScore]);

    return (
        <div className="formFieldsGroup">
            <FormField type="select" id="firstTeamToScore" options={allOptions} label={"1ère équipe à marquer (" + getPrintablePeriod(sport, marketName) + ")"} value={firstTeamToScore} onChange={setFirstTeamToScore} />
        </div>
    );
}
//#endregion

const getTeamToWinNilSuccessOptions = (moneyline, homeTotals, awayTotals) => {
    const successOptions = [];
    if (moneyline === "home" && awayTotals === 0) successOptions.push("home yes");
    if (moneyline === "home" && awayTotals === 0) successOptions.push("home no");
    if (moneyline === "away" && homeTotals > 0) successOptions.push("away yes");
    if (moneyline === "away" && homeTotals > 0) successOptions.push("away no");

    return successOptions;
}

const getOddEvenTotalsSuccessOptions = (totals) => {
    return totals % 2 === 0 ? "odd" : "even";
}

const getOddEvenTeamTotalsSuccessOptions = (homeTotals, awayTotals) => {
    const successOptions = [];
    successOptions.push(homeTotals % 2 === 0 ? "home odd" : "home even");
    successOptions.push(awayTotals % 2 === 0 ? "away odd" : "away even");

    return successOptions;
}

const getTeamToScoreSuccessOptions = (homeTotals, awayTotals) => {
    const successOptions = [];
    successOptions.push(homeTotals === 0 ? "home no" : "home yes");
    successOptions.push(awayTotals === 0 ? "away no" : "away yes");

    return successOptions;
}

const getBothTeamToScoreSuccessOptions = (homeTotals, awayTotals) => {
    return homeTotals > 0 && awayTotals > 0 ? "yes" : "no";
}

const getEitherTeamToScoreSuccessOptions = (homeTotals, awayTotals) => {
    return homeTotals === 0 && awayTotals === 0 ? "yes" : "no";
}